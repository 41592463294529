const { useState } = require("react");

function isValidDate(dateString) {
    // Check if it's a valid date in the format dd/mm/yyyy
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    if (!regex.test(dateString)) return false;

    // Parse the date parts to integers
    const parts = dateString.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

    // Check the number of days in the month
    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
        monthLength[1] = 29; // Leap year
    }
    return day > 0 && day <= monthLength[month - 1];
}

function validFirstName(firstName) {
    let errors = {};
    let isValid = true;
    if (firstName.trim() === '') {
        errors.firstName = 'First name is required';
    }

    if (!/^[A-Za-z]+(\s[A-Za-z]+)*$/.test(firstName.trim()) && !errors.firstName) {
        errors.firstName = 'Please enter a valid first name';
    }
    return errors.firstName;
}

function validMiddleName(middleName) {
    let errors = {};
    if (middleName.trim() === '') {
        errors.middleName = 'middle name is required';
        // isValid = false;
    }
    if (!/^[A-Za-z]+(\s[A-Za-z]+)*$/.test(middleName.trim()) && !errors.middleName) {
        errors.middleName = 'Please enter a valid middle name';
        //isValid = false;
    }
    return errors.middleName;
}

function validLastName(lastName) {
    let errors = {};
    if (lastName.trim() === '') {
        errors.lastName = 'Last name is required';
        //isValid = false;
    }
    if (!/^[A-Za-z]+(\s[A-Za-z]+)*$/.test(lastName.trim()) && !errors.lastName) {
        errors.lastName = 'Please enter valid last name';
        //isValid = false;
    }
    return errors.lastName;
}

function validMobileNumber(mobileNumber) {
    let errors = {};
    if (mobileNumber != '' && (!/^\d{10}$/.test(mobileNumber) || !/^[6-9]\d{9}$/.test(mobileNumber))) {
        errors.mobileNumber = 'Please enter a valid mobile number';
        //isValid = false;
    } else {

        if (/^0/.test(mobileNumber)) {
            errors.mobileNumber = 'Mobile number cannot start with zero';
            // isValid = false;
        }

        if (/^(.)\1*$/.test(mobileNumber)) {
            errors.mobileNumber = 'Mobile number cannot be all the same digit';
            //isValid = false;
        }

        const invalidNumbers = ['0000000000', '1234567890', '1111111111', '2222222222', '3333333333', '4444444444', '5555555555', '6666666666', '7777777777', '8888888888', '9999999999'];
        if (invalidNumbers.includes(mobileNumber)) {
            errors.mobileNumber = 'Please enter a valid mobile number';
            //isValid = false;
        }
    }
    return errors.mobileNumber;
}

function validTabMobileNumber(mobileNumber) {
    let errors = {};
    if (mobileNumber && mobileNumber != '' && (!/^\d{10}$/.test(mobileNumber) || !/^[6-9]\d{9}$/.test(mobileNumber))) {
        errors.mobileNumber = 'Please enter a valid mobile number';
        //isValid = false;
    } else {

        if (/^0/.test(mobileNumber)) {
            errors.mobileNumber = 'Mobile number cannot start with zero';
            // isValid = false;
        }

        if (/^(.)\1*$/.test(mobileNumber)) {
            errors.mobileNumber = 'Mobile number cannot be all the same digit';
            //isValid = false;
        }

        const invalidNumbers = ['0000000000', '1234567890', '1111111111', '2222222222', '3333333333', '4444444444', '5555555555', '6666666666', '7777777777', '8888888888', '9999999999'];
        if (invalidNumbers.includes(mobileNumber)) {
            errors.mobileNumber = 'Please enter a valid mobile number';
            //isValid = false;
        }
    }
    return errors.mobileNumber;
}

function validRollNo(rollNo) {
    let errors = {};
    if (rollNo.trim() != '' && (!/^\d+$/.test(rollNo) || /^0+$/.test(rollNo))) {
        errors.rollNo = 'Please enter a valid roll number';
        //isValid = false;
    }

    if (rollNo.trim() == '') {
        errors.rollNo = "Roll number is required";
        // isValid = false;
    } else if (!/^\d+$/.test(rollNo)) {
        errors.rollNo = 'Please enter valid roll number';
        //isValid = false;
    }
    return errors.rollNo;
}

function validEmail(email) {
    let errors = {};
    if (email != '' && (!/^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) || /^[0-9]+@/.test(email))) {
        errors.email = 'Please enter a valid email address';
        // isValid = false;
    }

    return errors.email;
}

function validDob(dob) {
    let errors = {};
    if (dob.trim() === '') {
        errors.dob = 'Date of birth is required';
        // isValid = false;
    } else if (!isValidDate(dob)) {
        errors.dob = 'Please enter a valid date of birth';
        // isValid = false;
    } else {
        // Calculate age and validate
        let parts = dob.split('/');
        let day = parseInt(parts[0], 10);
        let month = parseInt(parts[1], 10);
        let year = parseInt(parts[2], 10);

        let dobDate = new Date(year, month - 1, day);
        let today = new Date();
        let age = today.getFullYear() - dobDate.getFullYear();

        // Adjust for leap years
        if (dobDate > today.setFullYear(today.getFullYear() - age)) {
            age--;
        }

        if (age < 1 || age > 70) {
            errors.dob = 'Invalid age. Age should be between 1 and 70 years';
            //isValid = false;
        }
    }
    return errors.dob;
}

function validAadharNumber(aadharNumber) {
    let errors = {};
    if (aadharNumber.trim() === '') {
        errors.aadharNumber = 'Aadhar number is required';
        // isValid = false;
    } else if (!/^\d{12}$/.test(aadharNumber.trim()) || /^0+$/.test(aadharNumber.trim())) {
        errors.aadharNumber = 'Please enter a valid Aadhar number';
        // isValid = false;
    }
    return errors.aadharNumber;
}

function genderSelected(selectedGender) {
    let errors = {};
    if (selectedGender.trim() === '') {
        errors.selectedGender = 'Gender is required';
        //isValid = false;
    }
    return errors.selectedGender;
}

function sectionSelected(selectedSection) {
    let errors = {};
    if (selectedSection.trim() === '') {
        errors.selectedSection = 'Section is required';
        //isValid = false;
    }
    return errors.sectionSelected;
}

function streamSelected(selectedSection, streamSelectedValue) {
    let errors = {};
    if (selectedSection != 'School') {
        if (streamSelectedValue.trim() === '') {
            errors.stream = 'Please select stream';
            //isValid = false;
        }
    }
    return errors.stream;
}


function instituteSelected(instituteselectedValue) {
    let errors = {};
    if (instituteselectedValue.trim() === '') {
        errors.institute = 'Please select institute';
        //isValid = false;
    }
    return errors.institute;
}

function boardSelected(boardSelectedValue) {
    let errors = {};
    if (boardSelectedValue.trim() === '') {
        errors.sdBoard = 'Please select board';
        //isValid = false;
    }
    return errors.sdBoard;
}

function mediumSelected(mediumSelectedValue) {
    let errors = {};
    if (mediumSelectedValue.trim() === '') {
        errors.sdMedium = 'Please select medium';
        //isValid = false;
    }
    return errors.sdMedium;
}

function standardSelected(standardSelectedValue) {
    let errors = {};
    if (standardSelectedValue.trim() === '') {
        errors.sdStandard = 'Please select standard';
        //isValid = false;
    }
    return errors.sdStandard;
}

function divisionSelected(divisionSelectedValue) {
    let errors = {};
    if (divisionSelectedValue.trim() === '') {
        errors.division = 'Please select division';
        //isValid = false;
    }
    return errors.division;
}

function validParentsFirstName(parentsfirstName) {
    let errors = {};
    if (parentsfirstName.trim() === '') {
        errors.parentsfirstName = 'Parent\'s first name is required';
        //isValid = false;
    } else if (!/^[A-Za-z]+(\s[A-Za-z]+)*$/.test(parentsfirstName.trim()) && !errors.parentsfirstName) {
        errors.parentsfirstName = 'Please enter a valid Parent\'s first name';
        // isValid = false;
    }
    return errors.parentsfirstName;
}

function validParentsLastName(parentslastName) {
    let errors = {};
    if (parentslastName.trim() === '') {
        errors.parentslastName = 'Parent\'s last name is required';
        //isValid = false;
    } else if (!/^[A-Za-z]+(\s[A-Za-z]+)*$/.test(parentslastName.trim()) && !errors.parentslastName) {
        errors.parentslastName = 'Please enter a valid Parent\'s last name';
        // isValid = false;
    }
    return errors.parentslastName;
}

function validParentEmail(parentsemail) {
    let errors = {};
    if (parentsemail.trim() == '') {
        errors.parentsemail = "Parent's email address is required";
        // isValid = false;
    } else if ((!/^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(parentsemail) || /^[0-9]+@/.test(parentsemail))) {
        errors.parentsemail = 'Please enter a valid email address';
        //isValid = false;
    }
    return errors.parentsemail;
}

function validParentsMobileNumber(parentsmobileNumber) {
    let errors = {};
    if (parentsmobileNumber.trim() == '') {
        errors.parentsmobileNumber = "Mobile number is required";
        // isValid = false;
    } else if (!/^\d{10}$/.test(parentsmobileNumber) || !/^[6-9]\d{9}$/.test(parentsmobileNumber)) {
        errors.parentsmobileNumber = 'Please enter a valid mobile number';
        // isValid = false;
    } else {
        // Check if the mobile number does not start with zero
        if (/^0/.test(parentsmobileNumber)) {
            errors.parentsmobileNumber = 'Mobile number cannot start with zero';
            // isValid = false;
        }
        // Check if the mobile number is not all the same digit
        if (/^(.)\1*$/.test(parentsmobileNumber)) {
            errors.parentsmobileNumber = 'Mobile number cannot be all the same digit';
            // isValid = false;
        }
        // Exclude common invalid numbers
        const invalidNumbers = ['0000000000', '1234567890', '1111111111', '2222222222', '3333333333', '4444444444', '5555555555', '6666666666', '7777777777', '8888888888', '9999999999'];
        if (invalidNumbers.includes(parentsmobileNumber)) {
            errors.parentsmobileNumber = 'Please enter a valid mobile number';
            // isValid = false;
        }
    }
    return errors.parentsmobileNumber;
}

function occupationDetails(occupation) {
    let errors = {};
    if (occupation.trim() == '') {
        errors.occupation = 'Occupation is required';
        //isValid = false;
    } else if (occupation.trim() === '' || !/^[A-Za-z][A-Za-z ]+$/.test(occupation)) {
        errors.occupation = 'Please enter valid Occupation';
        //isValid = false;
    }
    return errors.occupation;
}

function validAppartmentName(appartmentName) {
    let errors = {};
    if (appartmentName.trim() === '') {
        errors.appartmentName = 'Apartment name is required';
        //isValid = false;
    }
    return errors.appartmentName;
}

function validStreetName(streetName) {
    let errors = {};
    if (streetName.trim() === '') {
        errors.streetName = 'Street name is required';
        //isValid = false;
    }
    return errors.streetName;
}

function validLandmark(landmark) {
    let errors = {};
    if (landmark.trim() === '') {
        errors.landmark = 'Landmark is required';
        // isValid = false;
    }
    return errors.landmark;
}

function validCityName(cityName) {
    let errors = {};
    if (cityName.trim() === '') {
        errors.cityName = 'Resident city name is required';
        //isValid = false;
    } else if (cityName.trim() === '' || !/^[a-zA-Z][a-zA-Z0-9\s]+$/.test(cityName.trim())) {
        errors.cityName = 'Please enter valid city name';
        // isValid = false;
    }
    return errors.cityName;

}

function validOfficeName(officeName) {
    let errors = {};
    if (officeName.trim() === '') {
        errors.officeName = 'Office name is required';
        //isValid = false;
    }
    return errors.officeName;
}

function validOfficeStreet(officeStreetName) {
    let errors = {};
    if (officeStreetName.trim() === '') {
        errors.officeStreetName = 'Office street name is required';
        //isValid = false;
    }
    return errors.officeStreetName;
}

function validOfficeLandmark(officeLandmark) {
    let errors = {};
    if (officeLandmark.trim() === '') {
        errors.officeLandmark = 'Office landmark is required';
        //isValid = false;
    }
    return errors.officeLandmark;
}

function validOfficeCityName(officeCityName) {
    let errors = {};
    if (officeCityName.trim() === '') {
        errors.officeCityName = 'Office City name is required';
        // isValid = false;
    } else if (!/^[a-zA-Z][a-zA-Z0-9\s]+$/.test(officeCityName.trim())) {
        errors.officeCityName = 'Please enter valid city name';
        // isValid = false;
    }
    return errors.officeCityName;
}

function stateSelected(State) {
    let errors = {};
    if (State.trim() === '') {
        errors.homeState = 'Please select home state';
        //isValid = false;
    }
    return errors.homeState;
}

function officeStateSelected(officeState) {
    let errors = {};
    if (officeState.trim() === '') {
        errors.officeState = 'Please select office state';
        // isValid = false;
    }
    return errors.officeState;
}

const fetchData = async (pincodevalue) => {
    try {
        const response = await fetch(`https://api.postalpincode.in/pincode/${pincodevalue}`);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        if (data && data.length > 0 && data[0].Message === 'No records found') {
            return true; // Indicates pincode is invalid
        } else {
            return false; // Indicates pincode is valid
        }
    } catch (error) {
        console.log(error);
    }
};

async function validOfficePincode(officePincode) {
    let errors = {};
    if (officePincode.trim() === '') {
        errors.officePincode = 'Office pincode is required';
        // isValid = false;
    } else if (!/^\d{6}$/.test(officePincode.trim())) {
        errors.officePincode = 'Please enter valid pincode';
        //isValid = false;
    } else {
        try {
            const isPincodeValid = await fetchData(officePincode);
            if (isPincodeValid) {
                errors.officePincode = 'Please enter valid Indian pincode';
                // isValid = false;
            }
        } catch (error) {
            errors.officePincode = error.message || 'Failed to validate pincode';
            //isValid = false;
        }
    }
    return errors.officePincode;
}


async function validPincode(pincode) {
    let errors = {};
    if (pincode.trim() === '') {
        errors.pincode = 'Home Pincode is required';
        // isValid = false;
    } else if (!/^\d{6}$/.test(pincode.trim())) {
        errors.pincode = 'Please enter valid pincode';
        // isValid = false;
    } else {
        try {
            const isPincodeValid = await fetchData(pincode);
            if (isPincodeValid) {
                errors.pincode = 'Please enter valid Indian pincode';
                //isValid = false;
            }
        } catch (error) {
            errors.pincode = error.message || 'Failed to validate pincode';
            // isValid = false;
        }
    }
    return errors.pincode;
}

function validAlternateParentsMobileNumber(AlternativeParentsMobileNumber) {
    let errors = {};
    if (AlternativeParentsMobileNumber && AlternativeParentsMobileNumber != '' && (!/^\d{10}$/.test(AlternativeParentsMobileNumber) || !/^[6-9]\d{9}$/.test(AlternativeParentsMobileNumber))) {
        errors.AlternativeParentsMobileNumber = 'Please enter a valid mobile number';
        //isValid = false;
    } else {
        // Check if the mobile number does not start with zero
        if (/^0/.test(AlternativeParentsMobileNumber)) {
            errors.AlternativeParentsMobileNumber = 'Mobile number cannot start with zero';
            // isValid = false;
        }
        // Check if the mobile number is not all the same digit
        if (/^(.)\1*$/.test(AlternativeParentsMobileNumber)) {
            errors.AlternativeParentsMobileNumber = 'Mobile number cannot be all the same digit';
            // isValid = false;
        }
        // Exclude common invalid numbers
        const invalidNumbers = ['0000000000', '1234567890', '1111111111', '2222222222', '3333333333', '4444444444', '5555555555', '6666666666', '7777777777', '8888888888', '9999999999'];
        if (invalidNumbers.includes(AlternativeParentsMobileNumber)) {
            errors.AlternativeParentsMobileNumber = 'Please enter a valid mobile number';
            //isValid = false;
        }
    }
    return errors.AlternativeParentsMobileNumber;
}

function validDiscount(showDiscount, discountValue) {
    let errors = {};
    if (showDiscount && discountValue.trim() == '') {
        errors.discountValue = 'Please enter discount value';
        // isValid = false;
    } else if (showDiscount && !/^\d+$/.test(discountValue.trim())) {
        errors.discountValue = 'Please enter valid discount value';
        //  isValid = false;
    }
    return errors.discountValue;
}

function validObtainedMarks(obtainedMarks, outOfMarks) {
    let errors = {};
    let formatedobtainedMarks = Number(obtainedMarks);
    let formatedoutOfMarks = Number(outOfMarks);
    obtainedMarks = obtainedMarks?.toString();
    outOfMarks = outOfMarks?.toString();
    // Validation for Obtained Marks
    if (obtainedMarks && obtainedMarks.trim() != '' && !/^\d+$/.test(obtainedMarks.trim())) {
        errors.obtainedMarks = 'Please enter valid obtained mark';
        //isValid = false;
    }

    if (obtainedMarks && obtainedMarks.trim() != '' && outOfMarks && outOfMarks.trim() != '' && formatedobtainedMarks > formatedoutOfMarks) {
        errors.obtainedMarks = 'Obtained mark should be less than Out of mark';
        //isValid = false;
    }
    return errors.obtainedMarks;

}

function validOutOfMarks(outOfMarks) {
    let errors = {};
    outOfMarks = outOfMarks?.toString();

    // // Validation for Out of Marks
    if (outOfMarks && outOfMarks.trim() != '' && !/^\d+$/.test(outOfMarks.trim())) {
        errors.outOfMarks = 'Please enter valid Out of marks';
        //isValid = false;
    } else if (outOfMarks && outOfMarks.trim() != '' && !isNaN(Number(outOfMarks)) && Number(outOfMarks.trim()) == 0) {
        errors.outOfMarks = 'Out of marks cannot be zero';
        //isValid = false;
    }
    return errors.outOfMarks;

}

function validOrganization(organization) {
    let errors = {};
    if (organization.trim() === '') {
        errors.organization = 'Organization name is required';
        //isValid = false;
    } else if (organization.length < 3) {
        errors.organization = 'Please enter a valid organization name';
        // isValid = false;
    } else if (!/^[a-zA-Z0-9 _-]{3,50}$/.test(organization)) {
        errors.organization = 'Please enter a valid organization name';
        // isValid = false;
    }
    return errors.organization;
}

function validPassword(password) {
    let errors = {};
    if (password == '') {
        errors.password = "Password is required";
        //isValid = false;
    } else if (password.length < 8 || password.length > 25) {
        errors.password = 'Password length must be between 8 and 25 characters';
        // isValid = false;
    } else if (!/\d/.test(password)) {
        errors.password = 'Password must contain at least one number';
        // isValid = false;
    } else if (!/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)) {
        errors.password = 'Password must contain at least one special character';
        // isValid = false;
    } else if (!/^(?=.*[a-z])(?=.*[A-Z]).+$/.test(password)) {
        errors.password = 'Password must contain at least one uppercase letter and at least one lowercase letter';
        //isValid = false;
    }
    return errors.password;
}

function validConfirmPassword(confirmPassword, password) {
    let errors = {};

    if (confirmPassword == '') {
        errors.confirmPassword = "Confirm password is required";
        //  isValid = false;
    } else if (confirmPassword !== password) {
        errors.confirmPassword = 'Password does not match';
        // isValid = false;
    }
    return errors.confirmPassword;
}

function validRegistrationMobileNumber(mobileNumber) {
    let errors = {};
    if (mobileNumber.trim() == '') {
        errors.mobileNumber = "Mobile number is required";
        // isValid = false;
    } else if (!/^\d{10}$/.test(mobileNumber) || !/^[6-9]\d{9}$/.test(mobileNumber)) {
        errors.mobileNumber = 'Please enter a valid mobile number';
        // isValid = false;
    } else {
        // Check if the mobile number does not start with zero
        if (/^0/.test(mobileNumber)) {
            errors.mobileNumber = 'Mobile number cannot start with zero';
            // isValid = false;
        }
        // Check if the mobile number is not all the same digit
        if (/^(.)\1*$/.test(mobileNumber)) {
            errors.mobileNumber = 'Mobile number cannot be all the same digit';
            // isValid = false;
        }
        // Exclude common invalid numbers
        const invalidNumbers = ['0000000000', '1234567890', '1111111111', '2222222222', '3333333333', '4444444444', '5555555555', '6666666666', '7777777777', '8888888888', '9999999999'];
        if (invalidNumbers.includes(mobileNumber)) {
            errors.mobileNumber = 'Please enter a valid mobile number';
            // isValid = false;
        }
    }
    return errors.mobileNumber;
}

function validRegistrationEmail(email) {
    let errors = {};
    if (email.trim() == '') {
        errors.email = "Parent's email address is required";
        // isValid = false;
    } else if ((!/^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email) || /^[0-9]+@/.test(email))) {
        errors.email = 'Please enter a valid email address';
        //isValid = false;
    }
    return errors.email;
}

function ConsentChecked(isConsentChecked) {
    let errors = {}
    if (!isConsentChecked) {
        errors.isConsentChecked = "Consent is required";
        // isValid = false;
    } else {
        errors.isConsentChecked = "";
    }
    return errors.isConsentChecked;

}

const mobileNumberRegex = /^\d{10}$/;
const emailRegex = /^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,253}\.[a-zA-Z]{2,63}$/;

const usernameRegex = /^[a-zA-Z]+\.[a-zA-Z]+@\d{8}$/;

function validUserName(username) {
    let errors = {};
    if (username == '') {
        errors.username = "Username is required"
    } else {
        if (mobileNumberRegex.test(username) || (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(username) || /^[0-9]+@/.test(username)) || usernameRegex.test(username)) {
            errors.username = "";
        }
    }
    return errors.username;
}

function validGrade(grade) {
    let errors = {};
    const trimmedGrade = grade.trim();
    const matches = trimmedGrade.match(/^[A-Za-z][+-]?$/);
    if (trimmedGrade && !matches) {
        errors.grade = 'Please enter a valid overall grade';
        //isValid = false;
    }
    return errors.grade;
}

function validOtp(mobileotp) {
    let errors = {};
    if (mobileotp.trim() == '') {
        errors.mobileotp = "Mobile OTP is required";
        //isValid = false;
        // setErrors(errors);
    } else if (!/^\d{6}$/.test(mobileotp)) {
        errors.mobileotp = 'Please enter a valid 6-digit OTP';
        // isValid = false;
        // setErrors(errors);
    }
    return errors.mobileotp;
}

function validEmailOtp(emailotp) {
    let errors = {};
    if (emailotp.trim() == '') {
        errors.emailotp = "Email OTP is required";
        // isValid = false;
        // setErrors(errors);
    } else if (!/^\d{6}$/.test(emailotp)) {
        errors.emailotp = 'Please enter a valid 6-digit OTP';
        // setErrors(errors);
        // isValid = false;
    }
    return errors.emailotp;
}

function formatDateToDDMMYYYY(date) {
    let day = String(date.getDate()).padStart(2, '0');
    let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based in JavaScript
    let year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

let CurrentDate = formatDateToDDMMYYYY(new Date());

let oneYearFromNow = new Date();
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
oneYearFromNow.setHours(0, 0, 0, 0);

let oneYearLessFromNow = new Date();
oneYearLessFromNow.setFullYear(oneYearLessFromNow.getFullYear() - 1);
oneYearLessFromNow.setHours(0, 0, 0, 0);

let todaysDate = new Date();
todaysDate.setHours(0, 0, 0, 0);

function parseDate(dateStr) {
    let [day, month, year] = dateStr.split('/');
    return new Date(year, month - 1, day); // Month is zero-based in JavaScript Date
}



function validAdmissionDate(admissionDate) {
    // Validation for Date of Birth
    let formatedadmissionDate = parseDate(admissionDate);
    let errors = {};
    if (admissionDate.trim() === '') {
        errors.admissionDate = 'Admission Date is required';
        // isValid = false;
    } else if (!isValidDate(admissionDate) || formatedadmissionDate < oneYearLessFromNow || formatedadmissionDate > todaysDate) {
        errors.admissionDate = 'Please enter valid Admission date';
        // isValid = false;
    }
    return errors.admissionDate;
}

function validAmount(amount) {
    let error = {};
    if (amount.trim() == '') {
        error.amount = "Amount is required";
        // isValid = false;
    } else if (!/^\d+$/.test(amount.trim())) {
        error.amount = "Please enter valid amount";
        //isValid = false;
    } else if (amount.trim() == '0') {
        error.amount = "Please enter valid amount";
        // isValid = false;
    }
    return error.amount;
}

function validReferenceId(ReferenceId) {
    let error = {};
    if (ReferenceId.trim() == '') {
        error.ReferenceId = "Reference ID is required";
        //isValid = false;
    }
    else if (!/^[a-zA-Z0-9@\/]*$/.test(ReferenceId)) {
        error.ReferenceId = "Please enter valid reference id";
        // isValid = false;
    }
    return error.ReferenceId;
}

function validPaymentDate(paymentDate) {
    let error = {};
    let formatedpaymentDate = parseDate(paymentDate);

    if (paymentDate.trim() === '') {
        error.paymentDate = 'Payment Date is required';
        // isValid = false;
    } else if (!isValidDate(paymentDate) || formatedpaymentDate < oneYearLessFromNow || formatedpaymentDate > todaysDate) {
        error.paymentDate = 'Please enter valid Payment date';
        // isValid = false;
    }
    return error.paymentDate;
}

function validModeOfPayment(modeOfPaymentSelectedValue) {
    let error = {};
    if (modeOfPaymentSelectedValue == '') {
        error.modeOfPayment = "Please select mode of payment";
        // isValid = false;
    }
    return error.modeOfPayment;
}

const validation = {
    validDate: isValidDate,
    isValidFirstName: validFirstName,
    isValidLastName: validLastName,
    isValidMiddleName: validMiddleName,
    isValidMobileNumber: validMobileNumber,
    isValidTabMobileNumber: validTabMobileNumber,
    isValidRollNumber: validRollNo,
    isValidEmail: validEmail,
    isValidDob: validDob,
    isValidAadharNumber: validAadharNumber,
    isGenderSelected: genderSelected,
    isSectionSelected: sectionSelected,
    isStreamSelected: streamSelected,
    isInstituteSelected: instituteSelected,
    isBoardSelected: boardSelected,
    isMediumSelected: mediumSelected,
    isStandardSelected: standardSelected,
    isDivisionSelected: divisionSelected,
    isValidParentsFirstName: validParentsFirstName,
    isValidParentsLastName: validParentsLastName,
    isValidParentsEmail: validParentEmail,
    isValidParentsMobileNumber: validParentsMobileNumber,
    isValidOccupationDetails: occupationDetails,
    isValidAppartmentName: validAppartmentName,
    isValidStreetName: validStreetName,
    isValidLandmark: validLandmark,
    isValidCityName: validCityName,
    isValidOfficeName: validOfficeName,
    isValidOfficeStreetName: validOfficeStreet,
    isValidOfficeLandMark: validOfficeLandmark,
    isValidOfficeCityName: validOfficeCityName,
    isStateSelected: stateSelected,
    isOfficeStateSelected: officeStateSelected,
    isValidOfficePincode: validOfficePincode,
    isValidPincode: validPincode,
    isValidAlternateParentsMobileNumber: validAlternateParentsMobileNumber,
    isValidDiscountValue: validDiscount,
    isValidObtainedMarks: validObtainedMarks,
    isValidOutOfMarks: validOutOfMarks,
    isValidOrganization: validOrganization,
    isValidPassword: validPassword,
    isValidConfirmPassword: validConfirmPassword,
    isValidRegistrationMobileNumber: validRegistrationMobileNumber,
    isValidRegistrationEmail: validRegistrationEmail,
    isConsentChecked: ConsentChecked,
    isValidUserName: validUserName,
    isValidGrade: validGrade,
    isValidOtp: validOtp,
    isValidAdmissionDate: validAdmissionDate,
    isValidEmailOtp: validEmailOtp,
    isValidAmount: validAmount,
    isValidReferenceId: validReferenceId,
    isValidPaymentDate: validPaymentDate,
    isValidModeOfPayment: validModeOfPayment,

}

module.exports = validation