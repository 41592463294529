import React, { useEffect, useState } from 'react';
import './RegistrationPage.css';
import RegistrationForm from '../../../Components/Registration/RegistrationForm';
// import Header from '../../../Components/Common/Header';
// import Footer from '../../../Components/Common/Footer';
import PreLoginHeader from '../../../Components/Common/PreLoginHeader';
import CommonFooter from '../../../Components/Common/CommonFooter';
import NewRegistrationForm from '../../../Components/Registration/NewRegistrationForm';
import LoginModal from '../../../Components/Login/LoginModal/LoginModal';
import ProfilePopup from '../../../Components/Common/ProfilePopup/ProfilePopup';
import NotificationDetails from '../../../Components/Common/NotificationDetails/NotificationDetails';

const RegistrationPage = () => {

    const [displayLoginPopup, setDisplayLoginPopup] = useState(false);
    const [scrollY, setScrollY] = useState(0);
    const [displayProfileMenu, setDisplayProfileMenu] = useState(false);
    const [displayNotificationMenu, setDisplayNotificationMenu] = useState(false);

    useEffect(() => {

        if (displayLoginPopup) {
            document.getElementsByTagName('body')[0].classList.add("overflow-hide");
        } else {
            document.getElementsByTagName("body")[0].classList.remove('overflow-hide');
        }
    }, [displayLoginPopup]);


    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        document.title = 'Registeration'
        sessionStorage.clear();
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className='register-page-main-container'>
            <PreLoginHeader
                hideRegister={true}
                setDisplayLoginPopup={setDisplayLoginPopup}
            />
            <NewRegistrationForm setDisplayLoginPopup={setDisplayLoginPopup} />
            <CommonFooter />
            {
                displayLoginPopup ?
                    <LoginModal scrollY={scrollY} setDisplayLoginPopup={setDisplayLoginPopup} />
                    : null
            }
            {
                displayProfileMenu ?
                    <ProfilePopup scrollY={scrollY} setDisplayProfileMenu={setDisplayProfileMenu} />
                    : null
            }
            {
                displayNotificationMenu ?
                    <NotificationDetails scrollY={scrollY} setDisplayNotificationMenu={setDisplayNotificationMenu} />
                    : null
            }
        </div>
    )
}

export default RegistrationPage;