import React, { useState, useEffect } from 'react'
import '../AdmissionForm/AdmissionForm.scss'
import Institute_logo from '../../Assets/Images/Institute_logo.png'
import sample from '../../Assets/Images/sample.png'
import StudentDetails from '../StudentsDetails'
import ParentDetails from '../ParentDetails/ParentDetails'
import PastAcademicReports from '../PastAcademicReports/PastAcademicReports'
import PrimaryButton from '../Common/PrimaryButton'
import { ApiCall } from '../../Utils/ApiComponets/ApiCall';
import { useNavigate } from 'react-router-dom';
import { FaInfoCircle, } from "react-icons/fa";
import moment from 'moment/moment';
import AddDiscount from '../AddDiscount';
import StudentFeesInfo from '../StudentFeesInfo';
import DefaultProfile from '../../Assets/Images/default_profile.png';
import { Socket } from '../../Utils/ApiComponets/Socket';
import validation from '../../Utils/Validations/Validations'
import { error } from 'jquery'


const AdmissionForm = () => {

    const navigate = useNavigate();

    const ClientmobileNumber = "+91 9283321255";
    const Clientemail = "contact@sankalp.com";
    const [isProfileImgPresent, setIsProfileImgPresent] = useState(false);
    const [profileImg, setProfileImg] = useState(null);
    const [profileImgType, setProfileImgType] = useState('');
    const [profileImgSize, setProfileImgSize] = useState(0);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [displaySubmitLoader, setDisplaySubmitLoader] = useState(false);
    const [sdInstituteLoader, setSdInstituteLoader] = useState(false);
    const [sdBoardLoader, setSdBoardLoader] = useState(false);
    const [sdMediumLoader, setSdMediumLoader] = useState(false);
    const [sdStandardLoader, setSdStandardLoader] = useState(false);
    const [sdSectionLoader, setSdSectionLoader] = useState(false);
    const [sdDivisionLoader, setSdDivisionLoader] = useState(false);
    const [sdStreamLoader, setSdStreamLoader] = useState(false);
    const [parBoardLoader, setParBoardLoader] = useState(false);
    const [parMediumLoader, setParMediumLoader] = useState(false);
    const [parStandardLoader, setParStandardLoader] = useState(false);
    const [showDiscount, setShowDiscount] = useState(false);
    const [totalFees, setTotalFees] = useState(0);
    const [standardFees, setStandardFees] = useState(0);
    const [selectedDiscountType, setSelectedDiscountType] = useState('Percentage');

    const [instituteSelectedLabel, setInstituteSelectedLabel] = useState('');
    const [boardSelectedLabel, setBoardSelectedLabel] = useState('');
    const [mediumSelectedLabel, setMediumSelectedLabel] = useState('');
    const [standardSelectedLabel, setStandardSelectedLabel] = useState('');
    const [divisionSelectedLabel, setDivisionSelectedLabel] = useState('');
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [warningText, setWarningText] = useState('');

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);
    const [activeTab, setActiveTab] = useState(0);

    const handleDiscountTypeChange = (e) => {
        setSelectedDiscountType(e.target.value);
    }

    function displayWarning(text) {
        setWarningText(text);
        setShowWarningModal(true);
    }

    function hideWarning() {
        setWarningText('');
        setShowWarningModal(false);
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileType = file.type;
            const fileSize = file.size
            const reader = new FileReader();
            reader.onloadend = () => {
                if (!isFileTypeValid(fileType)) {
                    displayWarning("Only .png, .jpg, .jpeg files are allowed");
                } else if (!isFileSizeValid(fileSize)) {
                    displayWarning("File size should not exceed 300kb");
                } else {
                    setProfileImg(reader.result);
                    setIsProfileImgPresent(true);
                    setProfileImgType(fileType);
                    setProfileImgSize(fileSize);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    function isFileSizeValid(fileSize) {
        const maxSizeInKB = 300; // 300 KB
        const maxSizeInBytes = maxSizeInKB * 1024; // Convert KB to Bytes
        return fileSize <= maxSizeInBytes;
    }

    function isFileTypeValid(fileType) {
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
        return allowedTypes.includes(fileType)
    }

    function formatFileSizeToKb(fileSize) {
        const sizeInKB = fileSize / 1024; // Convert Bytes to KB
        return `${sizeInKB.toFixed(2)}kb`; // Keep two decimal places
    }


    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [rollNo, setRollNo] = useState('');

    const [mobileNumber, setMobileNumber] = useState('');
    const [email, setEmail] = useState('');
    let [dob, setDob] = useState('');
    const [aadharNumber, setAadharNumber] = useState('');
    const [selectedGender, setSelectedGender] = useState('Male');
    const [selectedSection, setselectedSection] = useState('School');
    const [mainButtonLoader, setMainButtonLoader] = useState(false);
    let [showCalendar, setShowCalendar] = useState(false);
    const [showAdmissionCalendar, setshowAdmissionCalendar] = useState(false)
    const [admissionDate, setadmissionDate] = useState(moment().format("DD/MM/YYYY"));

    const [errors, setErrors] = useState({});
    const [discountValue, setDiscountValue] = useState('');
    const [openFeeModal, setOpenFeeModal] = useState(false);


    const toggleCalendar = () => {
        if (dob) {
            calendarformatDate(dob)
        }
        setShowCalendar(!showCalendar);
    };

    const toggleAdmissionCalendar = () => {

        if (admissionDate) {
            calendarformatDate_AdmissionDate(admissionDate)
        }
        setshowAdmissionCalendar(!showAdmissionCalendar);
    };

    function formatDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    function formatAdmissionDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    function calendarformatDate(date) {
        const newdate = new Date(date);
        // Check if the date is valid
        if (isNaN(date)) {
            console.error("Invalid date");
            return null;
        }

        const day = newdate.getDate().toString().padStart(2, '0');
        const month = (newdate.getMonth() + 1).toString().padStart(2, '0');
        const year = newdate.getFullYear();
        let datevalue = `${month}/${day}/${year}`
        setformatedDobForCalendar(datevalue);
        return `${month}/${day}/${year}`;
    }

    const [formatedDobForCalendar, setformatedDobForCalendar] = useState(dob)
    const [formatedAdmisionDateForCalendar, setformatedAdmisionDateForCalendar] = useState(admissionDate)


    function calendarformatDate_AdmissionDate(date) {


        const newdate = new Date(date);
        // Check if the date is valid
        if (isNaN(date)) {
            console.error("Invalid date");
            return null;
        }

        const day = newdate.getDate().toString().padStart(2, '0');
        const month = (newdate.getMonth() + 1).toString().padStart(2, '0');
        const year = newdate.getFullYear();
        let datevalue = `${month}/${day}/${year}`
        setformatedAdmisionDateForCalendar(datevalue);

        return `${month}/${day}/${year}`;
    }

    const handleCalendarChange = (date) => {
        setDob(date instanceof Date ? formatDate(date) : date.target.value);
        toggleCalendar();
    };

    const handleAdmissionCalendarChange = (date) => {
        let formatedadmissionDate = date instanceof Date ? formatAdmissionDate(date) : date.target.value
        setadmissionDate(formatedadmissionDate);
        toggleAdmissionCalendar();
    };

    const handleGenderChange = (event) => {
        setSelectedGender(event.target.value);
    };

    const handlesectionChange = (event) => {
        setselectedSection(event.target.value);
    };


    const [instituteselectedValue, setInstituteSelectedValue] = useState('');
    const [boardSelectedValue, setBoardSelectedValue] = useState('');
    const [mediumSelectedValue, setMediumSelectedValue] = useState('');
    const [standardSelectedValue, setStandardSelectedValue] = useState('');
    const [divisionSelectedValue, setDivisionSelectedValue] = useState('');
    const [streamSelectedValue, setStreamSelectedValue] = useState('');
    const [academicYearSelectedValue, setAcademicYearSelectedValue] = useState('');

    function vwToPx(vw) {
        const width = window.innerWidth;
        return (vw / 100) * width;
    }

    const [progress, setProgress] = useState(0);
    const [wsStatus, setWsStatus] = useState('');
    const [isProfileImageUploading, setIsProfileImageUploading] = useState(false);

    const normalizedRadius = vwToPx(2) - vwToPx(0.2); // radius minus border thickness
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    const genderOptions = ['Male', 'Female', 'Other'];
    // const sectionOptions = [];
    const [sectionOptions, setSectionOptions] = useState([]);
    const [sectionWithId, setSectionWithId] = useState([]);

    const [InstitiuteOptions, setInstitiuteOptions] = useState([
        { value: '', label: 'Institute' }
    ]);
    const [BoardOptions, setBoardOptions] = useState([
        { value: '', label: 'Board' }
    ]);
    const [MediumOptions, setMediumOptions] = useState([
        { value: '', label: 'Medium' }
    ]);
    const [StandardOptions, setStandardOptions] = useState([
        { value: '', label: 'Standard' }
    ]);
    const [DivisionOptions, setDivisionOptions] = useState([
        { value: '', label: 'Division' }
    ]);
    const [StreamOptions, setStreamOptions] = useState([
        { value: '', label: 'Stream' }
    ])


    const AcademicYearOptions = [
        { value: '', label: 'Academic Year' },
        { value: '2022-2023', label: '2022-2023' },
        { value: '2023-2024', label: '2023-2024' },
        { value: '2024-2025', label: '2024-2025' },
        { value: '2025-2026', label: '2025-2026' },
        { value: '2026-2027', label: '2026-2027' },
    ];

    const handleInstituteChange = async (event) => {
        setInstituteSelectedValue(event.target.value);
        setBoardSelectedValue('');
        setBoardOptions([
            { value: '', label: 'Board' }
        ]);
        setMediumSelectedValue('');
        setMediumOptions([
            { value: '', label: 'Medium' }
        ]);
        setStandardSelectedValue('');
        setStandardOptions([
            { value: '', label: 'Standard' }
        ]);
        setShowDiscount(false);
        setTotalFees(0);
        setStandardFees(0);
        setDivisionSelectedValue('');
        setDivisionOptions([
            { value: '', label: 'Division' }
        ]);
        if (event.target.value != '') {
            try {
                if (selectedSection == 'School' || (selectedSection == 'College' && streamSelectedValue != '')) {
                    await triggerBoardApi(event.target.value);
                }
            } catch (e) {
                if (e.status == 401) {
                    navigate("/");
                    console.log("unathorized, logging out...");
                }
            }
        }
    };

    const handleBoardChange = (event) => {
        setBoardSelectedValue(event.target.value);
        setMediumSelectedValue('');
        setMediumOptions([
            { value: '', label: 'Medium' }
        ]);
        setStandardSelectedValue('');
        setStandardOptions([
            { value: '', label: 'Standard' }
        ]);
        setShowDiscount(false);
        setTotalFees(0);
        setStandardFees(0);
        setDivisionSelectedValue('');
        setDivisionOptions([
            { value: '', label: 'Division' }
        ]);
        if (event.target.value != '') {
            try {
                triggerMediumApi(event.target.value);
            } catch (e) {
                if (e.status == 401) {
                    navigate("/");
                    console.log("unathorized, logging out...");
                }
            }
        }
    };

    const handleMediumChange = (event) => {
        setMediumSelectedValue(event.target.value);
        setStandardSelectedValue('');
        setStandardOptions([
            { value: '', label: 'Standard' }
        ]);
        setShowDiscount(false);
        setTotalFees(0);
        setStandardFees(0);
        setDivisionSelectedValue('');
        setDivisionOptions([
            { value: '', label: 'Division' }
        ]);
        if (event.target.value != '') {
            try {
                triggerStandardApi(event.target.value);
            } catch (e) {
                if (e.status == 401) {
                    navigate("/");
                    console.log("unathorized, logging out...");
                }
            }
        }
    };

    const handleStandardChange = (event) => {
        setStandardSelectedValue(event.target.value);
        setDivisionSelectedValue('');
        setDivisionOptions([
            { value: '', label: 'Division' }
        ]);
        if (event.target.value != '') {
            try {
                triggerDivisionApi(event.target.value);
                triggerGetStandardFees(event.target.value);
            } catch (e) {
                if (e.status == 401) {
                    navigate("/");
                    console.log("unathorized, logging out...");
                }
            }
        } else {
            setTotalFees(0);
            setStandardFees(0);
            setShowDiscount(false);
        }
    };


    const handleDivisionChange = (event) => {
        setDivisionSelectedValue(event.target.value);
    };

    const handleStreamChange = async (event) => {
        setStreamSelectedValue(event.target.value);
        setBoardSelectedValue('');
        setBoardOptions([
            { value: '', label: 'Board' }
        ]);
        setMediumSelectedValue('');
        setMediumOptions([
            { value: '', label: 'Medium' }
        ]);
        setStandardSelectedValue('');
        setStandardOptions([
            { value: '', label: 'Standard' }
        ]);
        setShowDiscount(false);
        setTotalFees(0);
        setStandardFees(0);
        setDivisionSelectedValue('');
        setDivisionOptions([
            { value: '', label: 'Division' }
        ]);
        if (event.target.value != '') {
            try {
                if (instituteselectedValue != '') {
                    await triggerBoardApi(null, event.target.value);
                }
            } catch (e) {
                if (e.status == 401) {
                    navigate("/");
                    console.log("unathorized, logging out...");
                }
            }
        }
    }

    const triggerCreateDiscount = (userId,) => {
        return new Promise(async (res, rej) => {
            try {
                const body = {
                    "discountData": {
                        "userId": userId,
                        "discountKey": selectedDiscountType == 'Percentage' ? 'p' : 'a',
                        "discountValue": discountValue,
                    }
                }
                const response = await ApiCall('fee/createstudentdiscount', body);
                res();
            } catch (e) {
                rej(e);
            }
        })
    }

    // parents details form

    const [parentsfirstName, setparentsFirstName] = useState('');
    const [parentslastName, setparentsLastName] = useState('');
    const [parentsmobileNumber, setparentsMobileNumber] = useState('');
    const [parentsemail, setparentsEmail] = useState('');
    const [occupation, setOccupation] = useState('');
    const [appartmentName, setappartmentName] = useState('');
    const [streetName, setstreetName] = useState('');
    const [landmark, setlandmark] = useState('');
    const [cityName, setcityName] = useState('');
    const [officeName, setofficeName] = useState('');
    const [officeStreetName, setofficeStreetName] = useState('');
    const [officeLandmark, setofficeLandmark] = useState('');
    const [disableStream, setDisableStream] = useState(true);
    const [officeCityName, setofficeCityName] = useState('');
    const IndianStatesAndUTs = [
        { value: 'Andaman and Nicobar Islands', label: 'Andaman and Nicobar Islands' },
        { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
        { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
        { value: 'Assam', label: 'Assam' },
        { value: 'Bihar', label: 'Bihar' },
        { value: 'Chandigarh', label: 'Chandigarh' },
        { value: 'Chhattisgarh', label: 'Chhattisgarh' },
        { value: 'Dadra and Nagar Haveli and Daman and Diu', label: 'Dadra and Nagar Haveli and Daman and Diu' },
        { value: 'Delhi', label: 'Delhi' },
        { value: 'Goa', label: 'Goa' },
        { value: 'Gujarat', label: 'Gujarat' },
        { value: 'Haryana', label: 'Haryana' },
        { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
        { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' },
        { value: 'Jharkhand', label: 'Jharkhand' },
        { value: 'Karnataka', label: 'Karnataka' },
        { value: 'Kerala', label: 'Kerala' },
        { value: 'Ladakh', label: 'Ladakh' },
        { value: 'Lakshadweep', label: 'Lakshadweep' },
        { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
        { value: 'Maharashtra', label: 'Maharashtra' },
        { value: 'Manipur', label: 'Manipur' },
        { value: 'Meghalaya', label: 'Meghalaya' },
        { value: 'Mizoram', label: 'Mizoram' },
        { value: 'Nagaland', label: 'Nagaland' },
        { value: 'Odisha', label: 'Odisha' },
        { value: 'Puducherry', label: 'Puducherry' },
        { value: 'Punjab', label: 'Punjab' },
        { value: 'Rajasthan', label: 'Rajasthan' },
        { value: 'Sikkim', label: 'Sikkim' },
        { value: 'Tamil Nadu', label: 'Tamil Nadu' },
        { value: 'Telangana', label: 'Telangana' },
        { value: 'Tripura', label: 'Tripura' },
        { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
        { value: 'Uttarakhand', label: 'Uttarakhand' },
        { value: 'West Bengal', label: 'West Bengal' }
    ];
    const [State, setState] = useState('');
    const [officeState, setofficeState] = useState('');
    const [officePincode, setofficePincode] = useState('');
    const [pincode, setpincode] = useState('');
    const [studentId, setStudentId] = useState('');
    const [AlternativeParentsMobileNumber, setAlternativeParentsMobileNumber] = useState('');

    const StateOptions = [
        { value: '', label: 'State' },
        { value: 'cbse', label: 'CBSE' },
        { value: 'icse', label: 'ICSE' },
        { value: 'state_board', label: 'State Board' },
    ];

    const handleStateChange = (event) => {
        setState(event.target.value);
    };

    const handleOfficeStateChange = (event) => {
        setofficeState(event.target.value);
    };

    //post acadmeic records

    const [instituteName, setinstituteName] = useState('');
    const [medium, setMedium] = useState('');
    let [obtainedMarks, setObtainedMarks] = useState('');
    const [obtainedPercentage, setObtainedPercentage] = useState('');
    const [board, setBoard] = useState('');
    const [standard, setStandard] = useState('');
    let [outOfMarks, setOutOfMarks] = useState('');
    let [grade, setGrade] = useState('');

    const [mediumOptionspostacademic, setmediumOptionspostacademic] = useState([{ value: '', label: 'Medium' }]);
    const [boardOptionspostacademic, setBoardOptionspostacademic] = useState([{ value: '', label: 'Board' }]);
    const [standardOptionspostacademic, setStandardOptionspostacademic] = useState([{ value: '', label: 'Standard' }]);



    const handleMediumChangepostacademic = (event) => {
        setMedium(event.target.value);
    };

    const handleboardOptionspostacademicChange = (event) => {
        setBoard(event.target.value);
    };

    const handleStandardChangepostacademic = (event) => {
        setStandard(event.target.value);
    };




    const handleSubmitClick = async () => {
        if (disableSubmit) {
            return;
        }

        setApiError('');

        let isValid = true;
        let errors = {};

        errors.firstName = validation.isValidFirstName(firstName);
        errors.lastName = validation.isValidLastName(lastName);
        errors.middleName = validation.isValidMiddleName(middleName);
        errors.mobileNumber = validation.isValidMobileNumber(mobileNumber);
        errors.selectedSection = validation.isSectionSelected(selectedSection);
        errors.sdBoard = validation.isBoardSelected(boardSelectedValue);
        errors.aadharNumber = validation.isValidAadharNumber(aadharNumber);
        errors.email = validation.isValidEmail(email);
        errors.rollNo = validation.isValidRollNumber(rollNo);
        errors.institute = validation.isInstituteSelected(instituteselectedValue);
        errors.sdStandard = validation.isStandardSelected(standardSelectedValue);
        errors.selectedGender = validation.isGenderSelected(selectedGender);
        errors.sdMedium = validation.isMediumSelected(mediumSelectedValue);
        errors.dob = validation.isValidDob(dob);
        errors.division = validation.isDivisionSelected(divisionSelectedValue);
        errors.stream = validation.isStreamSelected(selectedSection, streamSelectedValue);
        errors.parentsfirstName = validation.isValidParentsFirstName(parentsfirstName);
        errors.parentslastName = validation.isValidParentsLastName(parentslastName);
        errors.parentsemail = validation.isValidParentsEmail(parentsemail);
        errors.parentsmobileNumber = validation.isValidParentsMobileNumber(parentsmobileNumber);
        errors.occupation = validation.isValidOccupationDetails(occupation);
        errors.appartmentName = validation.isValidAppartmentName(appartmentName);
        errors.streetName = validation.isValidStreetName(streetName);
        errors.landmark = validation.isValidLandmark(landmark);
        errors.cityName = validation.isValidCityName(cityName);
        errors.homeState = validation.isStateSelected(State);
        errors.officeName = validation.isValidOfficeName(officeName);
        errors.officeStreetName = validation.isValidStreetName(officeStreetName);
        errors.officeLandmark = validation.isValidOfficeLandMark(officeLandmark);
        errors.officeCityName = validation.isValidCityName(officeCityName);
        errors.officeState = validation.isOfficeStateSelected(officeState);
        errors.pincode = await validation.isValidPincode(pincode);
        errors.officePincode = await validation.isValidOfficePincode(officePincode);
        errors.AlternativeParentsMobileNumber = validation.isValidAlternateParentsMobileNumber(AlternativeParentsMobileNumber);
        errors.discountValue = validation.isValidDiscountValue(showDiscount, discountValue);
        errors.obtainedMarks = validation.isValidObtainedMarks(obtainedMarks, outOfMarks);
        errors.outOfMarks = validation.isValidOutOfMarks(outOfMarks);
        errors.grade = validation.isValidGrade(grade);
        //errors.obtainedMarks = validation.isValidMarks(obtainedMarks, outOfMarks);
        errors.admissionDate = validation.isValidAdmissionDate(admissionDate);


        for (const prop in errors) {
            if (errors[prop]) {
                isValid = false;
                break;
            }
        }




        setErrors(errors);
        if (isValid) {
            try {
                const userId = await triggerCreateStudent();
                // navigate("/dashboard");

                const recentStudentKey = "recentlyAddedStudents";
                const recentlyAddedStudents = sessionStorage.getItem(recentStudentKey);
                const currentStudentData = {
                    id: userId,
                    rollNo: rollNo,
                    name: firstName + " " + lastName,
                    mobileNo: parentsmobileNumber,
                    std: StandardOptions.find(item => item.value == standardSelectedValue).label,
                    admissionDate: admissionDate,
                    div: DivisionOptions.find(item => item.value == divisionSelectedValue).label,
                    documents: '',
                }
                if (!recentlyAddedStudents) {//no recently added students present
                    sessionStorage.setItem(recentStudentKey, JSON.stringify([currentStudentData]));
                } else {
                    let recentStudentData = JSON.parse(recentlyAddedStudents);
                    if (recentStudentData.length >= 5) {
                        recentStudentData.pop();
                    }
                    recentStudentData = [currentStudentData, ...recentStudentData];
                    sessionStorage.setItem(recentStudentKey, JSON.stringify(recentStudentData));
                }

                if (showDiscount) {
                    await triggerCreateDiscount(userId);
                }

                setStudentId(userId);
                const institute = InstitiuteOptions.find(item => item.value == instituteselectedValue);
                setInstituteSelectedLabel(institute.label);
                const board = BoardOptions.find(item => item.value == boardSelectedValue);
                setBoardSelectedLabel(board.label);
                const medium = MediumOptions.find(item => item.value == mediumSelectedValue);
                setMediumSelectedLabel(medium.label);
                const standard = StandardOptions.find(item => item.value == standardSelectedValue);
                setStandardSelectedLabel(standard.label);
                const division = DivisionOptions.find(item => item.value == divisionSelectedValue);
                setDivisionSelectedLabel(division.label);

                if (profileImg) {
                    setDisableSubmit(true);
                    setDisplaySubmitLoader(true);
                    const pureBase64 = profileImg.split(";base64,")[1];
                    const pureFileType = profileImgType.split("/")[1];
                    const pureFileSize = formatFileSizeToKb((profileImgSize));
                    const userAction = 'profile';
                    const userObjectId = userId;
                    const count = 100;
                    const checkSum = "d41d8cd98f00b204e9800998ecf8427e";//not used hence hardcoded
                    Socket(pureBase64, pureFileType, pureFileSize, userAction, userObjectId, count, checkSum, setProgress, setWsStatus);
                } else {
                    setOpenFeeModal(true);
                }
            } catch (e) {
                if (e.status == 401) {
                    navigate("/");
                    console.log("unathorized, logging out...");
                }
            }
        } else {
            setApiError("Please solve above errors");
        }



        return isValid;
    }

    const handleStudentDetail = () => {
        if (disableSubmit) {
            return;
        }

        setApiError('');

        let isValid = true;
        let errors = {};

        errors.firstName = validation.isValidFirstName(firstName);
        errors.lastName = validation.isValidLastName(lastName);
        errors.middleName = validation.isValidMiddleName(middleName);
        errors.mobileNumber = validation.isValidMobileNumber(mobileNumber);
        errors.selectedSection = validation.isSectionSelected(selectedSection);
        errors.sdBoard = validation.isBoardSelected(boardSelectedValue);
        errors.aadharNumber = validation.isValidAadharNumber(aadharNumber);
        errors.email = validation.isValidEmail(email);
        errors.rollNo = validation.isValidRollNumber(rollNo);
        errors.institute = validation.isInstituteSelected(instituteselectedValue);
        errors.sdStandard = validation.isStandardSelected(standardSelectedValue);
        errors.selectedGender = validation.isGenderSelected(selectedGender);
        errors.sdMedium = validation.isMediumSelected(mediumSelectedValue);
        errors.dob = validation.isValidDob(dob);
        errors.division = validation.isDivisionSelected(divisionSelectedValue);
        errors.stream = validation.isStreamSelected(selectedSection, streamSelectedValue);

        for (const prop in errors) {
            if (errors[prop]) {
                isValid = false;
                break;
            }
        }


        if (isValid) {
            setActiveTab(val => val + 1)
        }

        setErrors(errors);

    }

    const handleParentDetail = async () => {

        if (disableSubmit) {
            return;
        }

        setApiError('');

        let isValid = true;
        let errors = {};

        errors.parentsfirstName = validation.isValidParentsFirstName(parentsfirstName);
        errors.parentslastName = validation.isValidParentsLastName(parentslastName);
        errors.parentsemail = validation.isValidParentsEmail(parentsemail);
        errors.parentsmobileNumber = validation.isValidParentsMobileNumber(parentsmobileNumber);
        errors.occupation = validation.isValidOccupationDetails(occupation);
        errors.appartmentName = validation.isValidAppartmentName(appartmentName);
        errors.streetName = validation.isValidStreetName(streetName);
        errors.landmark = validation.isValidLandmark(landmark);
        errors.cityName = validation.isValidCityName(cityName);
        errors.homeState = validation.isStateSelected(State);
        errors.officeName = validation.isValidOfficeName(officeName);
        errors.officeStreetName = validation.isValidStreetName(officeStreetName);
        errors.officeLandmark = validation.isValidOfficeLandMark(officeLandmark);
        errors.officeCityName = validation.isValidCityName(officeCityName);
        errors.officeState = validation.isOfficeStateSelected(officeState);
        errors.pincode = await validation.isValidPincode(pincode);
        errors.officePincode = await validation.isValidOfficePincode(officePincode)
        errors.AlternativeParentsMobileNumber = validation.isValidAlternateParentsMobileNumber(AlternativeParentsMobileNumber);
        errors.discountValue = validation.isValidDiscountValue(showDiscount, discountValue);
        errors.obtainedMarks = validation.isValidObtainedMarks(obtainedMarks, outOfMarks);
        // errors.obtainedMarks = validation.isValidMarks(obtainedMarks, outOfMarks);
        errors.outOfMarks = validation.isValidOutOfMarks(outOfMarks);
        errors.grade = validation.isValidGrade(grade);


        for (const prop in errors) {
            if (errors[prop]) {
                isValid = false;
                break;
            }
        }

        if (isValid) {
            setActiveTab(val => val + 1)
        }

        setErrors(errors);
    }

    //  api functions  here

    const [apiError, setApiError] = useState('');

    const init = () => {
        try {
            triggerSectionsApi();
            triggerInstituteApi();
            triggerBoardOptionsApi();
            triggerPostAcademicMediumsApi();
            triggerStandardOptionsApi();
        } catch (e) {
            if (e.status == 401) {
                navigate("/");
                console.log("unathorized, logging out...");
            }
        }
    }

    const [discountedAmount, setDiscountedAmount] = useState('');
    const [discountGiven, setDiscountGiven] = useState('');
    const [showDiscountedAmount, setShowDiscountedAmount] = useState(false);

    const handleButtonClick = () => {
        setActiveTab(val => val - 1);
        setShowDiscount(false);
        setDiscountGiven('');
        setDiscountedAmount('');
        setDiscountValue('');
        setShowDiscountedAmount(false);
        setSelectedDiscountType('Percentage');
        let error = { ...errors };
        error.discountValue = '';
        setErrors(error);

    }

    useEffect(() => {
        init();
    }, [])


    const triggerSectionsApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setSdSectionLoader(true);
                let userId = sessionStorage.getItem("userId");
                if (!userId) {
                    userId = localStorage.getItem("userId");
                }
                const response = await ApiCall("section/getallusersections?userId=" + userId, null, 'get');
                const formattedOptions = response.responseJson.userSectionDetails.map(section => section.sectionName);
                setSectionOptions([
                    ...formattedOptions
                ]);
                const formattedOptionsWithId = response.responseJson.userSectionDetails.map(section => ({
                    value: section._id,
                    label: section.sectionName
                }));
                setSectionWithId(formattedOptionsWithId);
                // console.log(formattedOptions)
                setSdSectionLoader(false);

                res();
            } catch (error) {
                setSdSectionLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    }


    const triggerStreamApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setSdStreamLoader(true);
                const sectionId = sectionWithId.find((item) => item.label == selectedSection).value;
                const response = await ApiCall("stream/getallsectionstreams?sectionId=" + sectionId, null, 'get');
                const formattedOptions = response.responseJson.sectionStreamDetails.map(stream => ({
                    value: stream._id,
                    label: stream.streamName
                }));
                setStreamOptions([
                    { value: '', label: 'Stream' },
                    ...formattedOptions
                ]);
                setSdStreamLoader(false);

                res();
            } catch (error) {
                setSdStreamLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    }


    const triggerInstituteApi = () => {

        return new Promise(async (res, rej) => {
            try {
                setSdInstituteLoader(true);
                const response = await ApiCall("institute/getalluserinstitutes", null, 'get');
                const formattedOptions = response.responseJson.userInstitutesDetails.map(institute => ({
                    value: institute._id,
                    label: institute.institudeName
                }));
                setInstitiuteOptions([
                    { value: '', label: 'Institute' },
                    ...formattedOptions
                ]);
                // if(formattedOptions.length > 0) {
                //     setInstituteSelectedValue(formattedOptions[0].value);
                // }
                setSdInstituteLoader(false);

                res();
            } catch (error) {
                setSdInstituteLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    }

    const triggerBoardApi = (instituteId, streamId) => {

        return new Promise(async (res, rej) => {
            try {
                setSdBoardLoader(true);
                const id = instituteId ? instituteId : instituteselectedValue;
                const selectStreamId = streamId ? streamId : streamSelectedValue;
                let generatedApiUrl = "board/getallinstituteboards?instituteId=" + id;
                if (selectedSection != 'School') {
                    generatedApiUrl += "&streamId=" + selectStreamId;
                }
                const response = await ApiCall(generatedApiUrl, null, 'get');
                const formattedOptions = response.responseJson.instituteBoardsDetails.map(Board => ({
                    value: Board._id,
                    label: Board.boardName
                }));
                setBoardOptions([
                    { value: '', label: 'Board' },
                    ...formattedOptions
                ]);
                setSdBoardLoader(false);
                res();
            } catch (error) {
                setSdBoardLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    }

    const triggerMediumApi = (boardId) => {
        return new Promise(async (res, rej) => {
            try {
                setSdMediumLoader(true);
                const id = boardId ? boardId : boardSelectedValue;
                const response = await ApiCall("medium/getallboardmediums?boardId=" + id, null, 'get');
                const formattedOptions = response.responseJson.boardMediumsDetails.map(medium => ({
                    value: medium._id,
                    label: medium.mediumName // Assuming mediumName is the appropriate field for the label
                }));
                setMediumOptions([
                    { value: '', label: 'Medium' },
                    ...formattedOptions
                ]);
                setSdMediumLoader(false);
                res();
            } catch (error) {
                setSdMediumLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    };

    const triggerStandardApi = (mediumId) => {
        return new Promise(async (res, rej) => {
            try {
                setSdStandardLoader(true);
                const id = mediumId ? mediumId : mediumSelectedValue;
                const response = await ApiCall("standard/getallmediumstandards?mediumId=" + id, null, 'get');
                const formattedOptions = response.responseJson.mediumStandardsDetails.map(standard => ({
                    value: standard._id,
                    label: standard.standardName // Assuming standardName is the appropriate field for the label
                }));
                setStandardOptions([
                    { value: '', label: 'Standard' },
                    ...formattedOptions
                ]);
                setSdStandardLoader(false);
                res();
            } catch (error) {
                setSdStandardLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    };

    const triggerDivisionApi = (standardId) => {
        return new Promise(async (res, rej) => {
            try {
                setSdDivisionLoader(true);
                const id = standardId ? standardId : standardSelectedValue;
                const response = await ApiCall("division/getalldivisionstandards?standardId=" + id, null, 'get');
                const formattedOptions = response.responseJson.divisonStandardsDetails.map(division => ({
                    value: division.divisonId,
                    label: division.divisionName // Assuming divisionName is the appropriate field for the label
                }));
                setDivisionOptions([
                    { value: '', label: 'Division' },
                    ...formattedOptions
                ]);
                setSdDivisionLoader(false);
                res();
            } catch (error) {
                setSdDivisionLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    };

    const triggerPostAcademicMediumsApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setParMediumLoader(true);
                const response = await ApiCall("medium/getallmastermediums", null, 'get');
                const formattedOptions = response.responseJson.mediums.map(mediums => ({
                    value: mediums._id,
                    label: mediums.mediumName
                }));
                setmediumOptionspostacademic([
                    { value: '', label: 'Medium' },
                    ...formattedOptions
                ]);
                setParMediumLoader(false);
                res();
            } catch (error) {
                setParMediumLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    };

    const triggerBoardOptionsApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setParBoardLoader(true);
                const response = await ApiCall("board/getallmasterboards", null, 'get');

                const formattedOptions = response.responseJson.boardsDetails.map(option => ({
                    value: option._id,
                    label: option.boardName
                }));
                setBoardOptionspostacademic([
                    { value: '', label: 'Board' },
                    ...formattedOptions
                ]);
                setParBoardLoader(false);
                res();
            } catch (error) {
                setParBoardLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    };

    const triggerStandardOptionsApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setParStandardLoader(true);
                const response = await ApiCall("standard/getallmasterstandards", null, 'get');
                const formattedOptions = response.responseJson.standards.map(standards => ({
                    value: standards._id,
                    label: standards.standardName
                }));
                setStandardOptionspostacademic([
                    { value: '', label: 'Standard' },
                    ...formattedOptions
                ]);
                setParStandardLoader(false);
                res();
            } catch (error) {
                setParStandardLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    };

    useEffect(() => {

        if (obtainedMarks.trim() != '' && outOfMarks.trim() != '' && !isNaN(Number(obtainedMarks)) && !isNaN(Number(outOfMarks))) {
            if (Number(outOfMarks) == 0) {
                setObtainedPercentage('');
                return;
            }
            const percentage = (obtainedMarks / outOfMarks) * 100;
            setObtainedPercentage(percentage.toFixed(2));
        } else {
            setObtainedPercentage('');
        }
    }, [obtainedMarks, outOfMarks]);


    useEffect(() => {
        setSdStreamLoader(false);
        setStreamSelectedValue('');
        setStreamOptions([
            { value: '', label: 'Stream' }
        ]);
        setInstituteSelectedValue('');
        setBoardSelectedValue('');
        setBoardOptions([
            { value: '', label: 'Board' }
        ]);
        setMediumSelectedValue('');
        setMediumOptions([
            { value: '', label: 'Medium' }
        ]);
        setStandardSelectedValue('');
        setStandardOptions([
            { value: '', label: 'Standard' }
        ]);
        setShowDiscount(false);
        setTotalFees(0);
        setStandardFees(0);
        setDivisionSelectedValue('');
        setDivisionOptions([
            { value: '', label: 'Division' }
        ]);

        if (selectedSection == 'School') {
            setDisableStream(true);
        } else {
            setDisableStream(false);
            triggerStreamApi();
        }
    }, [selectedSection]);

    const triggerGetStandardFees = (standardId) => {
        return new Promise(async (res, rej) => {
            try {
                const response = await ApiCall('fee/getstandardfee?standardId=' + standardId, null, 'get');
                setTotalFees(response.responseJson.standardFeeDetails.discountedAmount);
                setStandardFees(response.responseJson.standardFeeDetails.totalAmount);
                res();
            } catch (e) {
                rej(e);
            }
        })
    }



    const triggerCreateStudent = () => {
        return new Promise(async (res, rej) => {
            try {
                let body = {
                    "studentData": {
                        "admissionDate": admissionDate,
                        "studentDetails": {
                            "firstName": firstName, //
                            "middleName": middleName,
                            "lastName": lastName, //
                            "dob": dob, //
                            "divisionId": divisionSelectedValue,
                            "gender": selectedGender.charAt(0).toLowerCase() + selectedGender.slice(1),
                            "countryCode": "+91",                  //optional
                            "mobileNumber": mobileNumber,          //optional  
                            "emailAddress": email,                   //optional
                            "aadharNumber": aadharNumber,
                            "rollNumber": rollNo.trim(),
                            "sectionName": selectedSection,
                        },
                        "parentDetails": {
                            "firstName": parentsfirstName,
                            "lastName": parentslastName,
                            "occupation": occupation.trim(),
                            "countryCode": "+91", //
                            "mobileNumber": parentsmobileNumber, //
                            "isMobileNumberVerified": 0, //      //optional
                            "alternetMobileNumber": AlternativeParentsMobileNumber,          //optional
                            "primaryEmail": parentsemail, //
                            "isPrimaryEmailVerified": "", //     optional
                            "addressDetails": [
                                {
                                    "addressType": "home",
                                    "apartmentData": appartmentName,
                                    "landMark": landmark,
                                    "street": streetName,
                                    "city": cityName,
                                    "state": State,
                                    "pincode": pincode,
                                    "country": "India"  //by default india
                                },
                                {
                                    "addressType": "office",
                                    "apartmentData": officeName,
                                    "landMark": officeLandmark,
                                    "street": officeStreetName,
                                    "city": officeCityName,
                                    "state": officeState,
                                    "pincode": officePincode,
                                    "country": "India"
                                }
                            ]
                        },
                        "pastAcademicRecord": {
                            "standardId": standard,
                            "boardId": board,
                            "mediumId": medium,
                            "instituteName": instituteName.trim(),
                            "obtainedMarks": obtainedMarks,
                            "outOfMarks": outOfMarks,
                            "grade": grade
                        }
                    }
                }
                setDisplaySubmitLoader(true);
                setDisableSubmit(true);
                const response = await ApiCall("student/createstudent", body);
                setDisableSubmit(false);
                setDisplaySubmitLoader(false);
                res(response.responseJson.userDetails.userId);
            } catch (error) {
                setApiError(error.errors[0]);
                console.log(error);
                setDisableSubmit(false);
                setDisplaySubmitLoader(false);
                rej(error);
            }
        });
    };

    useEffect(() => {
        if (showWarningModal) {
            document.getElementsByTagName('body')[0].classList.add("overflow-hide");
        } else {
            document.getElementsByTagName("body")[0].classList.remove('overflow-hide');
        }
    }, [showWarningModal]);


    useEffect(() => {
        if (wsStatus == 'uploading') {
            setIsProfileImageUploading(true);
        } else if (wsStatus == 'success') {
            //change to success
            setOpenFeeModal(true);
            setDisableSubmit(false);
            setDisplaySubmitLoader(false);
        } else if (wsStatus == 'failed') {
            //change to failed
            displayWarning("Error while uploading profile picture, please try again in details section");
            setDisableSubmit(false);
            setDisplaySubmitLoader(false);
        }
    }, [wsStatus]);



    return (
        <div className="Admission_form_section_container">
            <div className="Admission_form_main_container">
                {/* Form Section Starts */}
                <div className="form_title_parent_container">
                    <div className="logo_container">
                        <img className='Institute_logo_img' src={Institute_logo} alt="Institute_logo" />
                    </div>
                    <div className="title_details_container">
                        <div className="institute_details_container">
                            <div className="institute_title_header">
                                <h1>Sankalp Tutorials</h1>
                            </div>
                            <div className="address_container">
                                <p>Opp. Aai Chandika Sweets, Near CK Advertising, At <br /> Wakipada, Post Juchandra, Naigaon (East)</p>
                            </div>
                            <div className="contact_info_contianer">
                                <p>Mobile: {ClientmobileNumber} | Email: {Clientemail}</p>
                            </div>
                        </div>
                        <div className="admission_form_container">
                            <h1>ADMISSION FORM</h1>
                        </div>
                    </div>
                    <div className="profile_img_container">
                        <div className="uploaded_profile_img_wrapper">
                            <img className='uploaded_profile_img' src={profileImg ? profileImg : DefaultProfile} alt="uploaded_profile_img" />
                        </div>
                        {
                            !isProfileImageUploading ?
                                <div className="profile_img_wrapper"  >
                                    {/* <h4>Photo</h4> */}
                                    <label htmlFor="upload-image" >
                                        {profileImg ? null : <h4>Photo</h4>}
                                        <input
                                            type="file"
                                            accept="image/png, image/jpeg, image/jpg"
                                            onChange={handleImageChange}
                                            style={{ display: 'none' }}
                                            id="upload-image"
                                        />
                                    </label>
                                </div>
                                : null
                        }
                        {
                            isProfileImageUploading ?
                                <div className="circular-progress">
                                    <svg height="4vw" width="4vw">
                                        <circle
                                            stroke="#e6e6e6"
                                            fill="transparent"
                                            strokeWidth="0.2vw"
                                            r={normalizedRadius}
                                            cx="2vw"
                                            cy="2vw"
                                        />
                                        <circle
                                            stroke="#008af1"
                                            fill="transparent"
                                            strokeWidth="0.2vw"
                                            r={normalizedRadius}
                                            cx="2vw"
                                            cy="2vw"
                                            strokeDasharray={circumference + ' ' + circumference}
                                            strokeDashoffset={strokeDashoffset}
                                        />
                                    </svg>
                                    <div className="circular-progress__text">
                                        {progress}%
                                    </div>
                                </div>
                                : null
                        }
                        {
                            profileImg && !isProfileImageUploading ?
                                <div className="close-button" onClick={() => {
                                    setIsProfileImgPresent(false);
                                    setProfileImg('');
                                    setProfileImgSize(0);
                                    setProfileImgType('');
                                }}>
                                    Remove Photo
                                </div>
                                : null
                        }
                    </div>
                </div>
                {/* Form Section Ends */}

                {/* Admission details section starts */}

                <div className="Admission_details_parent_container">
                    {
                        !isMobile || (isMobile && activeTab == 0) ?
                            <StudentDetails
                                firstName={firstName}
                                setFirstName={setFirstName}
                                middleName={middleName}
                                setMiddleName={setMiddleName}
                                disableStream={disableStream}
                                lastName={lastName}
                                setLastName={setLastName}
                                mobileNumber={mobileNumber}
                                setMobileNumber={setMobileNumber}
                                email={email}
                                setEmail={setEmail}
                                rollNo={rollNo}
                                setRollNo={setRollNo}
                                instituteLoader={sdInstituteLoader}
                                boardLoader={sdBoardLoader}
                                mediumLoader={sdMediumLoader}
                                standardLoader={sdStandardLoader}
                                divisionLoader={sdDivisionLoader}
                                streamLoader={sdStreamLoader}
                                sectionLoader={sdSectionLoader}
                                dob={dob}
                                setDob={setDob}
                                aadharNumber={aadharNumber}
                                setAadharNumber={setAadharNumber}
                                selectedGender={selectedGender}
                                setSelectedGender={setSelectedGender}
                                selectedSection={selectedSection}
                                setselectedSection={setselectedSection}
                                mainButtonLoader={disableSubmit}
                                toggleCalendar={toggleCalendar}
                                handleCalendarChange={handleCalendarChange}
                                handleGenderChange={handleGenderChange}
                                handlesectionChange={handlesectionChange}
                                setErrors={setErrors}
                                errors={errors}
                                showCalendar={showCalendar}
                                instituteselectedValue={instituteselectedValue}
                                setInstituteSelectedValue={setInstituteSelectedValue}
                                boardSelectedValue={boardSelectedValue}
                                setBoardSelectedValue={setBoardSelectedValue}
                                mediumSelectedValue={mediumSelectedValue}
                                setMediumSelectedValue={setMediumSelectedValue}
                                standardSelectedValue={standardSelectedValue}
                                setStandardSelectedValue={setStandardSelectedValue}
                                divisionSelectedValue={divisionSelectedValue}
                                setDivisionSelectedValue={setDivisionSelectedValue}
                                academicYearSelectedValue={academicYearSelectedValue}
                                setAcademicYearSelectedValue={setAcademicYearSelectedValue}
                                genderOptions={genderOptions}
                                sectionOptions={sectionOptions}
                                InstitiuteOptions={InstitiuteOptions}
                                BoardOptions={BoardOptions}
                                MediumOptions={MediumOptions}
                                StandardOptions={StandardOptions}
                                DivisionOptions={DivisionOptions}
                                StreamOptions={StreamOptions}
                                setStreamOptions={setStreamOptions}
                                handleInstituteChange={handleInstituteChange}
                                handleBoardChange={handleBoardChange}
                                handleMediumChange={handleMediumChange}
                                handleStandardChange={handleStandardChange}
                                handleDivisionChange={handleDivisionChange}
                                handleStreamChange={handleStreamChange}
                                handleAdmissionCalendarChange={handleAdmissionCalendarChange}
                                admissionDate={admissionDate}
                                setadmissionDate={setadmissionDate}
                                toggleAdmissionCalendar={toggleAdmissionCalendar}
                                showAdmissionCalendar={showAdmissionCalendar}
                                setshowAdmissionCalendar={setshowAdmissionCalendar}
                            />
                            : null
                    }

                    {
                        !isMobile || (isMobile && activeTab == 1) ?
                            <ParentDetails
                                parentsfirstName={parentsfirstName}
                                setparentsFirstName={setparentsFirstName}
                                parentslastName={parentslastName}
                                setparentsLastName={setparentsLastName}
                                parentsmobileNumber={parentsmobileNumber}
                                setparentsMobileNumber={setparentsMobileNumber}
                                parentsemail={parentsemail}
                                setparentsEmail={setparentsEmail}
                                occupation={occupation}
                                setOccupation={setOccupation}
                                appartmentName={appartmentName}
                                setappartmentName={setappartmentName}
                                streetName={streetName}
                                setstreetName={setstreetName}
                                landmark={landmark}
                                setlandmark={setlandmark}
                                cityName={cityName}
                                setcityName={setcityName}
                                officeName={officeName}
                                setofficeName={setofficeName}
                                officeStreetName={officeStreetName}
                                setofficeStreetName={setofficeStreetName}
                                officeLandmark={officeLandmark}
                                setofficeLandmark={setofficeLandmark}
                                officeCityName={officeCityName}
                                setofficeCityName={setofficeCityName}
                                State={State}
                                setState={setState}
                                officeState={officeState}
                                setofficeState={setofficeState}
                                officePincode={officePincode}
                                setofficePincode={setofficePincode}
                                pincode={pincode}
                                setpincode={setpincode}
                                errors={errors}
                                setErrors={setErrors}
                                mainButtonLoader={disableSubmit}
                                setMainButtonLoader={setMainButtonLoader}
                                StateOptions={IndianStatesAndUTs}
                                handleStateChange={handleStateChange}
                                handleOfficeStateChange={handleOfficeStateChange}
                                AlternativeParentsMobileNumber={AlternativeParentsMobileNumber}
                                setAlternativeParentsMobileNumber={setAlternativeParentsMobileNumber}
                            />
                            : null
                    }

                    {
                        !isMobile || (isMobile && activeTab == 2) ?
                            <PastAcademicReports
                                instituteName={instituteName}
                                setinstituteName={setinstituteName}
                                medium={medium}
                                setMedium={setMedium}
                                obtainedMarks={obtainedMarks}
                                setObtainedMarks={setObtainedMarks}
                                obtainedPercentage={obtainedPercentage}
                                setObtainedPercentage={setObtainedPercentage}
                                boardLoader={parBoardLoader}
                                mediumLoader={parMediumLoader}
                                standardLoader={parStandardLoader}
                                board={board}
                                setBoard={setBoard}
                                standard={standard}
                                setStandard={setStandard}
                                outOfMarks={outOfMarks}
                                setOutOfMarks={setOutOfMarks}
                                grade={grade}
                                setGrade={setGrade}
                                errors={errors}
                                mainButtonLoader={disableSubmit}
                                mediumOptions={mediumOptionspostacademic}
                                boardOptions={boardOptionspostacademic}
                                standardOptions={standardOptionspostacademic}
                                handleMediumChange={handleMediumChangepostacademic}
                                handleBoardChange={handleboardOptionspostacademicChange}
                                handleStandardChange={handleStandardChangepostacademic}
                            />
                            : null
                    }
                    {

                        (showDiscount && totalFees) ?
                            <AddDiscount
                                setShowDiscount={setShowDiscount}
                                totalFees={totalFees}
                                mainButtonLoader={disableSubmit}
                                selectedDiscountType={selectedDiscountType}
                                handleDiscountTypeChange={handleDiscountTypeChange}
                                discountValue={discountValue}
                                setDiscountValue={setDiscountValue}
                                errors={errors}
                                setErrors={setErrors}
                                setSelectedDiscountType={setSelectedDiscountType}
                            />
                            :
                            <div className='apply-discount-button-admission-form'>
                                <div onClick={() => {
                                    if (totalFees) {
                                        setShowDiscount(true);
                                        setApiError('');
                                    } else {
                                        setApiError("Please select standard first");
                                    }
                                }}>
                                    <p>Apply Discount</p>
                                </div>
                            </div>
                    }
                    {
                        (isMobile && activeTab == 2 && !showDiscount) ?
                            <div className='apply-discount-button'>
                                <div onClick={() => {
                                    if (totalFees) {
                                        setShowDiscount(true);
                                        setApiError('');
                                    } else {
                                        setApiError("Please select standard first");
                                    }
                                }}>
                                    <p>Apply Discount</p>
                                </div>
                            </div>
                            : null
                    }
                    <div className='Admission_form_navigation_button'>
                        <div className='Admission_form_back_button'>
                            {
                                isMobile && (activeTab == 1 || activeTab == 2) ?

                                    <PrimaryButton
                                        buttonText={"Back"}
                                        className={"Admission_form_button"}
                                        onClick={handleButtonClick}

                                    />

                                    : null
                            }
                        </div>
                        <div className='Admission_form_next_button'>
                            {
                                isMobile && (activeTab == 0 || activeTab == 1) ?

                                    <PrimaryButton
                                        buttonText={"Next"}
                                        className={"Admission_form_button"}
                                        onClick={activeTab == 0 ? handleStudentDetail : handleParentDetail}

                                    />

                                    : null
                            }
                        </div>
                    </div>
                    {
                        apiError != '' ?
                            <div className={`admission-form-error-container`} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                                <div className={`login-form-error-container`}>
                                    <FaInfoCircle />
                                    <p>
                                        {apiError}
                                    </p>
                                </div>
                            </div>
                            : null
                    }
                    {
                        !isMobile || (isMobile && activeTab == 2) ?
                            <div className="Admission_form_common_button">
                                <PrimaryButton
                                    buttonText={"Submit"}
                                    className={"Admission_form_common_submit_button"}
                                    onClick={handleSubmitClick}
                                    displayLoader={displaySubmitLoader}
                                    disabled={disableSubmit}
                                />
                            </div>
                            : null
                    }
                </div>
                {/* Admission details section ends */}
            </div>
            {
                openFeeModal ?
                    <div className="modal-overlaycontent_studentfees">
                        <div className="modal-content_studentfees">
                            {/* <span className="close" onClick={handleCloseModal}>&times;</span> */}
                            <StudentFeesInfo
                                onClose={() => {
                                    setOpenFeeModal(false);
                                    navigate("/dashboard");
                                }}
                                firstName={firstName}
                                middleName={middleName}
                                lastName={lastName}
                                institute={instituteSelectedLabel}
                                board={boardSelectedLabel}
                                medium={mediumSelectedLabel}
                                rollNo={rollNo}
                                standard={standardSelectedLabel}
                                division={divisionSelectedLabel}
                                admissionDate={admissionDate}
                                standardFees={standardFees}
                                studentId={studentId}
                                newlyAddedStudent={true}
                                setOpenFeeModal={setOpenFeeModal}
                            // formatedAdmisionDateForCalendar={formatedAdmisionDateForCalendar}
                            />
                        </div>
                    </div>
                    :
                    null
            }
            {
                showWarningModal ?
                    <div className="modal-overlaycontent_studentfees warning-modal-main-container">
                        <div className="modal-content_studentfees warning-modal-inner-container">
                            <div className={`warning-modal-for-image`}>
                                <p>{warningText}</p>
                            </div>
                            <div className={`warning-modal-button-container`}>
                                <PrimaryButton
                                    buttonText="OK"
                                    onClick={() => {
                                        if (wsStatus == 'failed') {
                                            hideWarning();
                                            setOpenFeeModal(true);
                                        } else {
                                            hideWarning();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
    )
}

export default AdmissionForm