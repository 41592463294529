import React, { useState } from 'react'
import '../StudentsDetails/StudentDetails.scss'
import CommonTextInput from '../Common/CommonTextInput/CommonTextInput'
import Calendar from 'react-calendar';
import { FaCalendar } from "react-icons/fa";
import CommonDropDown from '../Common/CommonDropDown';
import moment from 'moment';
import { LuLoader2 } from "react-icons/lu";
import DefaultProfile from '../../Assets/Images/default_profile.png';


const StudentDetails = ({
    firstName,
    setFirstName,
    middleName,
    setMiddleName,
    lastName,
    setLastName,
    mobileNumber,
    setMobileNumber,
    email,
    setEmail,
    rollNo,
    setRollNo,
    dob,
    setDob,
    aadharNumber,
    setAadharNumber,
    selectedGender,
    setSelectedGender,
    selectedSection,
    setselectedSection,
    mainButtonLoader,
    toggleCalendar,
    handleCalendarChange,
    handleGenderChange,
    handlesectionChange,
    sectionLoader,
    setErrors,
    errors,
    showCalendar,
    setAcademicYearSelectedValue,
    academicYearSelectedValue,
    setDivisionSelectedValue,
    divisionSelectedValue,
    streamSelectedValue,
    setStreamSelectedValue,
    setStandardSelectedValue,
    standardSelectedValue,
    setMediumSelectedValue,
    mediumSelectedValue,
    setBoardSelectedValue,
    boardSelectedValue,
    setInstituteSelectedValue,
    instituteselectedValue,
    DivisionOptions,
    StreamOptions,
    disableStream,
    StandardOptions,
    MediumOptions,
    BoardOptions,
    InstitiuteOptions,
    sectionOptions,
    genderOptions,
    handleInstituteChange,
    handleBoardChange,
    handleMediumChange,
    handleStandardChange,
    handleDivisionChange,
    handleStreamChange,
    handleAdmissionCalendarChange,
    admissionDate,
    setadmissionDate,
    toggleAdmissionCalendar,
    showAdmissionCalendar,
    setshowAdmissionCalendar,
    hideHeader,
    viewOnly,
    editing,
    instituteLoader,
    boardLoader,
    mediumLoader,
    standardLoader,
    divisionLoader,
    streamLoader,
    formatedDobForCalendar,
    displayPhoto,
    formatedAdmisionDateForCalendar,
    profileImg,
    setProfileImg,
    isProfileImgPresent,
    setIsProfileImgPresent,
    setProfileImgSize,
    setProfileImgType,
    profileImgSize,
    profileImgType,
    displayWarning,
    isProfileImageUploading,
    setIsProfileImageUploading,
    normalizedRadius,
    circumference,
    strokeDashoffset,
    progress,
    profileImgFromServer,
}) => {

    const CalendarModal = ({ value, onChange, onClose }) => {
        let calendarValue;
        if (value == '') {
            calendarValue = new Date();
        }
        return (
            <>

                {
                    viewOnly ?
                        null
                        :
                        <div className="modal-overlay">
                            <div className="modal-content">
                                <span className="close" onClick={onClose}>&times;</span>
                                <Calendar
                                    onChange={onChange}
                                    value={value}
                                />
                            </div>
                        </div>
                }
            </>
        );
    }

    const renderBasicDetails = (withPhoto) => {
        return <>
            <div className={`row_class ${withPhoto ? 'row-class-with-photo' : ''}`}>
                <div className="students_first_name">
                    <CommonTextInput
                        label={"First name"}
                        required={true}
                        disabled={viewOnly || mainButtonLoader}
                        inputValue={firstName}
                        setInputValue={setFirstName}
                        error={errors.firstName}
                        maxLength={50}
                    />
                </div>
                <div className="students_middle_name">
                    <CommonTextInput
                        label={"Middle name"}
                        required={true}
                        disabled={viewOnly || mainButtonLoader}
                        inputValue={middleName}
                        setInputValue={setMiddleName}
                        error={errors.middleName}
                        maxLength={50}

                    />
                </div>
            </div>
            <div className={`row_class ${withPhoto ? 'row-class-with-photo' : ''}`}>
                <div className="students_last_name">
                    <CommonTextInput
                        label={"Last name"}
                        required={true}
                        disabled={viewOnly || mainButtonLoader}
                        inputValue={lastName}
                        setInputValue={setLastName}
                        error={errors.lastName}
                        maxLength={50}

                    />
                </div>
                <div className="students_dob">
                    <CommonTextInput
                        label={"Date of birth (dd/mm/yyyy)"}
                        required={true}
                        disabled={viewOnly || mainButtonLoader}
                        rightIcon={<FaCalendar onClick={toggleCalendar} />}
                        rightIconStyle={{ marginRight: "2vw" }}
                        inputValue={dob}
                        setInputValue={setDob}
                        error={errors.dob}
                        maxLength={10}
                    />
                    {
                        (showCalendar && !mainButtonLoader) ?
                            <CalendarModal
                                value={formatedDobForCalendar}
                                onChange={(date) => {
                                    handleCalendarChange(date);
                                    toggleCalendar();
                                }}
                                onClose={toggleCalendar}
                            />
                            : null
                    }
                </div>
            </div>
            <div className={`row_class ${withPhoto ? 'row-class-with-photo' : ''}`}>
                <div className="students_mobile_number">
                    <CommonTextInput
                        label={"Mobile number"}
                        inputValue={mobileNumber}
                        disabled={viewOnly || mainButtonLoader}
                        countryCode={true}
                        onKeyDown={(e) => {
                            const numberRegex = /^\d$/
                            if (numberRegex.test(e.key) || e.key == 'Backspace' || e.key == "Tab") {
                                return e.key;
                            } else {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }}
                        setInputValue={setMobileNumber}
                        error={errors.mobileNumber}
                        maxLength={10}
                    />
                </div>
                <div className="students_email_id">
                    <CommonTextInput
                        label={"Email address"}
                        disabled={viewOnly || mainButtonLoader}
                        inputValue={email}
                        setInputValue={setEmail}
                        error={errors.email}
                        maxLength={254}
                    />
                </div>
            </div>
        </>
    }

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileType = file.type;
            const fileSize = file.size
            const reader = new FileReader();
            reader.onloadend = () => {
                if (!isFileTypeValid(fileType)) {
                    displayWarning("Only .png, .jpg, .jpeg files are allowed");
                } else if (!isFileSizeValid(fileSize)) {
                    displayWarning("File size should not exceed 300kb");
                } else {
                    setProfileImg(reader.result);
                    setIsProfileImgPresent(true);
                    setProfileImgType(fileType);
                    setProfileImgSize(fileSize);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    function isFileSizeValid(fileSize) {
        const maxSizeInKB = 300; // 300 KB
        const maxSizeInBytes = maxSizeInKB * 1024; // Convert KB to Bytes
        return fileSize <= maxSizeInBytes;
    }

    function isFileTypeValid(fileType) {
        const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
        return allowedTypes.includes(fileType)
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 480)
    return (
        <div className="student_details_main_container">
            {
                hideHeader ?
                    null
                    :
                    <div className="students_details_title_container">
                        <h1>Student Details</h1>
                    </div>

            }
            {!isMobile ?
                <div className="student_details_content_container">
                    {
                        displayPhoto ?
                            <div className={`main-container-with-photo`}>
                                <div className={`main-row-container-basic`}>
                                    {renderBasicDetails(true)}
                                </div>
                                <div className={`right-side-photo-container`}>
                                    <div className={`photo-view`}>
                                        <img className='uploaded_profile_img' src={profileImg ? profileImg : profileImgFromServer ? profileImgFromServer : DefaultProfile} alt="uploaded_profile_img" />
                                        {
                                            !isProfileImageUploading && editing ?
                                                <label htmlFor="upload-image" >
                                                    <input
                                                        type="file"
                                                        accept="image/png, image/jpeg, image/jpg"
                                                        onChange={handleImageChange}
                                                        style={{ display: 'none' }}
                                                        id="upload-image"
                                                    />
                                                </label>
                                                : null
                                        }
                                        {
                                            isProfileImageUploading ?
                                                <div className="circular-progress">
                                                    <svg height="4vw" width="4vw">
                                                        <circle
                                                            stroke="#e6e6e6"
                                                            fill="transparent"
                                                            strokeWidth="0.2vw"
                                                            r={normalizedRadius}
                                                            cx="2vw"
                                                            cy="2vw"
                                                        />
                                                        <circle
                                                            stroke="#008af1"
                                                            fill="transparent"
                                                            strokeWidth="0.2vw"
                                                            r={normalizedRadius}
                                                            cx="2vw"
                                                            cy="2vw"
                                                            strokeDasharray={circumference + ' ' + circumference}
                                                            strokeDashoffset={strokeDashoffset}
                                                        />
                                                    </svg>
                                                    <div className="circular-progress__text">
                                                        {progress}%
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    {
                                        profileImg && !isProfileImageUploading ?
                                            <div className="close-button" onClick={() => {
                                                setIsProfileImgPresent(false);
                                                setProfileImg('');
                                                setProfileImgSize(0);
                                                setProfileImgType('');
                                            }}>
                                                Cancel
                                            </div>
                                            : null
                                    }
                                    <div className={`image-upload-instructions-container`}>
                                        <p>*Click on Edit, then click on image to change it.</p>
                                        <p>*Max image size can be 300kb.</p>
                                        <p>*Only .jpg, .jpeg and .png images are allowed.</p>
                                        <p>*Preferred dimensions are 110 (width) x 142 (height) in pixels.</p>
                                    </div>
                                </div>
                            </div>
                            :
                            renderBasicDetails()
                    }
                    <div className="row_class">
                        <div className="gender_contianer">
                            <div className="gender_text_container">
                                <h3>Gender<span style={{ color: "red" }}>*</span>:</h3>
                            </div>
                            <div className="gender_category_container">
                                {genderOptions.map((gender, index) => (
                                    <div className='radio_button_cateogry_wrapper' key={index}>
                                        <input
                                            type="radio"
                                            id={`gender-${gender}`}
                                            name="gender"
                                            value={gender}
                                            disabled={viewOnly || mainButtonLoader}
                                            checked={selectedGender === gender}
                                            onChange={handleGenderChange}
                                            style={{ transform: 'scale(1.5)', marginRight: "0.5vw" }}
                                        />
                                        <label htmlFor={gender}>{gender}</label>

                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="section_contianer">
                            <div className="section_text_container">
                                <h3>Section<span style={{ color: "red" }}>*</span>:</h3>
                            </div>
                            <div className="section_category_container">
                                {sectionOptions.map((section, index) => (
                                    <div className='radio_button_cateogry_wrapper' key={index}>
                                        <input
                                            type="radio"
                                            id={section}
                                            name="section"
                                            disabled={viewOnly || mainButtonLoader}
                                            value={section}
                                            checked={selectedSection === section}
                                            onChange={handlesectionChange}
                                            style={{ transform: 'scale(1.5)', marginRight: "0.5vw" }}

                                        />
                                        <label htmlFor={section}>{section}</label>
                                    </div>
                                ))}
                                {
                                    sectionLoader ?
                                        <LuLoader2 className={`radio-spinner`} />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row_class">
                        <div className="left_parent_dropDown_container">
                            <CommonDropDown
                                error={errors.stream}
                                disabled={mainButtonLoader || disableStream || viewOnly}
                                displayLoader={streamLoader}
                                selectedValue={streamSelectedValue}
                                handleSelectChange={handleStreamChange}
                                options={StreamOptions}
                            // extraStyles={{ width: "100%", margin: "0vw 0 0.8vw", padding: "0.63vw" }}

                            />
                        </div>
                        <div className="right_parent_dropDown_container">
                            <CommonDropDown
                                error={errors.institute}
                                disabled={viewOnly || mainButtonLoader}
                                displayLoader={instituteLoader}
                                selectedValue={instituteselectedValue}
                                handleSelectChange={handleInstituteChange}
                                options={InstitiuteOptions}
                            // extraStyles={{ width: "100%", margin: "0vw 0 0.8vw 0", padding: "0.63vw" }}
                            />
                        </div>
                    </div>
                    <div className="row_class">
                        <div className="left_parent_dropDown_container">
                            <CommonDropDown
                                error={errors.sdBoard}
                                disabled={viewOnly || mainButtonLoader}
                                displayLoader={boardLoader}
                                selectedValue={boardSelectedValue}
                                handleSelectChange={handleBoardChange}
                                options={BoardOptions}
                            // extraStyles={{ width: "100%", margin: "0vw 0 0.8vw 0", padding: "0.63vw" }}

                            />
                        </div>
                        <div className="right_parent_dropDown_container">
                            <CommonDropDown
                                error={errors.sdMedium}
                                displayLoader={mediumLoader}
                                disabled={viewOnly || mainButtonLoader}
                                selectedValue={mediumSelectedValue}
                                handleSelectChange={handleMediumChange}
                                options={MediumOptions}
                            //  extraStyles={{ width: "100%", margin: "0vw 0 0.8vw 0", padding: "0.63vw" }}
                            />
                        </div>
                    </div>
                    <div className="row_class">
                        <div className="left_parent_dropDown_container">
                            <CommonDropDown
                                error={errors.sdStandard}
                                displayLoader={standardLoader}
                                selectedValue={standardSelectedValue}
                                handleSelectChange={handleStandardChange}
                                options={StandardOptions}
                                disabled={viewOnly || mainButtonLoader}
                            //extraStyles={{ width: "100%", margin: "0vw 0 0vw 0", padding: "0.63vw" }}
                            />
                        </div>
                        <div className="right_parent_dropDown_container">
                            <CommonDropDown
                                error={errors.division}
                                disabled={viewOnly || mainButtonLoader}
                                displayLoader={divisionLoader}
                                selectedValue={divisionSelectedValue}
                                handleSelectChange={handleDivisionChange}
                                options={DivisionOptions}
                            // extraStyles={{ width: "100%", margin: "0vw 0 0vw 0", padding: "0.63vw" }}

                            />
                        </div>
                    </div>
                    <div className="row_class">
                        <div className={`rollno_container`}>
                            <CommonTextInput
                                label={"Roll number"}
                                disabled={viewOnly || mainButtonLoader}
                                inputValue={rollNo}
                                required={true}
                                setInputValue={setRollNo}
                                error={errors.rollNo}
                                maxLength={10}
                            />
                        </div>
                        <div className="students_last_name student_aadhar_no">
                            <CommonTextInput
                                label={"Aadhar number"}
                                required={true}
                                disabled={mainButtonLoader || viewOnly || editing}
                                inputValue={aadharNumber}
                                setInputValue={setAadharNumber}
                                error={errors.aadharNumber}
                                maxLength={12}
                            />
                        </div>
                    </div>
                    <div className="row_class">
                        <div className="students_dob">
                            <CommonTextInput
                                label={"Admission date (dd/mm/yyyy)"}
                                required={true}
                                disabled={viewOnly || mainButtonLoader}
                                rightIcon={viewOnly || mainButtonLoader ? null : <FaCalendar onClick={toggleAdmissionCalendar} />}
                                rightIconStyle={{ marginRight: "2vw" }}
                                inputValue={admissionDate}
                                setInputValue={setadmissionDate}
                                error={errors.admissionDate}
                                maxLength={10}
                            />
                            {
                                (showAdmissionCalendar && !mainButtonLoader) ?
                                    <CalendarModal
                                        value={formatedAdmisionDateForCalendar}
                                        onChange={(date) => {
                                            handleAdmissionCalendarChange(date);
                                            toggleAdmissionCalendar();
                                        }}
                                        onClose={toggleAdmissionCalendar}
                                    />
                                    : null
                            }
                        </div>
                    </div>
                </div>
                :
                <div className="student_details_content_container">
                    {
                        displayPhoto ?
                            <div className={`main-container-with-photo`}>
                                <div className={`right-side-photo-container`}>
                                    <div className={`photo-view`}>
                                        <img className='uploaded_profile_img' src={profileImg ? profileImg : profileImgFromServer ? profileImgFromServer : DefaultProfile} alt="uploaded_profile_img" />
                                        {
                                            !isProfileImageUploading && editing ?
                                                <label htmlFor="upload-image" >
                                                    <input
                                                        type="file"
                                                        accept="image/png, image/jpeg, image/jpg"
                                                        onChange={handleImageChange}
                                                        style={{ display: 'none' }}
                                                        id="upload-image"
                                                    />
                                                </label>
                                                : null
                                        }
                                        {
                                            isProfileImageUploading ?
                                                <div className="circular-progress">
                                                    <svg height="4vw" width="4vw">
                                                        <circle
                                                            stroke="#e6e6e6"
                                                            fill="transparent"
                                                            strokeWidth="0.2vw"
                                                            r={normalizedRadius}
                                                            cx="2vw"
                                                            cy="2vw"
                                                        />
                                                        <circle
                                                            stroke="#008af1"
                                                            fill="transparent"
                                                            strokeWidth="0.2vw"
                                                            r={normalizedRadius}
                                                            cx="2vw"
                                                            cy="2vw"
                                                            strokeDasharray={circumference + ' ' + circumference}
                                                            strokeDashoffset={strokeDashoffset}
                                                        />
                                                    </svg>
                                                    <div className="circular-progress__text">
                                                        {progress}%
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </div>
                                    {
                                        profileImg && !isProfileImageUploading ?
                                            <div className="close-button" onClick={() => {
                                                setIsProfileImgPresent(false);
                                                setProfileImg('');
                                                setProfileImgSize(0);
                                                setProfileImgType('');
                                            }}>
                                                Cancel
                                            </div>
                                            : null
                                    }
                                    <div className={`image-upload-instructions-container`}>
                                        <p>*Click on Edit, then click on image to change it.</p>
                                        <p>*Max image size can be 300kb.</p>
                                        <p>*Only .jpg, .jpeg and .png images are allowed.</p>
                                        <p>*Preferred dimensions are 110 (width) x 142 (height) in pixels.</p>
                                    </div>
                                </div>
                                <div className={`main-row-container-basic`}>
                                    {renderBasicDetails(true)}
                                </div>
                            </div>
                            :
                            renderBasicDetails()
                    }
                    <div className="row_class">
                        <div className="gender_contianer">
                            <div className="gender_text_container">
                                <h3>Gender<span style={{ color: "red" }}>*</span>:</h3>
                            </div>
                            <div className="gender_category_container">
                                {genderOptions.map((gender, index) => (
                                    <div className='radio_button_cateogry_wrapper' key={index}>
                                        <input
                                            type="radio"
                                            id={`gender-${gender}`}
                                            name="gender"
                                            value={gender}
                                            disabled={viewOnly || mainButtonLoader}
                                            checked={selectedGender === gender}
                                            onChange={handleGenderChange}
                                            style={{ transform: 'scale(1.5)', marginRight: "0.5vw" }}
                                        />
                                        <label htmlFor={gender}>{gender}</label>

                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="section_contianer">
                            <div className="section_text_container">
                                <h3>Section<span style={{ color: "red" }}>*</span>:</h3>
                            </div>
                            <div className="section_category_container">
                                {sectionOptions.map((section, index) => (
                                    <div className='radio_button_cateogry_wrapper' key={index}>
                                        <input
                                            type="radio"
                                            id={section}
                                            name="section"
                                            disabled={viewOnly || mainButtonLoader}
                                            value={section}
                                            checked={selectedSection === section}
                                            onChange={handlesectionChange}
                                            style={{ transform: 'scale(1.5)', marginRight: "0.5vw" }}

                                        />
                                        <label htmlFor={section}>{section}</label>
                                    </div>
                                ))}
                                {
                                    sectionLoader ?
                                        <LuLoader2 className={`radio-spinner`} />
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row_class">
                        <div className="left_parent_dropDown_container">
                            <CommonDropDown
                                error={errors.stream}
                                disabled={mainButtonLoader || disableStream || viewOnly}
                                displayLoader={streamLoader}
                                selectedValue={streamSelectedValue}
                                handleSelectChange={handleStreamChange}
                                options={StreamOptions}
                            // extraStyles={{ width: "100%", margin: "0vw 0 0.8vw", padding: "0.63vw" }}

                            />
                        </div>
                        <div className="right_parent_dropDown_container">
                            <CommonDropDown
                                error={errors.institute}
                                disabled={viewOnly || mainButtonLoader}
                                displayLoader={instituteLoader}
                                selectedValue={instituteselectedValue}
                                handleSelectChange={handleInstituteChange}
                                options={InstitiuteOptions}
                            // extraStyles={{ width: "100%", margin: "0vw 0 0.8vw 0", padding: "0.63vw" }}
                            />
                        </div>
                    </div>
                    <div className="row_class">
                        <div className="left_parent_dropDown_container">
                            <CommonDropDown
                                error={errors.sdBoard}
                                disabled={viewOnly || mainButtonLoader}
                                displayLoader={boardLoader}
                                selectedValue={boardSelectedValue}
                                handleSelectChange={handleBoardChange}
                                options={BoardOptions}
                            // extraStyles={{ width: "100%", margin: "0vw 0 0.8vw 0", padding: "0.63vw" }}

                            />
                        </div>
                        <div className="right_parent_dropDown_container">
                            <CommonDropDown
                                error={errors.sdMedium}
                                displayLoader={mediumLoader}
                                disabled={viewOnly || mainButtonLoader}
                                selectedValue={mediumSelectedValue}
                                handleSelectChange={handleMediumChange}
                                options={MediumOptions}
                            //  extraStyles={{ width: "100%", margin: "0vw 0 0.8vw 0", padding: "0.63vw" }}
                            />
                        </div>
                    </div>
                    <div className="row_class">
                        <div className="left_parent_dropDown_container">
                            <CommonDropDown
                                error={errors.sdStandard}
                                displayLoader={standardLoader}
                                selectedValue={standardSelectedValue}
                                handleSelectChange={handleStandardChange}
                                options={StandardOptions}
                                disabled={viewOnly || mainButtonLoader}
                            //extraStyles={{ width: "100%", margin: "0vw 0 0vw 0", padding: "0.63vw" }}
                            />
                        </div>
                        <div className="right_parent_dropDown_container">
                            <CommonDropDown
                                error={errors.division}
                                disabled={viewOnly || mainButtonLoader}
                                displayLoader={divisionLoader}
                                selectedValue={divisionSelectedValue}
                                handleSelectChange={handleDivisionChange}
                                options={DivisionOptions}
                            // extraStyles={{ width: "100%", margin: "0vw 0 0vw 0", padding: "0.63vw" }}

                            />
                        </div>
                    </div>
                    <div className="row_class">
                        <div className={`rollno_container`}>
                            <CommonTextInput
                                label={"Roll number"}
                                disabled={viewOnly || mainButtonLoader}
                                inputValue={rollNo}
                                required={true}
                                setInputValue={setRollNo}
                                error={errors.rollNo}
                                maxLength={10}
                            />
                        </div>
                        <div className="students_last_name student_aadhar_no">
                            <CommonTextInput
                                label={"Aadhar number"}
                                required={true}
                                disabled={mainButtonLoader || viewOnly || editing}
                                inputValue={aadharNumber}
                                setInputValue={setAadharNumber}
                                error={errors.aadharNumber}
                                maxLength={12}
                            />
                        </div>
                    </div>
                    <div className="row_class">
                        <div className="students_dob">
                            <CommonTextInput
                                label={"Admission date (dd/mm/yyyy)"}
                                required={true}
                                disabled={viewOnly || mainButtonLoader}
                                rightIcon={viewOnly || mainButtonLoader ? null : <FaCalendar onClick={toggleAdmissionCalendar} />}
                                rightIconStyle={{ marginRight: "2vw" }}
                                inputValue={admissionDate}
                                setInputValue={setadmissionDate}
                                error={errors.admissionDate}
                                maxLength={10}
                            />
                            {
                                (showAdmissionCalendar && !mainButtonLoader) ?
                                    <CalendarModal
                                        value={formatedAdmisionDateForCalendar}
                                        onChange={(date) => {
                                            handleAdmissionCalendarChange(date);
                                            toggleAdmissionCalendar();
                                        }}
                                        onClose={toggleAdmissionCalendar}
                                    />
                                    : null
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default StudentDetails