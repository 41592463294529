import React, { useState } from 'react';
import './LoginModal.scss';
import SideImage from '../../../Assets/Images/login-modal-side-image.svg';
import { FaTimes, FaUser, FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import Logo from '../../../Assets/Images/AlphacubesLogo.svg';
import CommonTextInput from '../../Common/CommonTextInput';
import PrimaryButton from '../../Common/PrimaryButton';
import ConsentCheckbox from '../../Common/ConsentCheckbox/ConsentCheckbox';
import { FaCheck, FaInfoCircle, FaChevronLeft, FaExclamationTriangle } from "react-icons/fa";
import { encrypt } from '../../../Utils/CryptoJs/CryptoJs';
import { ApiCall } from '../../../Utils/ApiComponets/ApiCall';
import { useNavigate } from 'react-router-dom';
import validation from '../../../Utils/Validations/Validations';

const LoginModal = (props) => {

    const navigate = useNavigate();

    const [apiError, setApiError] = useState('');
    const [isConsentChecked, setIsConsentChecked] = useState(true);
    const [isRememberChecked, setRememberChecked] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [displaySoftLock, setDisplaySoftLock] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [consentCheckError, setConsentCheckError] = useState('');
    const [disableLogin, setDisableLogin] = useState(false);
    const [loginLoader, setLoginLoader] = useState(false);

    const handleLoginClick = async () => {
        setApiError('');
        let errors = {};
        const mobileNumberRegex = /^\d{10}$/;
        const emailRegex = /^[a-zA-Z0-9._%+-]{1,64}@[a-zA-Z0-9.-]{1,253}\.[a-zA-Z]{2,63}$/;

        const usernameRegex = /^[a-zA-Z]+\.[a-zA-Z]+@\d{8}$/;

        try {
            console.log(username == '' || password == '' || !isConsentChecked, "username == '' || password == '' || !isConsentChecked");
            if (username == '' || password == '' || !isConsentChecked) {


                setUsernameError(errors.username = validation.isValidUserName(username));
                setPasswordError(errors.password = validation.isValidPassword(password));
                setConsentCheckError(errors.isConsentChecked = validation.isConsentChecked(isConsentChecked));

            } else {
                setPasswordError('');
                setConsentCheckError('');
                console.log(username);
                console.log((!/^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(username) || /^[0-9]+@/.test(username)), "(!/^[a-zA-Z][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(username) || /^[0-9]+@/.test(username))");
                if (mobileNumberRegex.test(username) || (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(username) || /^[0-9]+@/.test(username)) || usernameRegex.test(username)) {
                    setUsernameError('');
                    //add api call here
                    setDisableLogin(true);
                    setLoginLoader(true);
                    const body = {
                        "loginDetails": {
                            "countryCode": "+91",
                            "username": username,
                            "password": encrypt(password),
                            "remember": isRememberChecked ? 1 : 0
                        },
                        "consentData": {
                            "type": "login",
                            "userAgent": navigator.userAgent
                        }
                    }
                    const response = await ApiCall('login/validateuser', body);
                    setLoginLoader(false);
                    console.log(response);
                    setDisableLogin(false);
                    setApiError('');
                    document.getElementsByTagName("body")[0].classList.remove('overflow-hide');
                    if (isRememberChecked) {
                        localStorage.setItem("accessToken", response.responseJson.auth.accessToken);
                        localStorage.setItem("userId", response.responseJson.userDetails.userId);
                        localStorage.setItem("firstName", response.responseJson.userDetails.firstName);
                        localStorage.setItem("lastName", response.responseJson.userDetails.lastName);
                    } else {
                        sessionStorage.setItem("accessToken", response.responseJson.auth.accessToken);
                        sessionStorage.setItem("userId", response.responseJson.userDetails.userId);
                        sessionStorage.setItem("firstName", response.responseJson.userDetails.firstName);
                        sessionStorage.setItem("lastName", response.responseJson.userDetails.lastName);
                    }
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth' // Smooth scrolling animation
                    });
                    navigate("/dashboard")
                } else {
                    setUsernameError("Please enter valid username");
                }
            }
        } catch (error) {
            console.log(error);
            setLoginLoader(false);
            setPassword('');
            setDisableLogin(false);
            if (error.status == 401) {
                setApiError(error.errors);
                if (error.attemptLeft <= 0) {
                    setDisplaySoftLock(true);
                }
            } else if (error.status == 400) {
                setApiError("Please check inputs");
            } else if (error.status == 500) {
                setApiError("Something went wrong");
            } else {
                setApiError("Something went wrong");
            }
            //setApiError(error.error);
        }
    }

    return (
        <div style={{ top: props.scrollY }} className={`login-modal-outer-container`}>
            <div className={`login-modal-main-container`}>
                <div className={`login-left-section`}>
                    <img src={SideImage} />
                </div>
                <div className={`login-right-section`}>
                    <div className={`login-modal-close-container`}>
                        <div onClick={() => {
                            props.setDisplayLoginPopup(false);
                        }} className={`login-modal-close-button`}>
                            <FaTimes className={`login-modal-close-icon`} />
                        </div>
                    </div>
                    <div className={`login-form-container`}>
                        <div className={`login-logo-container`}>
                            <img src={Logo} />
                        </div>
                        {
                            displaySoftLock ?
                                <div className={`login-soft-lock-container`}>
                                    <div className={`back-to-login-button-container`}>
                                        <div
                                            onClick={() => {
                                                setDisplaySoftLock(false);
                                                setPassword('');
                                                setApiError('');
                                                setUsername('');
                                                setIsPasswordVisible(false);
                                            }}
                                            className={`back-to-login-button`}>
                                            <FaChevronLeft />
                                            <p>
                                                Back to login
                                            </p>
                                        </div>
                                    </div>
                                    <div className={`soft-lock-warning-text-container`}>
                                        <div className={`soft-lock-warning-container`}>
                                            <FaExclamationTriangle />
                                            <p>
                                                Warning
                                            </p>
                                        </div>
                                        <div className={`soft-lock-text`}>
                                            <p>
                                                5 incorrect attempts have been made, please try after some time
                                            </p>
                                        </div>
                                    </div>
                                    <div className={`login-or-line`}>
                                        <div className={`or-line`} />
                                        <p>
                                            OR
                                        </p>
                                        <div className={`or-line`} />
                                    </div>
                                    <div className={`login-reset-password-container`}>
                                        <p>
                                            <a className={`link-text`}>Reset</a> your password
                                        </p>
                                    </div>
                                    <div className={`login-contact-text`}>
                                        <p>
                                            Having trouble logging in? <a className={`link-text`}>Contact Support</a>
                                        </p>
                                    </div>
                                </div>
                                :
                                <>
                                    <div className={`login-form-heading-container`}>
                                        <p>
                                            Please Login To Continue
                                        </p>
                                    </div>
                                    {
                                        apiError != '' ?
                                            <div className={`login-form-error-container`}>
                                                <FaInfoCircle />
                                                <p>
                                                    {apiError}
                                                </p>
                                            </div>
                                            : null
                                    }
                                    <div className={`login-form`}>
                                        <CommonTextInput
                                            label={"Username, email or mobile*"}
                                            leftIcon={<FaUser />}
                                            inputValue={username}
                                            setInputValue={setUsername}
                                            error={usernameError}
                                            disabled={disableLogin}
                                            maxLength="254"
                                        // error={"Please enter valid username"}
                                        // rightIcon={<FaEye />}
                                        // countryCode={true}
                                        />
                                        <CommonTextInput
                                            label={"Password*"}
                                            leftIcon={<FaLock />}
                                            inputType={isPasswordVisible ? "text" : "password"}
                                            rightIconToggle={setIsPasswordVisible}
                                            // error={"Please enter valid password"}
                                            error={passwordError}
                                            rightIcon={isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                                            inputValue={password}
                                            disabled={disableLogin}
                                            setInputValue={setPassword}
                                            maxlength={32}

                                        // countryCode={true}
                                        />
                                        <ConsentCheckbox
                                            disabled={disableLogin}
                                            isConsentChecked={isConsentChecked}
                                            setIsConsentChecked={setIsConsentChecked}
                                            setConsentCheckError={setConsentCheckError}
                                            consentCheckError={consentCheckError}
                                        />
                                        <div className={`login-form-login-button-container`}>
                                            <PrimaryButton
                                                onClick={handleLoginClick}
                                                buttonText={"Login"}
                                                disabled={disableLogin}
                                                displayLoader={loginLoader}
                                            />
                                        </div>
                                        <div className={`login-remember-forgot-container`}>
                                            <div className={`login-remember-container`}>
                                                <div onClick={() => {
                                                    if (disableLogin) {
                                                        return;
                                                    }
                                                    setRememberChecked(val => !val);
                                                }} className={`remember-checkbox ${disableLogin ? 'disable' : ''}`}>
                                                    {
                                                        isRememberChecked ?
                                                            <FaCheck />
                                                            : null
                                                    }
                                                </div>
                                                <div className={`login-remember-text`}>
                                                    <p>
                                                        Remember me
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={`login-forgot-container`}>
                                                <p className={`link-text`}>
                                                    Forgot Password?
                                                </p>
                                            </div>
                                        </div>
                                        <div className={`login-register-text`}>
                                            <p>
                                                Don't have an account? <a onClick={() => {
                                                    props.setDisplayLoginPopup(false);
                                                    document.getElementsByTagName("body")[0].classList.remove('overflow-hide');
                                                    window.scrollTo({
                                                        top: 0,
                                                        behavior: 'smooth' // Smooth scrolling animation
                                                    });
                                                    navigate("/registration");
                                                }} className={`link-text`}>Register</a> Now!
                                            </p>
                                        </div>
                                        <div className={`login-contact-text`}>
                                            <p>
                                                Having trouble logging in? <a className={`link-text`}>Contact Support</a>
                                            </p>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoginModal;