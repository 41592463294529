import React from 'react';
import './CommonFooter.scss';
import Logo from '../../../Assets/Images/AlphacubesLogo.svg';
import { FaFacebook, FaInstagram, FaWhatsapp, FaTwitterSquare } from "react-icons/fa";

const CommonFooter = () => {

    return (
        <div className={`footer-main-container`}>
            <div className={`footer-content-container`}>
                <div className={`footer-logo-container`}>
                    <img className={`footer-logo`} src={Logo} />
                </div>
                <div className={`footer-nav-links-container`}>
                    <ul>
                        <li>
                            <a>
                                Products
                            </a>
                        </li>
                        <li>
                            <a>
                                Pricing
                            </a>
                        </li>
                        <li>
                            <a>
                                Contact Us
                            </a>
                        </li>
                        <li>
                            <a>
                                About Us
                            </a>
                        </li>
                    </ul>
                </div>
                <div className={`footer-terms-conditions-copyright-text-container`}>
                    <div className={`terms-privacy-link`}>
                        <p>
                            Terms and conditions
                        </p>
                        <p className={`footer-privacy-text`}>
                            Privacy policy
                        </p>
                    </div>
                    <div className={`copyright-text`}>
                        <p>
                            Alphacubes Software Solutions Private Limited. All Rights Reserved.
                        </p>
                    </div>
                </div>
                <div className={`footer-social-media-container`}>
                    <FaFacebook className='footer-social-media-link footer-facebook' />
                    <FaInstagram className='footer-social-media-link footer-instagram' />
                    <FaWhatsapp className='footer-social-media-link footer-whatsapp' />
                    <FaTwitterSquare className='footer-social-media-link footer-twitter' />
                </div>
            </div>
        </div>
    )
}

export default CommonFooter;