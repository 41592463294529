import React, { useState, useEffect, } from 'react'
import '../Admission/Admission.scss'
import AdmissionForm from '../../Components/AdmissionForm'
import PreLoginHeader from '../../Components/Common/PreLoginHeader/PreLoginHeader';
import PostLoginHeader from '../../Components/Common/PostLoginHeader';

import { useNavigate } from 'react-router-dom';
import { FaChevronLeft, } from "react-icons/fa";

const Admission = () => {
    const [displayLoginPopup, setDisplayLoginPopup] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Admission Form';
    },[]);

    return (
        <>
            {/* <PreLoginHeader setDisplayLoginPopup={setDisplayLoginPopup} /> */}
            <PostLoginHeader />
            <div className={`back-to-dashboard-button-container`}>
                <div
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth' // Smooth scrolling animation
                        });
                        navigate("/dashboard");
                    }}
                    className={`back-to-dashboard-button`}>
                    <FaChevronLeft />
                    <p>
                        Back to dashboard
                    </p>
                </div>
            </div>
            <AdmissionForm />
        </>
    )
}

export default Admission