import React from 'react';
import './HeroSection.scss';
import HeroImage1 from '../../../Assets/Images/hero-image-1.svg';
import PrimaryButton from '../../Common/PrimaryButton';

const HeroSection = () => {

    return (
        <div className='hero-section-outer-container'>
            <div className={`hero-section-main-container`}>
                <div className={`hero-left-section`}>
                    <div className={`hero-left-content-section`}>
                        <p className={`hero-left-section-heading`}>
                            Lorem Ipsum Dolor Sit Amet
                        </p>
                        <p className={`hero-left-section-para`}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec mattis est eu ultrices ultrices. Vivamus vestibulum nisi diam. Praesent pharetra nec mauris nec suscipit. Phasellus eget dui ut quam porta interdum. Vivamus 
                        </p>
                    </div>
                    <div className={`hero-left-button-container`}>
                        <PrimaryButton
                            buttonText={"Start Trial"}
                        />
                    </div>
                </div>
                <div className={`hero-right-section`}>
                    <img className={`hero-carousel-image`} src={HeroImage1} />
                </div>
            </div>
            <div className={`hero-section-dots-container`}>
                <div className={`hero-caraousel-dot active`} />
                <div className={`hero-caraousel-dot`} />
                <div className={`hero-caraousel-dot`} />
            </div>
        </div>
    )
}

export default HeroSection;