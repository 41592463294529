import React, { useEffect, useState, } from 'react'
import '../FeesDetails/FeesDetails.scss';
import PrimaryButton from '../Common/PrimaryButton';
import { ApiCall } from '../../Utils/ApiComponets/ApiCall';
import StudentFeesInfo from '../StudentFeesInfo';
import AddDiscount from '../AddDiscount';

export const FeesDetails = () => {

    const [data, setData] = useState([]);

    const [transactionData, setTransactionData] = useState([]);

    const [standardFees, setStandardFees] = useState('');
    const [discountedFees, setDiscountedFees] = useState('');
    const [openFeeModal, setOpenFeeModal] = useState(false);
    const [openAddDiscountModal, setOpenAddDiscountModal] = useState(false);
    const [totalFees, setTotalFees] = useState(0);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [selectedDiscountType, setSelectedDiscountType] = useState('Percentage');
    const [discountValue, setDiscountValue] = useState('');
    const [errors, setErrors] = useState({});
    const [amountAfterDiscount, setAmountAfterDiscount] = useState('');
    const [showSubmitForAddDiscount, setShowSubmitForAddDiscount] = useState(false);
    const [firstTimeDone, setFirstTimeDone] = useState(false);
    const [showAddDiscountButton, setShowAddDicountButton] = useState(false);

    useEffect(() => {
        if (amountAfterDiscount == '' || amountAfterDiscount < 0) {
            setShowSubmitForAddDiscount(false);
        } else {
            setShowSubmitForAddDiscount(true);
        }
    }, [amountAfterDiscount]);

    const handleDiscountTypeChange = (e) => {
        setSelectedDiscountType(e.target.value);
    }

    useEffect(() => {
        if (firstTimeDone && !openFeeModal) {
            init();
        }
    }, [openFeeModal]);

    useEffect(() => {
        if (firstTimeDone && !openAddDiscountModal) {
            init();
        }
    }, [openAddDiscountModal]);

    useEffect(() => {
        if (!firstTimeDone) {
            init();
            setFirstTimeDone(true);
        }
    }, []);

    useEffect(()  => {
        if(transactionData.length == 0) {
            if(totalFees && discountedFees && discountedFees >= totalFees) {
                setShowAddDicountButton(true);
            } else {
                setShowAddDicountButton(false);
            }
        } else {
            setShowAddDicountButton(false);
        }
    }, [totalFees, discountedFees, transactionData]);

    const init = () => {
        triggerGetStudentFeesInstallmentsDetails();
        triggerGetTransactionDetails();
        triggerGetStudentDetails();
        triggerGetStandardFees();
    }

    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [institute, setInstitute] = useState('');
    const [board, setBoard] = useState('');
    const [medium, setMedium] = useState('');
    const [standard, setStandard] = useState('');
    const [division, setDivision] = useState('');
    const [rollNo, setRollNo] = useState('');
    const [admissionDate, setAdmissionDate] = useState('');

    const triggerGetStudentDetails = () => {
        return new Promise(async (res, rej) => {
            try {
                const studentId = sessionStorage.getItem("selectedStudent");
                const response = await ApiCall("student/getstudent?studentId=" + studentId + "&filter=" + 'studentpersonaldetails', null, 'get');
                let studentDetails = response.responseJson.studentDetails.studentPersonalDetails;
                setFirstName(studentDetails.firstName);
                setMiddleName(studentDetails.middileName);
                setLastName(studentDetails.lastName);
                setInstitute(studentDetails.institute);
                setBoard(studentDetails.board);
                setMedium(studentDetails.medium);
                setStandard(studentDetails.standard);
                setDivision(studentDetails.division);
                setRollNo(studentDetails.rollNumber.toString());
                setAdmissionDate(studentDetails.admissionDate);
                res();
            } catch (e) {
                console.log(e);
                rej(e);
            }
        })
    }

    const triggerGetStandardFees = () => {
        return new Promise(async (res, rej) => {
            try {
                const standardId = sessionStorage.getItem("standardId");
                const response = await ApiCall('fee/getstandardfee?standardId=' + standardId, null, 'get');
                setTotalFees(response.responseJson.standardFeeDetails.discountedAmount);
                setStandardFees(response.responseJson.standardFeeDetails.totalAmount);
                res();
            } catch (e) {
                console.log(e);
                rej(e);
            }
        })
    }

    const triggerGetTransactionDetails = () => {
        return new Promise(async (res, rej) => {
            try {
                const studentId = sessionStorage.getItem("selectedStudent");
                const response = await ApiCall('fee/gettransaction?userId=' + studentId, null, 'get');
                console.log(response, 'response of gettransaction');
                const transactionList = response.responseJson.standardFeeDetails.map((item, index) => {
                    return {
                        _id: item._id,
                        srNo: index + 1,
                        amount: item.amountPaid,
                        paidDate: item.paymentDate,
                        modeOfPayment: item.ModeOfPayment,
                        referenceId: item.referenceId
                    }
                });
                setTransactionData(transactionList);
                res();
            } catch (e) {
                rej(e);
            }
        })
    }


    const triggerGetStudentFeesInstallmentsDetails = () => {
        return new Promise(async (res, rej) => {
            try {
                const studentId = sessionStorage.getItem("selectedStudent");
                const response = await ApiCall('fee/getstudentfeesinstallments?userId=' + studentId, null, 'get');
                console.log(response);
                const discountedFees = response.responseJson.studentFeesInstallmentDetails.reduce((acc, current) => acc + current.amount, 0);
                setDiscountedFees(discountedFees);

                const installmentData = response.responseJson.studentFeesInstallmentDetails.map((item, index) => {
                    return {
                        _id: item._id,
                        srno: index + 1,
                        installment: item.installmentName,
                        paidDate: item.paidDate,
                        amount: item.amount,
                        paidAmt: item.amountPaid,
                        balanceAmt: item.balanceAmount,
                        lateFees: item.lateFee,
                        dueDate: item.dueDate,
                        status: item.status,
                        receipt: 'Receipt 12345'
                    }
                });
                setData(installmentData);
                res();
            } catch (e) {
                rej(e);
            }
        })
    }

    const handleAddDiscountSubmit = () => {
        return new Promise(async (res, rej) => {
            try {
                const studentId = sessionStorage.getItem("selectedStudent");
                const body = {
                    "discountData": {
                        "userId": studentId,
                        "discountKey": selectedDiscountType == 'Percentage' ? 'p' : 'a',
                        "discountValue": discountValue,
                    }
                }
                setDisableSubmit(true);
                const response = await ApiCall('fee/createstudentdiscount', body);
                setOpenAddDiscountModal(false);
                setAmountAfterDiscount('');
                setDisableSubmit(false);
                res();
            } catch (e) {
                console.log(e);
                rej(e);
            }
        })
    }


    return (
        <div className="fees_details_main_container">
            <div className='fees_tab_buttons_container'>
                {
                    showAddDiscountButton ?
                        <div className='add_discount_button_container'>
                            <PrimaryButton
                                buttonText="Add Discount"
                                onClick={() => setOpenAddDiscountModal(true)}
                            />
                        </div>
                        : null
                }
                <div className='add_fees_button_container'>
                    <PrimaryButton
                        buttonText="Add Fees"
                        onClick={() => setOpenFeeModal(true)}
                    />
                </div>
            </div>
            <div className="installment_header_container">
                <h1>Installment Details</h1>
            </div>
            <div className="total_fees_discount_fees_parent_contianer">
                <div className="total_fees_container">
                    <h1>Total Fees : {standardFees}</h1>
                </div>
                <div className="discounted_container">
                    <h1>Total Fees After Discount : {discountedFees}</h1>
                </div>
            </div>
            <div className="installment_mobile_header_container">
                <h1>Installment Details</h1>
            </div>
            <table className="fees_table">
                <thead>
                    <tr>
                        <th>Sr. No.</th>
                        <th>Installment</th>
                        <th>Paid Date</th>
                        <th>Amount</th>
                        <th>Paid Amt</th>
                        <th>Balance Amt</th>
                        <th>Late Fees</th>
                        <th>Due Date</th>
                        <th>Status</th>
                        <th>Receipt</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((entry, index) => (
                        <tr key={index}>
                            <td>{entry.srno}</td>
                            <td>{entry.installment}</td>
                            <td>{entry.paidDate}</td>
                            <td>{entry.amount}</td>
                            <td>{entry.paidAmt}</td>
                            <td>{entry.balanceAmt}</td>
                            <td>{entry.lateFees}</td>
                            <td>{entry.dueDate}</td>
                            <td>{entry.status}</td>
                            <td>
                                {
                                    entry.status == 'unpaid' ?
                                        <p className='link-text disabled-text'>
                                            Download
                                        </p>
                                        :
                                        <a className={`link-text`} href={'/feesReceiptInstallment?token=' + sessionStorage.getItem("accessToken") + '&installmentId=' + entry._id + '&installment=' + entry.installment + '&amount=' + entry.amount} target='_blank'>
                                            Download
                                        </a>
                                }
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {
                transactionData.length ?
                    <>
                        <div className="installment_header_container">
                            <h1 style={{ margin: "1.5vw 0" }}>Transaction Details</h1>
                        </div>

                        <table className="fees_table" style={{ marginBottom: "1vw" }}>
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Amount Paid</th>
                                    <th>Payment Date</th>
                                    <th>Mode of Payment</th>
                                    <th>Reference Id</th>
                                    <th>Receipt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactionData.map((entry, index) => (
                                    <tr key={index}>
                                        <td>{entry.srNo}</td>
                                        <td>{entry.amount}</td>
                                        <td>{entry.paidDate}</td>
                                        <td>{entry.modeOfPayment}</td>
                                        <td>{entry.referenceId}</td>
                                        <td>
                                            <a className={`link-text`} href={'/feesReceipt?token=' + sessionStorage.getItem("accessToken") + '&transactionId=' + entry._id} target='_blank'>
                                                Download
                                            </a>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </> : null
            }

            {
                openFeeModal ?
                    <div className="modal-overlaycontent_studentfees">
                        <div className="modal-content_studentfees">
                            <StudentFeesInfo
                                onClose={() => {
                                    setOpenFeeModal(false);
                                }}
                                firstName={firstName}
                                middleName={middleName}
                                lastName={lastName}
                                institute={institute}
                                board={board}
                                medium={medium}
                                rollNo={rollNo}
                                standard={standard}
                                division={division}
                                admissionDate={admissionDate}
                                standardFees={standardFees}
                                studentId={sessionStorage.getItem("selectedStudent")}
                                setOpenFeeModal={setOpenFeeModal}
                            />
                        </div>
                    </div>
                    :
                    null
            }
            {
                openAddDiscountModal ?
                    <div className="modal-overlaycontent_studentfees">
                        <div className="modal-content_studentfees">
                            <AddDiscount
                                setShowDiscount={setOpenAddDiscountModal}
                                totalFees={totalFees}
                                mainButtonLoader={disableSubmit}
                                selectedDiscountType={selectedDiscountType}
                                handleDiscountTypeChange={handleDiscountTypeChange}
                                discountValue={discountValue}
                                setDiscountValue={setDiscountValue}
                                errors={errors}
                                setErrors={setErrors}
                                setSelectedDiscountType={setSelectedDiscountType}
                                isModal={true}
                                setAmountAfterDiscount={setAmountAfterDiscount}
                            />
                            {
                                showSubmitForAddDiscount ?
                                    <div className={`submit_button_for_add_discount`}>
                                        <PrimaryButton
                                            className={"Calculate_discount_button"}
                                            onClick={handleAddDiscountSubmit}
                                            disabled={disableSubmit}
                                            displayLoader={disableSubmit}
                                            buttonText="submit" />
                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>
                    :
                    null
            }
        </div >
    )
}
