import React, { useState } from 'react';
import './PreLoginHeader.scss';
import Logo from '../../../Assets/Images/AlphacubesLogo.svg';
import PrimaryButton from '../PrimaryButton';
import { FaBars, FaAngleRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const PreLoginHeader = (props) => {

    const navigate = useNavigate();

    const [displayMobileMenu, setDisplayMobileMenu] = useState(false);

    const handleHamburgerClick = () => {
        setDisplayMobileMenu(true);
        document.body.classList.add('overflow-hide');
    }

    const handleMobileMenuCloseClick = () => {
        setDisplayMobileMenu(false);
        document.body.classList.remove('overflow-hide');
    }

    const [displayLoginPopup, setDisplayLoginPopup] = useState(false);

    return (
        <header className={`header-main-container`}>
            <div className={`header-logo-container`}>
                <img className={`header-logo`} src={Logo} />
            </div>
            <div className={`header-nav-container`}>
                <ul>
                    <li>
                        <a>Products</a>
                    </li>
                    <li>
                        <a>Pricing</a>
                    </li>
                    <li>
                        <a>Contact Us</a>
                    </li>
                    <li>
                        <a>About Us</a>
                    </li>
                </ul>
            </div>
            <div className={`header-buttons-container`}>
                <PrimaryButton
                    buttonText={"Login"}
                    onClick={() => {
                        if (localStorage.getItem("accessToken")) {
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth' // Smooth scrolling animation
                            });
                            navigate("/dashboard");
                        } else {
                            props.setDisplayLoginPopup(true)
                        }
                    }}
                />
                {
                    props.hideRegister ?
                        null :
                        <PrimaryButton
                            onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth' // Smooth scrolling animation
                                });
                                navigate("/registration");
                            }}
                            className={`header-register-button`}
                            buttonText={"Register"}
                        />
                }
            </div>
            <div onClick={handleHamburgerClick} className={`hambuger-icon-container`}>
                <FaBars size={"8vw"} />
            </div>
            <div className={`header-mobile-menu-container ${displayMobileMenu ? 'active' : ''}`}>
                <div className={`header-mobile-menu-content-container`}>
                    <div className={`mobile-header-menu-content`}>
                        <div className={`mobile-header-upper-options`}>
                            <div className={`mobile-header-close-container`}>
                                <p onClick={handleMobileMenuCloseClick}>X</p>
                            </div>
                            <div className={`mobile-header-nav-links-container`}>
                                <ul>
                                    <li>
                                        <a>Products</a>
                                        <FaAngleRight className={`mobile-header-nav-arrow`} />
                                    </li>
                                    <li>
                                        <a>Pricing</a>
                                        <FaAngleRight className={`mobile-header-nav-arrow`} />
                                    </li>
                                    <li>
                                        <a>Contact Us</a>
                                        <FaAngleRight className={`mobile-header-nav-arrow`} />
                                    </li>
                                    <li>
                                        <a>About Us</a>
                                        <FaAngleRight className={`mobile-header-nav-arrow`} />
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={`mobile-header-lower-options`}>
                            <div className={`mobile-header-buttons-container`}>
                                <PrimaryButton
                                    buttonText={"Login"}
                                    onClick={() => {
                                        if (localStorage.getItem("accessToken")) {
                                            window.scrollTo({
                                                top: 0,
                                                behavior: 'smooth' // Smooth scrolling animation
                                            });
                                            navigate("/dashboard");
                                        } else {
                                            props.setDisplayLoginPopup(true)
                                        }
                                    }}
                                />
                                {
                                    props.hideRegister ?
                                        null
                                        :
                                        <PrimaryButton
                                            onClick={() => {
                                                window.scrollTo({
                                                    top: 0,
                                                    behavior: 'smooth' // Smooth scrolling animation
                                                });
                                                navigate("/registration");
                                            }}
                                            className={`mobile-header-register-button`}
                                            buttonText={"Register"}
                                        />
                                }

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </header>
    )
}

export default PreLoginHeader;