import React, { useState, useEffect } from 'react';
import '../Dashboard/Dashboard.scss';
import { PieChart } from '@mui/x-charts/PieChart';
import '../../Utils//Styles/Colors.css';
import CommonDropDown from '../Common/CommonDropDown';
import PrimaryButton from '../Common/PrimaryButton';
import { FaSearch } from 'react-icons/fa';
import StudentsTable from '../Common/StudentsTable/StudentsTable';
import { useNavigate } from 'react-router-dom';
import { ApiCall } from '../../Utils/ApiComponets/ApiCall';
import { LuLoader2 } from "react-icons/lu";



const Dashboard = () => {
    const navigate = useNavigate();

    const [selectedInterval, setSelectedInterval] = useState(null);
    const [instituteselectedValue, setInstituteSelectedValue] = useState('');
    const [boardSelectedValue, setBoardSelectedValue] = useState('');
    const [mediumSelectedValue, setMediumSelectedValue] = useState('');
    const [standardSelectedValue, setStandardSelectedValue] = useState('');
    const [divisionSelectedValue, setDivisionSelectedValue] = useState('');
    const [academicYearSelectedValue, setAcademicYearSelectedValue] = useState('');
    const [sort, setSort] = useState('atoz');
    const [studentCount, setStudentCount] = useState(0);
    const [searchedData, setSearchedData] = useState('');
    const [instituteLoader, setInstituteLoader] = useState(false);
    const [boardLoader, setBoardLoader] = useState(false);
    const [mediumLoader, setMediumLoader] = useState(false);
    const [standardLoader, setStandardLoader] = useState(false);
    const [divisionLoader, setDivisionLoader] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [studentListLoader, setStudentListLoader] = useState(true);
    const [enableDivision, setEnableDivision] = useState(false);
    const [filters, setFilters] = useState('');
    const [recentlyAddedStudents, setRecentlyAddedStudents] = useState(sessionStorage.getItem("recentlyAddedStudents") ? JSON.parse(sessionStorage.getItem("recentlyAddedStudents")) : []);

    const Intervals = [
        { id: 1, name: 'weekly' },
        { id: 2, name: 'monthly' },
        { id: 3, name: 'yearly' }
    ];

    const handleIntervalChange = (intervalId) => {
        setSelectedInterval(intervalId);
    };

    const [InstitiuteOptions, setInstitiuteOptions] = useState([
        { value: '', label: 'Institute' },
    ]);

    const [BoardOptions, setBoardOptions] = useState([
        { value: '', label: 'Board' },
    ]);

    const [MediumOptions, setMediumOptions] = useState([
        { value: '', label: 'Medium' },
    ]);

    const [StandardOptions, setStandardOptions] = useState([
        { value: '', label: 'Standard' },
    ]);

    const [DivisionOptions, setDivisionOptions] = useState([
        { value: '', label: 'Division' },
    ]);

    const AcademicYearOptions = [
        { value: '', label: 'Academic Year' },
        { value: '2022-2023', label: '2022-2023' },
        { value: '2023-2024', label: '2023-2024' },
        { value: '2024-2025', label: '2024-2025' },
        { value: '2025-2026', label: '2025-2026' },
        { value: '2026-2027', label: '2026-2027' },
    ];

    const sortOptions = [
        { value: '', label: 'Sort' },
        { value: 'atoz', label: 'A to Z' },
        { value: 'ztoa', label: 'Z to A' }
    ]


    const handleInstituteChange = (event) => {
        setInstituteSelectedValue(event.target.value);
    };

    const handleBoardChange = (event) => {
        setBoardSelectedValue(event.target.value);
    };

    const handleMediumChange = (event) => {
        setMediumSelectedValue(event.target.value);
    };

    const handleStandardChange = (event) => {
        setStandardSelectedValue(event.target.value);
    };

    const handleDivisionChange = (event) => {
        setDivisionSelectedValue(event.target.value);
    };

    const handleAcademicYearChange = (event) => {
        setAcademicYearSelectedValue(event.target.value);
    };

    const handleSortChange = (event) => {
        setSort(event.target.value);
    }

    const handleSearchChange = (event) => {
        setStudentListLoader(true);
        setSearchedData(event.target.value);
        const searchTerm = event.target.value;
        if (window.dbTimeout) {
            clearTimeout(window.dbTimeout);
        }
        window.dbTimeout = setTimeout(() => {
            triggerGetStudentList(false, searchTerm);
        }, 1500);
        setCurrentPage(1);
        setPageSize(10);
    }

    const [studentsData, setStudentsData] = useState([
        // {
        //     rollNo: '101',
        //     name: 'John Doe',
        //     mobileNo: '1234567890',
        //     std: '10',
        //     div: 'A',
        //     admissionDate: '2022-01-01',
        //     documents: ''
        // },
        // {
        //     rollNo: '102',
        //     name: 'Jane Smith',
        //     mobileNo: '9876543210',
        //     std: '9',
        //     div: 'B',
        //     admissionDate: '2022-02-15',
        //     documents: ''
        // },
        // Add more student data as needed
    ]);

    useEffect(() => {
        triggerInstituteApi();
        triggerBoardApi();
        triggerMediumApi();
        triggerStandardApi();
        // triggerDivisionApi();
        triggerGetStudentList();
    }, []);

    const handleEnableDivision = async () => {
        try {
            if (
                instituteselectedValue != '' &&
                boardSelectedValue != '' &&
                mediumSelectedValue != '' &&
                standardSelectedValue != ''
            ) {
                setDivisionLoader(true);
                const userMediums = await triggerGetAllBoardMedium();
                const selectedMediumLabel = MediumOptions.find(item => item.value == mediumSelectedValue).label;
                const selectedMediumId = userMediums.find(item => item.mediumName == selectedMediumLabel)._id;
                const userStandards = await triggerGetAllMediumStandard(selectedMediumId);
                const selectedStandardLabel = StandardOptions.find(item => item.value == standardSelectedValue).label;
                const selectedStandardId = userStandards.find(item => item.standardName == selectedStandardLabel)._id;
                const userDivisions = await triggerGetAllDivisionStandards(selectedStandardId);
                const formattedOptions = userDivisions.map(division => ({
                    value: division.divisonId,
                    label: division.divisionName
                }));
                setDivisionOptions([
                    { value: '', label: 'Division' },
                    ...formattedOptions
                ]);

                setDivisionLoader(false);
                setEnableDivision(true);
            } else {
                setDivisionSelectedValue('');
                setDivisionOptions([
                    { value: '', label: 'Division' },
                ]);
                setDivisionLoader(false);
                setEnableDivision(false);
            }
        } catch (e) {
            console.log(e);
            setDivisionSelectedValue('');
            setDivisionOptions([
                { value: '', label: 'Division' },
            ]);
            setDivisionLoader(false);
            setEnableDivision(false);
        }
    }

    useEffect(() => {
        handleEnableDivision();
    }, [instituteselectedValue, boardSelectedValue, mediumSelectedValue, standardSelectedValue]);

    const triggerGetAllBoardMedium = () => {
        return new Promise(async (res, rej) => {
            try {
                const response = await ApiCall("medium/getallboardmediums?boardId=" + boardSelectedValue, null, 'get');
                res(response.responseJson.boardMediumsDetails);
            } catch (e) {
                console.log(e);
                rej(e);
            }
        });
    }

    const triggerGetAllMediumStandard = (mediumId) => {
        return new Promise(async (res, rej) => {
            try {
                const response = await ApiCall("standard/getallmediumstandards?mediumId=" + mediumId, null, 'get');
                res(response.responseJson.mediumStandardsDetails);
            } catch (e) {
                console.log(e);
                rej(e);
            }
        })
    }

    const triggerGetAllDivisionStandards = (standardId) => {
        return new Promise(async (res, rej) => {
            try {
                const response = await ApiCall("division/getalldivisionstandards?standardId=" + standardId, null, 'get');
                res(response.responseJson.divisonStandardsDetails);
            } catch (e) {
                console.log(e);
                rej(e);
            }
        })
    }


    const triggerInstituteApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setInstituteLoader(true);
                const response = await ApiCall("institute/getalluserinstitutes", null, 'get');
                // console.log(response);
                const formattedOptions = response.responseJson.userInstitutesDetails.map(institute => ({
                    value: institute._id,
                    label: institute.institudeName
                }));
                setInstitiuteOptions([
                    { value: '', label: 'Institute' },
                    ...formattedOptions
                ]);
                setInstituteLoader(false);
                res();
            } catch (error) {
                setInstituteLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    }

    const triggerBoardApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setBoardLoader(true);
                const response = await ApiCall("board/getalluserinstituteboards", null, 'get');
                // console.log(response);
                const formattedOptions = response.responseJson.instituteBoardsDetails.map(board => ({
                    value: board._id,
                    label: board.boardName
                }));
                setBoardOptions([
                    { value: '', label: 'Board' },
                    ...formattedOptions
                ]);
                setBoardLoader(false);
                res();
            } catch (error) {
                setBoardLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    }

    const triggerMediumApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setMediumLoader(true);
                const response = await ApiCall("medium/getallmastermediums", null, 'get');
                // console.log(response);
                const formattedOptions = response.responseJson.mediums.map(medium => ({
                    value: medium._id,
                    label: medium.mediumName
                }));
                setMediumOptions([
                    { value: '', label: 'Medium' },
                    ...formattedOptions
                ]);
                setMediumLoader(false);
                res();
            } catch (error) {
                setMediumLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    }


    const triggerStandardApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setStandardLoader(true);
                const response = await ApiCall("standard/getallmasterstandards", null, 'get');
                // console.log(response);
                const formattedOptions = response.responseJson.standards.map(standard => ({
                    value: standard._id,
                    label: standard.standardName
                }));
                setStandardOptions([
                    { value: '', label: 'Standard' },
                    ...formattedOptions
                ]);
                setStandardLoader(false);
                res();
            } catch (error) {
                setStandardLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    }

    const triggerDivisionApi = () => {
        return new Promise(async (res, rej) => {
            try {
                setDivisionLoader(true);
                const response = await ApiCall("division/getalluserstandarddivisions", null, 'get');
                // console.log(response);
                const formattedOptions = response.responseJson.division.map(division => ({
                    value: division._id,
                    label: division.divisionName
                }));
                setDivisionOptions([
                    { value: '', label: 'Division' },
                    ...formattedOptions
                ]);
                setDivisionLoader(false);
                res();
            } catch (error) {
                setDivisionLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    }

    const triggerGetStudentList = (defaultCall, searchData, startNumber, recordsCount) => {
        return new Promise(async (res, rej) => {
            try {
                // setDivisionLoader(true);
                const instituteId = defaultCall || instituteselectedValue == '' ? 'all' : instituteselectedValue;
                const boardId = defaultCall || boardSelectedValue == '' ? 'all' : boardSelectedValue;
                const mediumId = defaultCall || mediumSelectedValue == '' ? 'all' : mediumSelectedValue;
                const standardId = defaultCall || standardSelectedValue == '' ? 'all' : standardSelectedValue;
                const divisionId = defaultCall || divisionSelectedValue == '' ? 'all' : divisionSelectedValue;
                const sorted = defaultCall || sort == 'atoz' ? 'atoz' : sort;
                const search = searchData ? searchData : '';
                const startNum = startNumber ? startNumber : '1';
                const count = recordsCount ? recordsCount : '10';
                setStudentListLoader(true);
                setFilters("student/getallstudents?instituteId=" + instituteId + "&boardId=" + boardId + "&mediumId=" + mediumId + "&standardId=" + standardId + "&divisionId=" + divisionId + "&search=" + search + "&sort=" + sorted);
                const response = await ApiCall("student/getallstudents?instituteId=" + instituteId + "&boardId=" + boardId + "&mediumId=" + mediumId + "&standardId=" + standardId + "&divisionId=" + divisionId + "&search=" + search + "&sort=" + sorted + "&startNumber=" + startNum + "&recordsCount=" + count, null, 'get');
                setStudentCount(response.responseJson.studentsDetails.studentsCount);
                const formattedOptions = response.responseJson.studentsDetails.studentsDataResponse.map(student => ({
                    id: student.id,
                    rollNo: student.rollNo,
                    name: student.name,
                    mobileNo: student.mobileNumber,
                    std: student.standardName,
                    div: student.divisionName,
                    admissionDate: student.admissionDate,
                    documents: ''
                }));
                setStudentsData([
                    ...formattedOptions
                ]);
                // setDivisionLoader(false);
                setStudentListLoader(false);
                res();
            } catch (error) {
                console.log(error, "error");
                // setDivisionLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    }

    const handleApplyClick = () => {
        setCurrentPage(1);
        setPageSize(10);
        triggerGetStudentList();
    }


    return (
        <div className="Dashboard_Section_conatainer">
            <div className="Dashboard_main_container">
                {
                    recentlyAddedStudents.length != 0 ?
                        <div className={`recently-added-students-container`}>
                            <p>Recently Added Students (Max. Last 5):</p>
                            <StudentsTable handleRecentlyAdded={true} studentsData={recentlyAddedStudents} />
                        </div>
                        : null
                }
                {/* Pie Chart Section Starts */}
                {/* <div className="Pie_Chart_Parent_Container">
                    <div className="pie_Chart_Left_Container">
                        <div className="Total_Students_container">
                            <h3 className='Total_Students_title'>Total no. of Student</h3>
                            <h3 className='Total_students_number'>650.00</h3>
                        </div>
                        <div className="Pass_Out_Students_container">
                            <h3 className='Pass_Out_Students_title'>Pass out Student</h3>
                            <h3 className='Pass_Out_students_number'>110.00</h3>
                        </div>
                    </div>
                    <div className="pie_Chart_Right_Container">
                        <div className="Fees_Collection_Container">
                            <h5 className='Fees_collection_title'>Fees Collection</h5>
                            <div className="Intervals_Container">
                                {Intervals.map((item) => (
                                    <div key={item.id} className="radio_button_container">
                                        <input
                                            type="radio"
                                            id={`interval_${item.id}`}
                                            name="interval"
                                            value={item.id}
                                            checked={selectedInterval === item.id}
                                            onChange={() => handleIntervalChange(item.id)}
                                            style={{ transform: 'scale(1.5)', marginRight: "0.5vw" }}
                                        />
                                        <label htmlFor={`interval_${item.id}`}>{item.name}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="Pie_Chart_Contianer">
                            <PieChart
                                colors={['var(--pending-pie_chart-color)', 'var(--over-due-pie_chart-color)', 'var(--paid_pie_chart-color)',]}
                                series={[
                                    {
                                        data: [
                                            { id: 0, value: 10, label: 'Over Due' },
                                            { id: 1, value: 10, label: 'Pending' },
                                            { id: 2, value: 55, label: 'Paid', },
                                        ],
                                    },
                                ]}
                                width={400}
                                height={200}
                            />
                        </div>
                    </div>
                </div> */}
                {/* Pie Chart Section ends */}

                {/* filter's section Starts */}
                <div className="add_Student_Mobile_Contianer">
                            <PrimaryButton
                                buttonText={"Add Student"}
                                // onClick={VerifyemailOTpClick}
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth' // Smooth scrolling animation
                                    });
                                    navigate("/admission")
                                }}
                                className={"Add_Student_button_style"}
                            />
                        </div>
                <div className="filter_section_parent_container">
                    <div className="dashboard_institue_container">
                        <CommonDropDown
                            selectedValue={instituteselectedValue}
                            handleSelectChange={handleInstituteChange}
                            options={InstitiuteOptions}
                            displayLoader={instituteLoader}
                            extraStyles={{ width: "18vw" }}
                        />
                    </div>
                    <div className="board_container">
                        <CommonDropDown
                            selectedValue={boardSelectedValue}
                            handleSelectChange={handleBoardChange}
                            options={BoardOptions}
                            displayLoader={boardLoader}
                            extraStyles={{ width: "12vw" }}

                        />
                    </div>
                    <div className="dashboard_medium_container">
                        <CommonDropDown
                            selectedValue={mediumSelectedValue}
                            handleSelectChange={handleMediumChange}
                            options={MediumOptions}
                            displayLoader={mediumLoader}
                            extraStyles={{ width: "9vw" }}

                        />
                    </div>
                    <div className="dashboard_standard_container">
                        <CommonDropDown
                            selectedValue={standardSelectedValue}
                            handleSelectChange={handleStandardChange}
                            options={StandardOptions}
                            displayLoader={standardLoader}
                            extraStyles={{ width: "9vw" }}

                        />
                    </div>
                    <div className="dashboard_division_container">
                        <CommonDropDown
                            selectedValue={divisionSelectedValue}
                            handleSelectChange={handleDivisionChange}
                            options={DivisionOptions}
                            disabled={!enableDivision}
                            displayLoader={divisionLoader}
                            extraStyles={{ width: "9vw" }}

                        />
                    </div>
                    <div className="academic_year_container">
                        <CommonDropDown
                            selectedValue={sort}
                            handleSelectChange={handleSortChange}
                            options={sortOptions}
                            extraStyles={{ width: "12vw" }}
                        />
                    </div>
                    <div className='dashboard_button_container'>
                    <div className="reset_button_container">
                        <PrimaryButton
                            buttonText={"Reset"}
                            // onClick={VerifyemailOTpClick}
                            onClick={() => {
                                setInstituteSelectedValue('');
                                setBoardSelectedValue('');
                                setMediumSelectedValue('');
                                setStandardSelectedValue('');
                                setDivisionSelectedValue('');
                                setSearchedData('');
                                setSort('atoz');
                                setCurrentPage(1);
                                setPageSize(10);
                                triggerGetStudentList(true);
                            }}
                            className={"Reset_button_style"}
                        />
                    </div>

                    <div className="apply_button_container">
                        <PrimaryButton
                            buttonText={"Apply"}
                            onClick={handleApplyClick}
                            // onClick={VerifyemailOTpClick}
                            className={"Reset_button_style"}
                        />
                    </div>
                    </div>
                </div>
                {/* filter's section ends */}

                {/* Search Bar Section Starts */}
                <div className="Search_section_parent_container">
                    <div className="Search_main_container">
                        <div className="search_bar_container">
                            <div className="search_icon">
                                <FaSearch fontSize={"1.3vw"} />
                            </div>
                            <input type="text"
                                placeholder="Search by name"
                                className="search_input"
                                value={searchedData}
                                onChange={handleSearchChange}
                            />
                        </div>
                        <div className="add_Student_Contianer">
                            <PrimaryButton
                                buttonText={"Add Student"}
                                // onClick={VerifyemailOTpClick}
                                onClick={() => {
                                    window.scrollTo({
                                        top: 0,
                                        behavior: 'smooth' // Smooth scrolling animation
                                    });
                                    navigate("/admission")
                                }}
                                className={"Add_Student_button_style"}
                            />
                        </div>
                    </div>
                </div>
                {/* Search Bar Section ends */}
                {/* {console.log(studentsData, "studentsData")} */}
                {/* Student table Section Starts */}
                {
                    studentListLoader ?
                        <div className={`dashboard-student-list-loader`}>
                            <div>
                                <LuLoader2 className={`spinner`} />
                                <p>Fetching student records. Please wait...</p>
                            </div>
                        </div>
                        :
                        <StudentsTable studentsData={studentsData} currentPage={currentPage} setCurrentPage={setCurrentPage} pageSize={pageSize} setPageSize={setPageSize} triggerGetStudentList={triggerGetStudentList} searchedData={searchedData} studentCount={studentCount} filters={filters} showStudentExport={true} />
                }
                {/* Student table Section ends */}
            </div>
        </div>
    );
};

export default Dashboard;
