import React from 'react';
import './Services.scss';
import OurServicesImage from '../../../Assets/Images/landing-our-services.svg';
import { FaCheckCircle } from "react-icons/fa";

const Services = () => {

    return (
        <div className={`our-services-main-container`}>
            <div className={`our-services-heading-container`}>
                <p>
                    Our Services
                </p>
            </div>
            <div className={`our-services-content-container`}>
                <div className={`our-services-left-container`}>
                    <img className={`our-services-image`} src={OurServicesImage} />
                </div>
                <div className={`our-services-right-container`}>
                    <p className={`our-services-heading-text`}>Heading</p>
                    <ul>
                        <li>
                            <FaCheckCircle className={`our-services-check-icon`} />
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipiscing elit. Donec
                            </p>
                        </li>
                        <li>
                            <FaCheckCircle className={`our-services-check-icon`} />
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipiscing elit. Donec
                            </p>
                        </li>
                        <li>
                            <FaCheckCircle className={`our-services-check-icon`} />
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipiscing elit. Donec
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Services;