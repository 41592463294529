import React from 'react';
import './PrimaryButton.scss';
import { LuLoader2 } from "react-icons/lu";

const PrimaryButton = (props) => {

    return (
        <div onClick={props.disabled ? () => { console.log("disabled") } : props.onClick} className={`primary-button ${props.className ? props.className : ''} ${props.disabled ? 'disabled' : ''}`}>
            <p>
                {props.buttonText}
            </p>
            {
                props.displayLoader && props.disabled ?
                    <LuLoader2 className={`spinner`} />
                    : null
            }
        </div>
    )
}

export default PrimaryButton;