const AES = require("crypto-js").AES;
const enc = require("crypto-js").enc;
const PBKDF2 = require("crypto-js").PBKDF2;
// const base64 = require("./Base64");

let cryptoObj = {};
cryptoObj.keySize = 8; // 256 / 32; so it is 256 bit key
cryptoObj.iterationCount = 1000;
cryptoObj.passphrase = "";
cryptoObj.salt = 'vfeuee56hu3jBgQTpz8kBRuqunodOdfw';
cryptoObj.iv = 'pgmxhpi3D1oNdw9CtFNb4s8SO42EdEtZ';
cryptoObj.encryptedKey = 'eXhBZWFqaGJHVEdqTFlnRg==';
cryptoObj.encrypted = "";
cryptoObj.decrypted = "";
cryptoObj.passphrase = atob(cryptoObj.encryptedKey);
cryptoObj.key = PBKDF2(cryptoObj.passphrase, enc.Hex.parse(cryptoObj.salt), { keySize: cryptoObj.keySize, iterations: cryptoObj.iterationCount });

const encrypt = (plainText) => {
    plainText = plainText.toString();
    cryptoObj.encrypted = AES.encrypt(plainText, cryptoObj.key, { iv: enc.Hex.parse(cryptoObj.iv) });
    cryptoObj.encrypted = cryptoObj.encrypted.ciphertext.toString(enc.Base64);
    return cryptoObj.encrypted.toString();
}

const decrypt = (encryptedText) => {
    encryptedText = encryptedText.toString(enc.Base64);
    cryptoObj.decrypted = AES.decrypt(encryptedText, cryptoObj.key, { iv: enc.Hex.parse(cryptoObj.iv) });
    return cryptoObj.decrypted.toString(enc.Utf8);
}

export {encrypt, decrypt};