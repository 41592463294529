import React, { useState } from 'react';
import '../StudentsTable/StudentsTable.scss';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../PrimaryButton';
import { ApiCall } from '../../../Utils/ApiComponets/ApiCall';
import { FaInfoCircle, FaFilePdf, FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const StudentsTable = ({ studentsData, triggerGetStudentList, searchedData, studentCount, currentPage, setCurrentPage, pageSize, setPageSize, handleRecentlyAdded, filters, showStudentExport }) => {
    const indexOfLastStudent = currentPage * pageSize;
    const indexOfFirstStudent = indexOfLastStudent - pageSize;
    const currentStudents = studentsData;
    const numberOfPages = Math.ceil(studentCount / pageSize);

    const [exporting, setExporting] = useState(false);

    const navigate = useNavigate();


    const handleViewDocuments = (documents) => {
        // Implement logic to view documents
        console.log('Viewing documents:', documents);
    };

    const handleViewClick = (item) => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scrolling animation
        });
        // console.log(item)
        sessionStorage.setItem("selectedStudent", item.id);
        navigate("/AdmissionTabsSection");
    }

    const goToPage = (page) => {
        if (page >= 1 && page <= numberOfPages) {
            setCurrentPage(page);
            if (page == 1) {
                triggerGetStudentList(false, searchedData, '1', pageSize);
            } else {
                triggerGetStudentList(false, searchedData, ((pageSize * (page - 1)) + 1).toString(), pageSize);
            }
        }
    };

    const handleInputChange = (e) => {
        const page = parseInt(e.target.value);
        if (!isNaN(page) && page >= 1 && page <= numberOfPages) {
            setCurrentPage(page);
        }
    };

    const handlePageSizeChange = (e) => {
        let size = parseInt(e.target.value);
        if (!isNaN(size) && size >= 1) {
            if (size > 25) {
                size = 25;
                setPageSize(25);
            } else {
                setPageSize(size);
            }
            setCurrentPage(1); // Reset to the first page when page size changes
            if (window.dbPageSize) {
                clearTimeout(window.dbPageSize);
            }
            window.dbPageSize = setTimeout(() => {
                triggerGetStudentList(false, searchedData, '1', size);
            }, 1500);
        }
    };

    const handleJumpToPageChange = (e) => {
        let page = parseInt(e.target.value);
        if (!isNaN(page)) {
            if (page > numberOfPages) {
                page = numberOfPages;
            }
            setCurrentPage(page);
            if (window.dbJumpPage) {
                clearTimeout(window.dbJumpPage);
            }
            window.dbJumpPage = setTimeout(() => {
                triggerGetStudentList(false, searchedData, (page * pageSize) - pageSize + 1, pageSize);
            }, 1500);
        }
    }

    const generatePage = (i) => {
        return <div key={i} onClick={() => goToPage(i + 1)} className={currentPage === i + 1 ? 'current_page_active' : 'numbers_contianer'}>
            {i + 1}
        </div>
    }

    const renderPages = () => {
        const arr = [];
        if (numberOfPages <= 4) {
            for (let i = 0; i < numberOfPages; i++) {
                arr.push(generatePage(i));
            }
        } else {
            for (let i = currentPage - 2; i < currentPage + 1 && currentPage > 0 && currentPage < numberOfPages; i++) {
                arr[i] = generatePage(i);
            }
            for (let i = 0; i < numberOfPages; i++) {
                if ((i == 0 || i == 1 || i == numberOfPages - 2 || i == numberOfPages - 1) && !arr[i]) {
                    arr[i] = generatePage(i);
                } else if (i == 2 && !arr[i]) {
                    arr[i] = <p>...</p>;
                } else if (i == numberOfPages - 3 && !arr[i]) {
                    arr[i] = <p>...</p>;
                }
            }
        }
        return arr;
    }

    const [isMobile, setIsmobile] = useState(window.innerWidth <= 480)


    const handleExportClick = async () => {
        setExporting(true);
        let i = 1, j = 1, incrementBy = 10;//j is startnum and i is endnum
        let studentsData = [];
        while (studentCount && true) {
            i += incrementBy;
            if (i > studentCount) {
                i = studentCount;
            }
            const recordCount = i == studentCount ? incrementBy + 1 : incrementBy;
            const response = await ApiCall(filters + "&startNumber=" + j + "&recordsCount=" + recordCount, null, 'get');
            const formattedOptions = response.responseJson.studentsDetails.studentsDataResponse.map(student => ({
                rollNo: student.rollNo,
                name: student.name,
                mobileNo: student.mobileNumber,
                std: student.standardName,
                div: student.divisionName,
                admissionDate: student.admissionDate,
            }));
            studentsData = [...studentsData, ...formattedOptions];
            j += incrementBy;
            if (i == studentCount) {
                break;
            }
        }

        const csvHeader = "Sr. No.,Roll No,Name,Mobile No.,Std,Div,Admission Date\n";
        let csvRows = "";
        for (let index = 0; index < studentsData.length; index++) {
            const data = studentsData[index];
            csvRows += `"${index + 1}","${data.rollNo}","${data.name}","${data.mobileNo}","${data.std}","${data.div}","${data.admissionDate}"\n`
        }
        const csvData = csvHeader + csvRows;
        const blob = new Blob([csvData], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const newTab = window.open(url);
        if (newTab) {
            newTab.document.title = "students.csv";  // Set the title of the tab
            newTab.focus();  // Optionally focus the new tab
            setTimeout(() => {
                newTab.close();  // Close the tab immediately after opening it
                setExporting(false);
            }, 100); // Set a small timeout to ensure the download starts
        }
    }

    return (
        <div className="table_container">
            {
                studentsData.length == 0 ?
                    <div className="showing_index">
                        <p>No records found. Kindly reset your filters and try again.</p>
                    </div> :
                    <>
                        <table className="student_table">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Roll No.</th>
                                    <th>Name</th>
                                    <th>Mobile No.</th>
                                    <th>Std</th>
                                    <th>Div</th>
                                    <th>Admission Date</th>
                                    {!isMobile ?
                                        <th colSpan={2}>Actions</th>
                                        :
                                        <th>Actions</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {currentStudents.map((item, index) => (
                                    <tr key={index}>
                                        <td>{handleRecentlyAdded ? index + 1 : indexOfFirstStudent + index + 1}</td>
                                        <td>{item.rollNo}</td>
                                        <td>{item.name}</td>
                                        <td>{item.mobileNo}</td>
                                        <td>{item.std}</td>
                                        <td>{item.div}</td>
                                        <td>{item.admissionDate}</td>
                                        <td>
                                            <a className={`link-text`} onClick={() => handleViewClick(item)}>
                                                View
                                            </a>
                                        </td>
                                        {!isMobile ?
                                            <td>
                                                <a className={`link-text`} href={'/admissionform?token=' + sessionStorage.getItem("accessToken") + '&studentId=' + item.id} target='_blank'>
                                                    Download
                                                </a>
                                            </td>
                                            : null
                                        }
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {
                            handleRecentlyAdded ?
                                null :
                                <div className="pagination">
                                    <div className="pagination_container">
                                        <p>Showing {indexOfFirstStudent + 1} to {Math.min(indexOfFirstStudent + pageSize, studentCount)} of {studentCount} results</p>
                                    </div>
                                    <div className="pagination_controls">
                                        <div className='display_container'>
                                            <p>Jump To Page</p>
                                            <input
                                                type="number"
                                                value={currentPage}
                                                onChange={handleJumpToPageChange}
                                                min="1"
                                                max={numberOfPages}
                                                placeholder="Page Size"
                                                className='extra_space'
                                            />
                                        </div>
                                        <div className='display_container'>
                                            <p>Display</p>
                                            <input
                                                type="number"
                                                value={pageSize}
                                                onChange={handlePageSizeChange}
                                                min="1"
                                                max={"25"}
                                                placeholder="Page Size"
                                                className='extra_space'
                                            />
                                        </div>
                                        <div className="arrows_container">

                                            <div onClick={() => goToPage(currentPage - 1)} className={currentPage > 1 ? 'left_arrow_active extra_space' : 'left_arrow_btn_container extra_space'}>
                                                <p>{"<"}</p>
                                            </div>
                                            {/* <button onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>{'<'}</button> */}
                                            <div className='number_pages_parent'>
                                                {renderPages()}
                                            </div>
                                            <div className={currentPage != numberOfPages ? 'right_arrow_active' : 'right_arrow_btn_container'} onClick={() => goToPage(currentPage + 1)} >
                                                <p>{">"}</p>
                                            </div>
                                            {/* <button onClick={() => goToPage(currentPage + 1)} disabled={currentPage === numberOfPages}>{'>'}</button> */}
                                        </div>

                                    </div>
                                </div>
                        }
                        {
                            showStudentExport ?
                                <div className={`export-students-button`}>
                                    <PrimaryButton
                                        buttonText={exporting ? "Exporting " : "Export"}
                                        onClick={handleExportClick}
                                        displayLoader={exporting}
                                        disabled={exporting}
                                    />
                                </div>
                                : null
                        }
                    </>
            }
        </div>
    );
};

export default StudentsTable;
