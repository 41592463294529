import React, { useState, useEffect, } from 'react';
import '../StudentFeesInfo/StudentFeesInfo.scss';
import CommonTextInput from '../Common/CommonTextInput/CommonTextInput';
import CommonDropDown from '../Common/CommonDropDown';
import { FaCalendar } from "react-icons/fa";
import Calendar from 'react-calendar';
import PrimaryButton from '../Common/PrimaryButton';
import { ApiCall } from '../../Utils/ApiComponets/ApiCall';
import moment from 'moment/moment';


import { useNavigate } from 'react-router-dom';
import validation from '../../Utils/Validations/Validations';



const StudentFeesInfo = ({ onClose, firstName, middleName, lastName, institute, board, medium, rollNo, standard, division, admissionDate, standardFees, studentId, newlyAddedStudent, setOpenFeeModal, formatedAdmisionDateForCalendar }) => {

    const navigate = useNavigate();
    const [data, setData] = useState([]);

    const [mainButtonLoader, setMainButtonLoader] = useState(false);
    const [amount, setAmount] = useState('');
    const [errors, setErrors] = useState({});
    const [sdInstallmentLoader, setSdInstallmentLoader] = useState(false);
    const [sdModeOfpaymentLoader, setsdModeOfpaymentLoader] = useState(false);

    const [discountedFees, setDiscountedFees] = useState('');


    const [installmentSelectOptions, setinstallmentSelectOptions] = useState([
        { value: '', label: 'Installment*', status: 'paid' },
    ]);

    const [InstallmentSelectedValue, setInstallmentSelectedValue] = useState('');

    const [modeOfPaymentOptions, setmodeOfPaymentOptions] = useState([
        { value: '', label: 'Mode Of Payment*' },
    ]);
    const [modeOfPaymentSelectedValue, setmodeOfPaymentSelectedValue] = useState('');

    useEffect(() => {
        try {
            triggerGetModeOfPayment();
            triggerGetStudentFeesInstallmentsDetails();
        } catch (e) {
            console.log(e);
        }
    }, []);

    const triggerGetStudentFeesInstallmentsDetails = () => {
        return new Promise(async (res, rej) => {
            try {
                const response = await ApiCall('fee/getstudentfeesinstallments?userId=' + studentId, null, 'get');
                const discountedFees = response.responseJson.studentFeesInstallmentDetails.reduce((acc, current) => acc + current.amount, 0);
                setDiscountedFees(discountedFees);

                const installmentData = response.responseJson.studentFeesInstallmentDetails.map((item, index) => {
                    return {
                        _id: item._id,
                        srno: index + 1,
                        installment: item.installmentName,
                        paidDate: item.paidDate,
                        amount: item.amount,
                        paidAmt: item.amountPaid,
                        balanceAmt: item.balanceAmount,
                        lateFees: item.lateFee,
                        dueDate: item.dueDate,
                        status: item.status,
                        receipt: 'Receipt 12345'
                    }
                });
                setData(installmentData);

                const allInstallments = response.responseJson.studentFeesInstallmentDetails.map((item, index) => {
                    return {
                        value: item._id || index,
                        label: item.installmentName,
                        status: item.status
                    }
                });
                setinstallmentSelectOptions([...installmentSelectOptions, ...allInstallments]);
                res();
            } catch (e) {
                rej(e);
            }
        })
    }

    useEffect(() => {
        let SelectedInstallment = installmentSelectOptions[0];
        for (let i = 0; i < installmentSelectOptions.length; i++) {
            console.log(installmentSelectOptions[i])
            if (i == installmentSelectOptions.length - 1) {
                SelectedInstallment = installmentSelectOptions[i];
                break;
            } else if (installmentSelectOptions[i].status != 'paid') {
                SelectedInstallment = installmentSelectOptions[i];
                break;
            }
        }
        console.log(SelectedInstallment, installmentSelectOptions);
        setInstallmentSelectedValue(SelectedInstallment.label);
    }, [installmentSelectOptions]);


    const handleInstallmentChange = (event) => {
        // setInstallmentSelectedValue(event.target.value);
    };

    const handleModeOfPaymentChnage = (event) => {
        setmodeOfPaymentSelectedValue(event.target.value);
    };


    const [paymentDate, setpaymentDate] = useState(moment().format("DD/MM/YYYY"));

    const [showCalendar, setShowCalendar] = useState(false);
    const [ReferenceId, setReferenceId] = useState("");

    const [disableSubmit, setDisableSubmit] = useState(false);
    const [displaySubmitLoader, setDisplaySubmitLoader] = useState(false);

    const triggerGetModeOfPayment = () => {
        return new Promise(async (res, rej) => {
            try {
                const response = await ApiCall('fee/getmodeofpayment', null, 'get');
                let modeOfPayments = [
                    { value: '', label: 'Mode Of Payment*' },
                ];
                for (let i = 0; i < response.responseJson.paymentModesDetails.length; i++) {
                    modeOfPayments.push({
                        value: response.responseJson.paymentModesDetails[i]._id,
                        label: response.responseJson.paymentModesDetails[i].name,
                    });
                }
                setmodeOfPaymentOptions(modeOfPayments);
                setmodeOfPaymentSelectedValue('');
                res();
            } catch (e) {
                console.log(e);
                rej(e);
            }
        })
    }


    const handleCreateTransaction = () => {
        try {
            // setIsModalOpen(true);
            if (disableSubmit) {
                return;
            }


            let error = {};
            let isValid = true;

            error.amount = validation.isValidAmount(amount);
            error.ReferenceId = validation.isValidReferenceId(ReferenceId);
            error.paymentDate = validation.isValidPaymentDate(paymentDate);
            error.modeOfPayment = validation.isValidModeOfPayment(modeOfPaymentSelectedValue);

            for (const prop in error) {
                if (error[prop]) {
                    isValid = false;
                    break;
                }
            }

            if (isValid) {
                triggerCreateTransaction();
            } else {
                setErrors(error);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const triggerCreateTransaction = () => {
        return new Promise(async (res, rej) => {
            try {
                const paymentMode = modeOfPaymentOptions.find(item => item.value == modeOfPaymentSelectedValue);
                const body = {
                    "transactionData": {
                        "userId": studentId,
                        "paymentDate": paymentDate,
                        "amount": amount,
                        "modeOfPayment": paymentMode.label,
                        "referenceId": ReferenceId
                    }
                }
                setDisplaySubmitLoader(true);
                setDisableSubmit(true);
                const response = await ApiCall('fee/createstudentfeetransaction', body);
                setDisplaySubmitLoader(false);
                setDisableSubmit(false);
                if (newlyAddedStudent) {
                    navigate("/dashboard");
                } else {
                    setOpenFeeModal(false);
                }
                res();
            } catch (e) {
                rej(e);
            }
        })
    }

    const [formatedFeesForCalendar, setformatedFeesForCalendar] = useState(paymentDate)

    function calendarformatDateforFees(date) {

        const newdate = new Date(date);
        // Check if the date is valid
        if (isNaN(date)) {
            console.error("Invalid date---------------------------");
            return null;
        }

        const day = newdate.getDate().toString().padStart(2, '0');
        const month = (newdate.getMonth() + 1).toString().padStart(2, '0');
        const year = newdate.getFullYear();
        let datevalue = `${month}/${day}/${year}`
        setformatedFeesForCalendar(datevalue);

        return `${month}/${day}/${year}`;
    }



    const toggleCalendar = () => {
        if (paymentDate) {
            calendarformatDateforFees(paymentDate);
        }
        setShowCalendar(!showCalendar);
    };


    function formatDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }


    const handleCalendarChange = (date) => {
        setpaymentDate(date instanceof Date ? formatDate(date) : date.target.value);
        toggleCalendar();
    };


    const CalendarModal = ({ value, onChange, onClose }) => {
        let calendarValue;
        if (value == '') {
            calendarValue = new Date();
        }
        return (
            <div className="modal-overlay">
                <div className="modal-content">
                    <span className="close" onClick={onClose}>&times;</span>
                    <Calendar
                        onChange={onChange}
                        value={value}
                    />
                </div>
            </div>
        );
    }




    return (
        <div className="StudentFeesInfo_main_contianer">
            <div className="StudentFeesInfo_header_container">
                <h1>Student Details</h1>
                <button className="close-button" onClick={onClose}>
                    X
                </button>
            </div>
            <div className="StudentFeesInfo_Details_container">
                <div className="first_Row">
                    <div className="firstName">
                        <h1>First Name : {firstName}</h1>
                    </div>
                    <div className="firstName">
                        <h1>Middle Name : {middleName}</h1>
                    </div>
                    <div className="firstName">
                        <h1>Last Name : {lastName}</h1>
                    </div>
                </div>
                <div className="first_Row">
                    <div className="firstName">
                        <h1>Institute : {institute}</h1>
                    </div>
                    <div className="firstName">
                        <h1>Board : {board}</h1>
                    </div>
                    <div className="firstName">
                        <h1>Medium : {medium}</h1>
                    </div>
                </div>
                <div className="first_Row">
                    <div className="firstName">
                        <h1>Roll No : {rollNo}</h1>
                    </div>
                    <div className="firstName">
                        <h1>Standard : {standard}</h1>
                    </div>
                    <div className="firstName">
                        <h1>Division : {division}</h1>
                    </div>
                </div>
                <div className="first_Row">
                    <div className="firstName">
                        <h1>Admission Date : {admissionDate}</h1>
                    </div>
                </div>
            </div>
            <div className="fees_details_main_container">
                <div className="installment_header_container">
                    <h1>Installment Details</h1>
                </div>
                <div className="total_fees_discount_fees_parent_contianer">
                    <div className="total_fees_container">
                        <h1>Total Fees : {standardFees}</h1>
                    </div>
                    <div className="discounted_container">
                        <h1>Total Fees After Discount : {discountedFees}</h1>
                    </div>
                </div>
                <table className="fees_table">
                    <thead>
                        <tr>
                            <th>Sr. No.</th>
                            <th>Installment</th>
                            <th>Paid Date</th>
                            <th>Amount</th>
                            <th>Paid Amt</th>
                            <th>Balance Amt</th>
                            <th>Late Fees</th>
                            <th>Due Date</th>
                            <th>Status</th>
                            <th>Receipt</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((entry, index) => (
                            <tr key={index}>
                                <td>{entry.srno}</td>
                                <td>{entry.installment}</td>
                                <td>{entry.paidDate}</td>
                                <td>{entry.amount}</td>
                                <td>{entry.paidAmt}</td>
                                <td>{entry.balanceAmt}</td>
                                <td>{entry.lateFees}</td>
                                <td>{entry.dueDate}</td>
                                <td>{entry.status}</td>
                                <td>
                                    {/* <button onClick={
                                        () =>
                                            // handleDownloadClick(entry.receipt)
                                            console.log("")
                                    }>
                                        Download
                                    </button> */}
                                    {
                                        entry.status == 'unpaid' ?
                                            <p className='link-text disabled-text'>
                                                Download
                                            </p>
                                            :
                                            <a className={`link-text`} href={'/feesReceiptInstallment?token=' + sessionStorage.getItem("accessToken") + '&installmentId=' + entry._id + '&installment=' + entry.installment + '&amount=' + entry.amount} target='_blank'>
                                                Download
                                            </a>
                                    }
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div >


            <div className="pay_Installment_main_container">

                <div className="pay_Installment_title_container">
                    <h1>Pay Installment</h1>
                </div>

                <div className="pay_Installment_content_container">
                    <div className="pay_Installment_left_container">
                        <div>
                            <CommonTextInput
                                error={errors.sdStandard}
                                label={"Installment*"}
                                inputValue={InstallmentSelectedValue}
                                setInputValue={setInstallmentSelectedValue}
                                disabled={true}
                                extraStyles={{ width: "100%", margin: "0vw 0 0vw 0", padding: "0.63vw" }}
                            />
                        </div>
                        <div>
                            <CommonTextInput
                                label={"Amount*"}
                                disabled={mainButtonLoader}
                                inputValue={amount}
                                setInputValue={setAmount}
                                error={errors.amount}
                                maxlength={6}

                            />
                        </div>
                        <div>
                            <CommonTextInput
                                label={"Reference ID*"}
                                disabled={mainButtonLoader}
                                inputValue={ReferenceId}
                                setInputValue={setReferenceId}
                                error={errors.ReferenceId}
                                maxlength={20}

                            />
                        </div>

                    </div>
                    <div className="pay_Installment_right_container">
                        <div>
                            <CommonTextInput
                                label={"Payment Date* (dd/mm/yyyy)"}
                                disabled={mainButtonLoader}
                                rightIcon={<FaCalendar onClick={toggleCalendar} />}
                                rightIconStyle={{ marginRight: "2vw" }}
                                inputValue={paymentDate}
                                setInputValue={setpaymentDate}
                                error={errors.paymentDate}
                                maxLength={10}
                            />
                            {
                                (showCalendar && !mainButtonLoader) ?
                                    <CalendarModal
                                        value={formatedAdmisionDateForCalendar}
                                        onChange={(date) => {
                                            handleCalendarChange(date);
                                            toggleCalendar();
                                        }}
                                        onClose={toggleCalendar}
                                    />
                                    : null
                            }
                        </div>
                        <div className='mode_of_payment'>
                            <CommonDropDown
                                error={errors.modeOfPayment}
                                displayLoader={sdModeOfpaymentLoader}
                                selectedValue={modeOfPaymentSelectedValue}
                                handleSelectChange={handleModeOfPaymentChnage}
                                options={modeOfPaymentOptions}
                                disabled={mainButtonLoader}
                                extraStyles={{ width: "100%", margin: "0vw 0 0vw 0", padding: "0.63vw" }}
                            />
                        </div>
                    </div>
                </div>
                <div className="Primary_button">
                    <PrimaryButton
                        buttonText={"Submit"}
                        className={"Admission_form_common_submit_button"}
                        onClick={handleCreateTransaction}
                        displayLoader={displaySubmitLoader}
                        disabled={disableSubmit}
                    />
                </div>
            </div>

        </div>
    );
};

export default StudentFeesInfo;
