import React, { useState } from 'react';
import './AddDiscount.scss';
import CommonTextInput from '../Common/CommonTextInput/CommonTextInput';
import PrimaryButton from '../Common/PrimaryButton';
import { FaTimesCircle } from "react-icons/fa";

const AddDiscount = ({
    setShowDiscount,
    hideHeader,
    totalFees,
    viewOnly,
    mainButtonLoader,
    selectedDiscountType,
    setSelectedDiscountType,
    handleDiscountTypeChange,
    discountValue,
    setDiscountValue,
    errors,
    setErrors,
    isModal,
    setAmountAfterDiscount,
}) => {

    const discountTypes = ['Percentage', 'Absolute'];
    const [discountedAmount, setDiscountedAmount] = useState('');
    const [discountGiven, setDiscountGiven] = useState('');
    const [showDiscountedAmount, setShowDiscountedAmount] = useState(false);

    const setDiscountedValueInParent = (value) => {
        setAmountAfterDiscount && setAmountAfterDiscount(value);
    }

    const calculateDiscount = () => {
        let error = { ...errors };
        let isValid = true;
        error.discountValue = '';
        if (discountValue.trim() == '') {
            error.discountValue = 'Please enter discount value';
            isValid = false;
        } else if (!/^\d+$/.test(discountValue.trim())) {
            error.discountValue = 'Please enter valid discount value';
            isValid = false;
        }

        if (isValid) {
            let discountAmount, discountedAmount;
            if (selectedDiscountType == 'Percentage') {
                discountAmount = (Number(totalFees) * Number(discountValue)) / 100;
                discountedAmount = totalFees - discountAmount;
                setDiscountedAmount(discountedAmount);
                setDiscountGiven(discountAmount);
                setDiscountedValueInParent(discountAmount);
            } else {
                discountAmount = discountValue;
                discountedAmount = totalFees - discountAmount;
                setDiscountedAmount(discountedAmount);
                setDiscountedValueInParent(discountAmount);
                setDiscountGiven(discountAmount);
            }
            if (discountedAmount < 0) {
                error.discountValue = 'Calculated discount is less than 0';
                setShowDiscountedAmount(false);
                setDiscountedValueInParent('');
            } else {
                setShowDiscountedAmount(true);
            }
            setErrors(error);
        } else {
            setErrors(error);
            setShowDiscountedAmount(false);
            setDiscountedValueInParent('');
        }
    }

    return (
        <div className={`show-discount-main-container ${isModal ? 'is-modal' : ''}`}>
            {
                hideHeader ?
                    null
                    :
                    <div className="students_details_title_container">
                        <h1>Add Discount</h1>
                    </div>

            }
            <div className={`discount_cross_button ${isModal ? 'is-modal' : ''}`}>
                <div onClick={() => {
                    setShowDiscount(false);
                    setDiscountGiven('');
                    setDiscountedAmount('');
                    setDiscountedValueInParent('');
                    setDiscountValue('');
                    setShowDiscountedAmount(false);
                    setDiscountedValueInParent('');
                    setSelectedDiscountType('Percentage');
                    let error = { ...errors };
                    error.discountValue = '';
                    setErrors(error);
                }}>
                    <FaTimesCircle />
                </div>
            </div>
            <div className='total-fees-section'>
                <p>Total Fees: Rs.{totalFees}/-</p>
            </div>
            <div className="row_class">
                <div className="gender_contianer">
                    <div className="discount_type_text_container">
                        <h3>Discount Type<span style={{ color: "red" }}>*</span>:</h3>
                    </div>
                    <div className="discount_category_container">
                        {discountTypes.map((type, index) => (
                            <div className='radio_button_cateogry_wrapper' key={index}>
                                <input
                                    type="radio"
                                    id={`type-${type}`}
                                    name="discountType"
                                    value={type}
                                    disabled={viewOnly || mainButtonLoader}
                                    checked={selectedDiscountType === type}
                                    onChange={handleDiscountTypeChange}
                                    style={{ transform: 'scale(1.5)', marginRight: "0.5vw" }}
                                />
                                <label htmlFor={type}>{type}</label>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="section_contianer">
                    <div className={`rollno_container discount_container`}>
                        <CommonTextInput
                            label={"Discount Value"}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={discountValue}
                            setInputValue={setDiscountValue}
                            error={errors.discountValue}
                            maxlength={10}
                        />
                    </div>
                </div>
            </div>
            <div className="Admission_form_calculate_discount_button">
                <PrimaryButton
                    buttonText={"Calculate Discount"}
                    className={"Calculate_discount_button"}
                    onClick={calculateDiscount}
                    disabled={viewOnly || mainButtonLoader}
                />
            </div>
            {
                showDiscountedAmount ?
                    <div>
                        <div className='total-fees-section'>
                            <p>Discount Amount: Rs.{discountGiven}/-</p>
                        </div>
                        <div className='total-fees-section'>
                            <p>Total Fees After Discount: Rs.{discountedAmount}/-</p>
                        </div>
                    </div>
                    :
                    null
            }
        </div>
    )
}

export default AddDiscount;