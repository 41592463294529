import React, { useState } from 'react';
import './ConsentCheckbox.scss';
import { FaCheck, FaInfoCircle } from "react-icons/fa";

const ConsentCheckbox = ({ isConsentChecked, setIsConsentChecked, consentCheckError, disabled, isRegistrationPage }) => {

    const [displayConsentError, SetDisplayConsentError] = useState(false);

    return (
        <div className={`consent-checkbox-main-container`}>
            <div onClick={() => {
                if(disabled) {
                    return;
                }
                setIsConsentChecked(val => !val)
            }} className={`consent-checkbox ${disabled ? 'disabled' : ''}`}>
                {
                    isConsentChecked ?
                        <FaCheck />
                        : null
                }
            </div>
            <div className={`consent-text`}>
                <p>
                    By checking this box, you agree to our <span className={`link-text`}>terms and conditions</span> and <span className={`link-text`}>privacy policy</span>
                    {
                        consentCheckError ?
                            <FaInfoCircle onMouseEnter={() => SetDisplayConsentError(true)} onMouseLeave={() => SetDisplayConsentError(false)} />
                            : null
                    }
                    {
                        consentCheckError && displayConsentError ?
                            <div
                                className={`consent-error-text-container ${isRegistrationPage ? 'registration-page' : ''}`}>
                                <p>
                                    {consentCheckError}
                                </p>
                            </div>
                            : null
                    }
                </p>
            </div>
        </div>
    )
}

export default ConsentCheckbox;