import React, { useState, useEffect } from 'react';
import './LandingPage.scss';
import Header from '../../../Components/Common/Header';
import MainBanner from '../../../Components/Landing/MainBanner';
import OurServices from '../../../Components/Landing/OurServices';
import Footer from '../../../Components/Common/Footer';
import LoginPopup from '../../../Components/Login/LoginPopup';
import PreLoginHeader from '../../../Components/Common/PreLoginHeader';
import HeroSection from '../../../Components/Landing/HeroSection';
import Services from '../../../Components/Landing/Services';
import CommonFooter from '../../../Components/Common/CommonFooter';
import LoginModal from '../../../Components/Login/LoginModal/LoginModal';
import NewRegistrationForm from '../../../Components/Registration/NewRegistrationForm/NewRegistrationForm';

const LandingPage = () => {

    const [displayLoginPopup, setDisplayLoginPopup] = useState(false);
    const [scrollY, setScrollY] = useState(0);

    useEffect(() => {

        document.title = 'alphacubes';

        if (displayLoginPopup) {
            document.getElementsByTagName('body')[0].classList.add("overflow-hide");
        } else {
            document.getElementsByTagName("body")[0].classList.remove('overflow-hide');
        }


    }, [displayLoginPopup]);

    const handleScroll = () => {
        setScrollY(window.scrollY);
    };

    useEffect(() => {
        sessionStorage.clear();
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`landing-page-main-container`}>
            {/* <Header setDisplayLoginPopup={setDisplayLoginPopup} /> */}
            <PreLoginHeader setDisplayLoginPopup={setDisplayLoginPopup} />
            {/* <MainBanner /> */}
            <HeroSection />
            <Services />

            {/* <OurServices /> */}
            {/* <NewRegistrationForm /> */}
            {/* <Footer /> */}
            <CommonFooter />
            {/* <LoginPopup displayLoginPopup={displayLoginPopup} setDisplayLoginPopup={setDisplayLoginPopup} /> */}
            {
                displayLoginPopup ?
                    <LoginModal scrollY={scrollY} setDisplayLoginPopup={setDisplayLoginPopup} />
                    : null
            }
        </div>
    )
}

export default LandingPage;