import React, { useState, useEffect } from 'react';
import './AdmissionForm.scss';
import {
    Document,
    Page,
    Text,
    View,
    Image,
    StyleSheet,
    PDFViewer,
    Font,
} from "@react-pdf/renderer";
import Logo from '../../../Assets/Images/Sankalp.png';
import Photo from '../../../Assets/Images/default_profile.png';
import Font300 from '../../../Assets/Fonts/Poppins/Poppins-Light.ttf';
import Font400 from '../../../Assets/Fonts/Poppins/Poppins-Regular.ttf';
import Font500 from '../../../Assets/Fonts/Poppins/Poppins-Medium.ttf';
import Font600 from '../../../Assets/Fonts/Poppins/Poppins-SemiBold.ttf';
import { ApiCall } from '../../../Utils/ApiComponets/ApiCall';

Font.register({
    family: 'Poppins', fonts: [
        { src: Font300, fontWeight: 'light' },
        { src: Font400, fontWeight: 'normal' },
        { src: Font500, fontWeight: 'medium' },
        { src: Font600, fontWeight: 'semibold' }
    ]
});

const AdmissionForm = () => {

    const [username, setUsername] = useState('');
    const [instituteName, setInstituteName] = useState('Sankalp Tutorials');
    const [instituteAddress, setInstituteAddress] = useState('Opp. Aai Chandika Sweets, Near CK Advertising, At Wakipada, Post Juchandra, Naigaon (East)');
    const [instituteNumber, setInstituteNumber] = useState("+91 8087339921");
    const [instituteEmail, setInstituteEmail] = useState("contact@sankalp.com");
    const [admissionDate, setAdmissionDate] = useState('');
    const [params, setParams] = useState(new URLSearchParams(window.location.search));

    const [studentDetails, setStudentDetails] = useState({
        firstName: '',
        middileName: '',
        lastName: '',
        dob: '',
        gender: '',
        mobileNumber: '',
        section: '',
        adharNumber: '',
        email: '',
        institute: '',
        board: '',
        medium: '',
        standard: '',
        division: '',
        stream: '',
        rollNumber: '',
    });
    const [parentDetails, setParentDetails] = useState({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        alternetMobileNumber: '',
        email: '',
        occupation: '',
        residentialAddress: {
            apartmentData: '',
            street: '',
            landMark: '',
            city: '',
            state: '',
            pincode: '',
        },
        workAddress: {
            apartmentData: '',
            street: '',
            landMark: '',
            city: '',
            state: '',
            pincode: '',
        }
    })

    const [pastAcademicRecords, setPastAcademicRecords] = useState({
        instituteName: '',
        board: '',
        medium: '',
        standard: '',
        obtainedMarks: '',
        outOfMarks: '',
        percentage: '',
        overallGrade: '',
    });

    const [userProfile, setUserProfile] = useState('');

    useEffect(() => {
        document.title = 'Student Details PDF';
        triggerGetStudentDetailsApi();
        triggerGetUserProfileApi();
    }, []);

    const triggerGetUserProfileApi = () => {
        return new Promise(async (res, rej) => {
            try {
                const studentId = params.get('studentId');
                const response = await ApiCall("user/getuserprofile?userId=" + studentId, null, 'get', params.get('token'));
                if (response.responseJson.imageUrl) {
                    const base64 = await fetch(response.responseJson.imageUrl)
                        .then(response => response.blob())
                        .then(blob => {
                            const reader = new FileReader();
                            reader.readAsDataURL(blob);
                            return new Promise((res) => {
                                reader.onloadend = () => {
                                    res(reader.result);
                                }
                            })
                        });
                    setUserProfile(base64);
                }
                res();
            } catch (e) {
                console.log(e);
                res();
            }
        })
    }


    const triggerGetStudentDetailsApi = () => {
        return new Promise(async (res, rej) => {
            try {
                // setDivisionLoader(true);
                const studentId = params.get('studentId');

                const response = await ApiCall("student/getstudent?studentId=" + studentId + "&filter=all", null, 'get', params.get('token'));
                console.log(response);
                let studentDetails, parentDetails, pastAcademicRecord;

                studentDetails = response.responseJson.studentDetails.studentPersonalDetails;
                if (!studentDetails.stream) {
                    studentDetails.stream = '';
                }
                if (!studentDetails.mobileNumber) {
                    studentDetails.mobileNumber = '';
                }
                if (!studentDetails.email) {
                    studentDetails.email = '';
                }
                setUsername(studentDetails.firstName + ' ' + studentDetails.lastName);
                setAdmissionDate(studentDetails.admissionDate);
                setStudentDetails(studentDetails);
                parentDetails = response.responseJson.studentDetails.studentParentDetails;
                if (!parentDetails.alternetMobileNumber) {
                    parentDetails.alternetMobileNumber = '';
                }
                setParentDetails(parentDetails);
                pastAcademicRecord = response.responseJson.studentDetails.studentPastAcademicDetails;
                if (!pastAcademicRecord.instituteName) {
                    pastAcademicRecord.instituteName = '';
                }
                if (!pastAcademicRecord.board) {
                    pastAcademicRecord.board = '';
                }
                if (!pastAcademicRecord.medium) {
                    pastAcademicRecord.medium = '';
                }
                if (!pastAcademicRecord.standard) {
                    pastAcademicRecord.standard = '';
                }
                if (!pastAcademicRecord.obtainedMarks) {
                    pastAcademicRecord.obtainedMarks = '';
                }
                if (!pastAcademicRecord.outOfMarks) {
                    pastAcademicRecord.outOfMarks = '';
                }
                if (!pastAcademicRecord.overallGrade) {
                    pastAcademicRecord.overallGrade = '';
                }
                if (pastAcademicRecord.obtainedMarks && pastAcademicRecord.outOfMarks && !isNaN(Number(pastAcademicRecord.obtainedMarks)) && !isNaN(Number(pastAcademicRecord.outOfMarks))) {
                    const percentage = (Number(pastAcademicRecord.obtainedMarks) / Number(pastAcademicRecord.outOfMarks)) * 100;
                    pastAcademicRecord.percentage = percentage.toFixed(2);
                } else {
                    pastAcademicRecord.percentage = '';
                }
                setPastAcademicRecords(pastAcademicRecord);
                res();
            } catch (error) {
                console.log(error, "error");
                // setDivisionLoader(false);
                // setApiError(error.errors[0]);
                rej(error);
            }
        });
    }

    const generateRow = (first, second, third, fourth, needBottom) => {
        return (
            <View style={[styles.rowContainer, needBottom ? styles.borderBottom : null]}>
                <Text style={[styles.firstColoumn, styles.addSideBorder]}>
                    {first}
                </Text>
                <Text style={[styles.secondColoumn, styles.addSideBorder]}>
                    {second}
                </Text>
                <Text style={[styles.firstColoumn, styles.addSideBorder]}>
                    {third}
                </Text>
                <Text style={[styles.secondColoumn]}>
                    {fourth}
                </Text>
            </View>
        )
    }

    const generateTableHeading = (heading) => {
        return (
            <View style={[styles.tableHeading, styles.primaryHeader]}>
                <Text style={styles.tableHeadingText}>
                    {heading}
                </Text>
            </View>
        )
    }

    const generateSecondaryHeading = (heading) => {
        return (
            <View style={[styles.tableHeading, styles.secondaryHeader]}>
                <Text style={styles.secondaryHeaderText}>
                    {heading}
                </Text>
            </View>
        )
    }

    const generateSecondaryRow = (first, second, needBottom) => {
        return (
            <View style={[styles.rowContainer, needBottom ? styles.borderBottom : null]}>
                <Text style={[styles.secondaryFirstColoumn, styles.addSideBorder]}>
                    {first}
                </Text>
                <Text style={styles.secondarySecondColoumn}>
                    {second}
                </Text>
            </View>
        )
    }

    return (
        <PDFViewer style={styles.viewer}>
            <Document author='Alphacubes' pdfVersion='1.0' producer={instituteName} title={username}>
                <Page size="A4" style={styles.page}>
                    <View style={styles.page}>
                        <View style={styles.mainContainer}>
                            <View style={{ width: '100%' }}>
                                <View style={styles.headerContainer}>
                                    <View style={styles.logoContainer}>
                                        <Image
                                            src={Logo}//need to call get api for image and store base64 data to render here
                                            style={styles.instituteLogo}
                                        />
                                    </View>
                                    <View style={styles.instituteDetailsContainer}>
                                        <Text style={styles.instituteName}>
                                            {instituteName}
                                        </Text>
                                        <Text style={styles.instituteAddress}>
                                            {instituteAddress}
                                        </Text>
                                        <Text style={styles.instituteContactDetails}>
                                            Mobile: {instituteNumber} | Email: {instituteEmail}
                                        </Text>
                                        <Text style={styles.admissionText}>
                                            ADMISSION FORM
                                        </Text>
                                    </View>
                                    <View style={styles.photoContainer}>
                                        <Image
                                            src={userProfile ? userProfile : Photo}//same as logo
                                            style={styles.photo}
                                        />
                                    </View>
                                </View>
                                <View style={styles.tableContainer}>
                                    {generateTableHeading("Student Details")}
                                    {generateRow("First name", studentDetails.firstName, "Middle name", studentDetails.middileName)}
                                    {generateRow("Last name", studentDetails.lastName, "Date of birth", studentDetails.dob)}
                                    {generateRow("Gender", studentDetails.gender, "Mobile number", studentDetails.mobileNumber)}
                                    {generateRow("Section", studentDetails.section, "Aadhar number", studentDetails.adharNumber)}
                                    {generateRow("Email address", studentDetails.email, "Institute", studentDetails.institute)}
                                    {generateRow("Board", studentDetails.board, "Medium", studentDetails.medium)}
                                    {generateRow("Standard", studentDetails.standard, "Division", studentDetails.division)}
                                    {generateRow("Stream", studentDetails.stream, "Roll number", studentDetails.rollNumber, true)}
                                </View>
                                <View style={styles.tableContainer}>
                                    {generateTableHeading("Parent Details")}
                                    {generateRow("First name", parentDetails.firstName, "Last name", parentDetails.lastName)}
                                    {generateRow("Mobile number", parentDetails.mobileNumber, "Alternate mobile", parentDetails.alternetMobileNumber)}
                                    {generateRow("Email address", parentDetails.email, "Occupation", parentDetails.occupation)}
                                    {generateSecondaryHeading("Residential Address:")}
                                    {generateSecondaryRow("Apartment number and name", parentDetails.residentialAddress.apartmentData)}
                                    {generateSecondaryRow("Street/area name", parentDetails.residentialAddress.street)}
                                    {generateSecondaryRow("Landmark", parentDetails.residentialAddress.landMark)}
                                    {generateSecondaryRow("City", parentDetails.residentialAddress.city)}
                                    {generateSecondaryRow("State", parentDetails.residentialAddress.state)}
                                    {generateSecondaryRow("Pincode", parentDetails.residentialAddress.pincode)}
                                    {generateSecondaryHeading("Work Address:")}
                                    {generateSecondaryRow("Office number and name", parentDetails.workAddress.apartmentData)}
                                    {generateSecondaryRow("Street/area name", parentDetails.workAddress.street)}
                                    {generateSecondaryRow("Landmark", parentDetails.workAddress.landMark)}
                                    {generateSecondaryRow("City", parentDetails.workAddress.city)}
                                    {generateSecondaryRow("State", parentDetails.workAddress.state)}
                                    {generateSecondaryRow("Pincode", parentDetails.workAddress.pincode, true)}
                                </View>
                                <View style={styles.tableContainer}>
                                    {generateTableHeading("Past Academic Record")}
                                    {generateRow("Institute", pastAcademicRecords.instituteName, "Board", pastAcademicRecords.board)}
                                    {generateRow("Medium", pastAcademicRecords.medium, "Standard", pastAcademicRecords.standard)}
                                    {generateRow("Obtained marks", pastAcademicRecords.obtainedMarks, "Out of marks", pastAcademicRecords.outOfMarks)}
                                    {generateRow("Percentage", pastAcademicRecords.percentage, "Grade", pastAcademicRecords.overallGrade, true)}
                                </View>
                                <View style={{ alignSelf: 'center' }}>
                                    <Text style={styles.disclaimerText}>
                                        We hereby declare that the data we have provided to the institute is correct and up-to-date. As responsible parents, we understand the importance of maintaining accurate information of our child's records.
                                    </Text>
                                    <Text style={styles.admissionDate}>
                                        Admission Date: {admissionDate}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.bottomContainer}>
                                <View style={styles.signContainer}>
                                    <Text style={styles.signText}>
                                        Parent's Sign
                                    </Text>
                                    <Text style={styles.signText}>
                                        Seal/Stamp
                                    </Text>
                                    <Text style={styles.signText}>
                                        Student's Sign
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    )
}

export default AdmissionForm;

const styles = StyleSheet.create({
    page: {
        flex: 1,
        backgroundColor: '#fff',
        padding: '5px'
    },
    viewer: {
        width: window.innerWidth,
        height: window.innerHeight,
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        fontSize: '9px',
        border: '1px solid #2d3142',
        borderBottomWidth: 0,
        fontFamily: 'Poppins',
    },
    secondaryFirstColoumn: {
        width: '30%',
        marginLeft: '4px',
        height: '100%',
    },
    secondarySecondColoumn: {
        width: '70%',
        marginLeft: '4px',
        height: '100%',
    },
    secondaryHeader: {
        backgroundColor: '#f1f1f1',
        alignItems: 'flex-start'
    },
    secondaryHeaderText: {
        fontSize: '11px',
        marginLeft: '4px'
    },
    primaryHeader: {
        backgroundColor: '#e9e9e9',
        alignItems: 'center',
    },
    tableHeading: {
        justifyContent: 'center',
        border: '1px solid #2d3142',
        width: '100%',
        borderBottomWidth: 0,
        paddingVertical: '1px',
    },
    tableHeadingText: {
        fontSize: '12px',
        fontFamily: 'Poppins',
    },
    firstColoumn: {
        width: '15%',
        marginLeft: '4px',
        height: '100%'
    },
    bottomContainer: {
        width: '95%',
        alignSelf: 'center',
    },
    addSideBorder: {
        borderRightWidth: '1px',
        borderColor: '#2d3142',
        borderStyle: 'solid',
    },
    secondColoumn: {
        width: '35%',
        marginLeft: '4px',
        height: '100%',
    },
    borderBottom: {
        borderBottomWidth: '1px',
    },
    admissionDate: {
        fontSize: '10px',
        fontFamily: 'Poppins',
        marginTop: '8px',
    },
    mainContainer: {
        border: '2px solid #2d3142',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableContainer: {
        width: '95%',
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '12px',
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: '2.5%',
        paddingBottom: '2px',
        borderBottomWidth: '2px',
        borderColor: '#2d3142',
        borderStyle: 'solid',
        width: '100%',
    },
    logoContainer: {
        width: '80px',
        height: 'auto',
    },
    instituteDetailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flex: 1,
    },
    photoContainer: {
        width: '80px',
        height: '100px',
        overflow: 'hidden',
        border: '1px',
        borderWidth: '1px',
        borderColor: '#2d3142',

    },
    photo: {
        margin: 0,
        padding: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        objectPosition: 'center',
    },
    instituteName: {
        fontSize: '16px',
        fontWeight: 'semibold',
        color: '#2d3142',
        fontFamily: 'Poppins',
    },
    instituteAddress: {
        fontSize: '10px',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontWeight: 'light',
        width: '70%',
        marginTop: '2px',
    },
    instituteContactDetails: {
        fontSize: '8px',
        fontFamily: 'Poppins',
        fontWeight: 'light',
        marginTop: '2px',
    },
    admissionText: {
        fontSize: '16px',
        marginTop: '32px',
        fontFamily: 'Poppins',
        fontWeight: 'medium',
    },
    disclaimerText: {
        fontSize: '8px',
        fontFamily: 'Poppins',
        // alignSelf: 'center',
        marginTop: '4px',
    },
    signContainer: {
        width: '90%',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '4px',
    },
    signText: {
        fontSize: '10px',
        fontFamily: 'Poppins',
    }
});