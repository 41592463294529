import React, { useState } from 'react';
import './CommonTextInput.scss';


import { FaInfoCircle } from "react-icons/fa";

const CommonTextInput = (props) => {

    const [isFocused, setIsFocused] = useState(false);
    const [displayError, setDisplayError] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    const handleChange = (event) => {
        props.setInputValue(event.target.value);
    };

    return (
        <div className={`input-field-outer-container`}>
            <div
                className={`custom-input ${isFocused || props.inputValue ? 'input-focused' : ''} ${props.error ? 'input-error' : ''}`}
            >
                {
                    props.leftIcon ?
                        <div className={`input-left-label-container`}>
                            {props.leftIcon}
                        </div>
                        : null
                }
                {
                    props.countryCode ?
                        <div className={`input-country-code-label`}>
                            <p>
                                +91
                            </p>
                        </div>
                        : null
                }
                {/* <label className={`input-label`}>{props.label}</label> */}
                <label className="input-label">
                    {props.label}
                    {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
                </label>
                {
                    props.rightIcon ?
                        <div onClick={() => {
                            if (props.rightIconToggle) {
                                props.rightIconToggle(val => !val);
                            }
                        }} className={`input-right-icon-container`}>
                            {props.rightIcon}
                        </div>
                        : null
                }
                {
                    props.error ?
                        <div
                            onMouseEnter={(e) => {
                                setDisplayError(true);
                            }}
                            onMouseLeave={(e) => {
                                setDisplayError(false);
                            }}
                            className={`input-error-container`}>
                            <FaInfoCircle />
                        </div>
                        : null
                }
                <input
                    type={props.inputType ? props.inputType : 'text'}
                    className={`text-input-field`}
                    value={props.inputValue}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    disabled={props.disabled}
                    maxLength={props.maxLength && props.maxLength}
                    onKeyDown={props.onKeyDown ? (e) => { return props.onKeyDown(e) } : () => { console.log("On key down") }}
                />
            </div>
            {
                props.error && displayError ?
                    <div
                        className={`input-error-text-container ${props.rightIcon ? 'input-right-icon-added' : ''}`}>
                        <p>
                            {props.error}
                        </p>
                    </div>
                    : null
            }
        </div>
    )
}

export default CommonTextInput;