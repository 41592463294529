import React, { useState } from 'react'
import '../ParentDetails/ParentDetails.scss'
import CommonTextInput from '../Common/CommonTextInput/CommonTextInput'
import CommonDropDown from '../Common/CommonDropDown';

const ParentDetails = ({
    parentsfirstName,
    setparentsFirstName,
    parentslastName,
    setparentsLastName,
    parentsmobileNumber,
    setparentsMobileNumber,
    parentsemail,
    setparentsEmail,
    occupation,
    setOccupation,
    appartmentName,
    setappartmentName,
    streetName,
    setstreetName,
    landmark,
    setlandmark,
    cityName,
    setcityName,
    officeName,
    setofficeName,
    officeStreetName,
    setofficeStreetName,
    officeLandmark,
    setofficeLandmark,
    officeCityName,
    setofficeCityName,
    State,
    setState,
    officeState,
    setofficeState,
    officePincode,
    setofficePincode,
    pincode,
    setpincode,
    errors,
    mainButtonLoader,
    StateOptions,
    handleStateChange,
    handleOfficeStateChange,
    setAlternativeParentsMobileNumber,
    AlternativeParentsMobileNumber,
    hideHeader,
    viewOnly
}) => {


    return (
        <div className="ParentDetails_main_container">
            {
                hideHeader ?
                    null :
                    <div className="ParentDetails_title_container">
                        <h1>Parent Details</h1>
                    </div>
            }
            <div className="parent_row_main_conaiter">

                <div className="row_class">
                    <div className="parents_first_name">
                        <CommonTextInput
                            label={"First name"}
                            required={true}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={parentsfirstName}
                            setInputValue={setparentsFirstName}
                            error={errors.parentsfirstName}
                            maxLength={50}
                        />
                    </div>
                    <div className="parents_last_name">
                        <CommonTextInput
                            label={"Last name"}
                            required={true}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={parentslastName}
                            setInputValue={setparentsLastName}
                            error={errors.parentslastName}
                            maxLength={50}
                        />
                    </div>
                </div>
                <div className="row_class">
                    <div className="parents_mobile_number">
                        <CommonTextInput
                            label={"Mobile number"}
                            required={true}
                            inputValue={parentsmobileNumber}
                            disabled={viewOnly || mainButtonLoader}
                            countryCode={true}
                            onKeyDown={(e) => {
                                const numberRegex = /^\d$/
                                if (numberRegex.test(e.key) || e.key == 'Backspace' || e.key == "Tab") {
                                    return e.key;
                                } else {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }}
                            setInputValue={setparentsMobileNumber}
                            error={errors.parentsmobileNumber}
                            maxLength={10}
                        />
                    </div>
                    <div className="parents_mobile_number">
                        <CommonTextInput
                            label={"Alternative Mobile number"}
                            inputValue={AlternativeParentsMobileNumber}
                            disabled={viewOnly || mainButtonLoader}
                            countryCode={true}
                            onKeyDown={(e) => {
                                const numberRegex = /^\d$/
                                if (numberRegex.test(e.key) || e.key == 'Backspace' || e.key == "Tab") {
                                    return e.key;
                                } else {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }}
                            setInputValue={setAlternativeParentsMobileNumber}
                            error={errors.AlternativeParentsMobileNumber}
                            maxLength={10}
                        />
                    </div>
                </div>
                <div className="row_class">
                    <div className="parents_email_id">
                        <CommonTextInput
                            label={"Email address"}
                            required={true}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={parentsemail}
                            setInputValue={setparentsEmail}
                            error={errors.parentsemail}
                            maxLength={254}

                        />
                    </div>
                    <div className="parents_occupation">
                        <CommonTextInput
                            label={"Occupation"}
                            required={true}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={occupation}
                            setInputValue={setOccupation}
                            error={errors.occupation}
                            maxLength={50}
                        />
                    </div>
                </div>
            </div>
            <div className="ParentDetails_content_container">
                <div className="ParentDetails_left_container">


                    <div className="Residential_contianer">
                        <div className="Residential_text_container">
                            <h3>Residential address*:</h3>
                        </div>
                    </div>

                    <div >
                        <CommonTextInput
                            label={"Apartment number and name"}
                            required={true}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={appartmentName}
                            setInputValue={setappartmentName}
                            error={errors.appartmentName}
                            maxlength={35}
                        />
                    </div>
                    <div >
                        <CommonTextInput
                            label={"Street/area name"}
                            required={true}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={streetName}
                            setInputValue={setstreetName}
                            error={errors.streetName}
                            maxlength={35}

                        />
                    </div>
                    <div >
                        <CommonTextInput
                            label={"Landmark"}
                            required={true}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={landmark}
                            setInputValue={setlandmark}
                            error={errors.landmark}
                            maxlength={35}

                        />
                    </div>
                    <div className="parent-city">
                        <CommonTextInput
                            label={"City name"}
                            required={true}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={cityName}
                            setInputValue={setcityName}
                            error={errors.cityName}
                            maxLength={20}

                        />
                    </div>
                    <div className="parent_state_container">
                        <CommonDropDown
                            error={errors.homeState}
                            selectedValue={State}
                            showDefaultLabel={true}
                            disabled={viewOnly || mainButtonLoader}
                            handleSelectChange={handleStateChange}
                            options={StateOptions}
                        // extraStyles={{ width: "100%", padding: "0.63vw" }}
                        />
                    </div>
                    <div>
                        <CommonTextInput
                            label={"Pincode"}
                            required={true}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={pincode}
                            setInputValue={setpincode}
                            error={errors.pincode}
                            maxLength={6}
                        />
                    </div>

                </div>
                <div className="ParentDetails_right_container">

                    <div className="work_contianer">
                        <div className="work_text_container">
                            <h3>Work address*:</h3>
                        </div>
                    </div>
                    <div>
                        <CommonTextInput
                            label={"Office number and name"}
                            required={true}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={officeName}
                            setInputValue={setofficeName}
                            error={errors.officeName}
                            maxlength={35}

                        />
                    </div>
                    <div>
                        <CommonTextInput
                            label={"Street/area name"}
                            required={true}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={officeStreetName}
                            setInputValue={setofficeStreetName}
                            error={errors.officeStreetName}
                            maxlength={35}

                        />
                    </div>
                    <div >
                        <CommonTextInput
                            label={"Landmark"}
                            required={true}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={officeLandmark}
                            setInputValue={setofficeLandmark}
                            error={errors.officeLandmark}
                            maxlength={35}

                        />
                    </div>
                    <div className="parent-city">
                        <CommonTextInput
                            label={"City name"}
                            required={true}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={officeCityName}
                            setInputValue={setofficeCityName}
                            error={errors.officeCityName}
                            maxLength={20}

                        />
                    </div>
                    <div className="parent_state_container">
                        <CommonDropDown
                            error={errors.officeState}
                            selectedValue={officeState}
                            showDefaultLabel={true}
                            disabled={viewOnly || mainButtonLoader}
                            handleSelectChange={handleOfficeStateChange}
                            options={StateOptions}
                        // extraStyles={{ width: "100%", padding: "0.63vw" }}
                        />
                    </div>
                    <div >
                        <CommonTextInput
                            label={"Pincode"}
                            required={true}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={officePincode}
                            setInputValue={setofficePincode}
                            error={errors.officePincode}
                            maxLength={6}
                        />
                    </div>

                </div>

            </div>
        </div>
    )
}

export default ParentDetails