import React, { useState, useEffect } from 'react';
import './NewRegistrationForm.scss';
import RegistrationSideImage from '../../../Assets/Images/registration_side_image.png';
import { FaCheck } from "react-icons/fa";
import { FaCheckCircle, FaCalendar, FaInfoCircle, FaChevronLeft } from "react-icons/fa";
import CommonTextInput from '../../Common/CommonTextInput';
import { FaEye, FaEyeSlash } from "react-icons/fa";
// import CommonButton from '../../Common/CommonButton/CommonButton';
import PrimaryButton from '../../Common/PrimaryButton';
import { ApiCall } from '../../../Utils/ApiComponets/ApiCall';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import errorImg from '../../../Assets/Images/error_img.png'
import { encrypt } from '../../../Utils/CryptoJs/CryptoJs';
import ConsentCheckbox from '../../Common/ConsentCheckbox/ConsentCheckbox';
import { useNavigate } from 'react-router-dom';
import validation from '../../../Utils/Validations/Validations';

const NewRegistrationForm = (props) => {
    const navigate = useNavigate();

    const [activepage, setactivepage] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const [mobileReqId, setMobileReqId] = useState('');
    const [emailReqId, setEmailReqId] = useState('');

    const [mobileOtpLoader, setMobileOtpLoader] = useState(false);
    const [emailOtpLoader, setEmailOtploader] = useState(false);
    const [mainButtonLoader, setMainButtonLoader] = useState(false);

    const [apiError, setApiError] = useState('');

    const [mobileotptime, setmobileotpTime] = useState(120);
    const [emailotptime, setemailotptime] = useState(120);
    const [TermsandConditionError, setTermsandConditionError] = useState(false);

    const [isConsentChecked, setIsConsentChecked] = useState(true);
    const [consentCheckError, setConsentCheckError] = useState('');

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

    useEffect(() => {
        if (activepage) {
            setmobileotpTime(120);
            setemailotptime(120);
            setmobileotp('');
            setemailotp('');
            const timer = setInterval(() => {
                setmobileotpTime(prevTime => {
                    if (prevTime === 0) {
                        return 0; // Return 0 to prevent negative values
                    } else {
                        return prevTime - 1;
                    }
                });
                setemailotptime(prevTime => {
                    if (prevTime === 0) {
                        return 0; // Return 0 to prevent negative values
                    } else {
                        return prevTime - 1;
                    }
                });
            }, 1000);

            // Clean up the interval on component unmount
            return () => clearInterval(timer);
        } else {
            setApiError('');
            setRegisterToken('');
            setMobileReqId('');
            setEmailReqId('');
            setisOtpVerified(false);
            setisEmailVerified(false);
        }
    }, [activepage]);

    useEffect(() => {
        setTermsandConditionError(false);
    }, [isChecked])

    const formatTime = (timeInSeconds) => {
        const minutes = Math.floor(timeInSeconds / 60);
        const seconds = timeInSeconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };

    const handle_mobile_Resend_Click = async () => {
        try {
            if (mainButtonLoader) {
                return;
            }
            if (mobileotptime == 0) {
                setErrors({});
                setApiError('');
                await TriggerSendOtpMobile();
                setmobileotp('');
                setmobileotpTime(120); // Reset timer to 120 seconds
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handle_email_Resend_Click = async () => {
        try {
            if (mainButtonLoader) {
                return;
            }
            if (emailotptime == 0) {
                setErrors({});
                setApiError('');
                await TriggerSendOtpEmail();
                setemailotp('');
                setemailotptime(120); // Reset timer to 120 seconds
            }
        } catch (e) {
            console.log(e);
        }
    }


    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleContinueClick = async () => {
        if (!activepage) {
            const isValid = validateInputs();
            if (isValid) {
                try {
                    setMainButtonLoader(true);
                    await TriggerSendOtpMobile();
                    await TriggerSendOtpEmail();
                    setMainButtonLoader(false);
                    setactivepage(true);
                    setApiError('');
                } catch (e) {
                    setMainButtonLoader(false);
                    console.log(e);
                }
            }
        } else {
            if (!isConsentChecked) {
                setConsentCheckError("Consent is required");
            } else {
                try {
                    setConsentCheckError('');
                    setMainButtonLoader(true);
                    await TriggerRegisterButton();
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth' // Smooth scrolling animation
                    });
                    navigate("/dashboard");
                    setMainButtonLoader(false);
                } catch (e) {
                    console.log(e);
                    setMainButtonLoader(false);
                }
            }
            // if (isOtpVerified && isChecked) {
            //     TriggerRegisterButton();
            // }
        }
        // if (!isChecked) {
        //     setTermsandConditionError(true);
        // }

        // if (isOtpVerified && isChecked) {
        //     TriggerRegisterButton();
        // }
        // setactivepage(true)
    }

    const paragraph_points = [
        {
            id: 1,
            text: "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet"
        },
        {
            id: 2,
            text: "Lorem ipsum dolor sit amet Lorem ipsum"
        },
        {
            id: 3,
            text: "Lorem ipsum dolor sit amet Lorem ipsum dolor sit"
        },
        {
            id: 4,
            text: "Lorem ipsum dolor sit amet Lorem ipsum dolor"
        },
        {
            id: 5,
            text: "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet"
        },
        {
            id: 6,
            text: "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem"
        },
        {
            id: 7,
            text: "Lorem ipsum dolor sit amet Lorem"
        }
    ]


    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [organization, setOrganization] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [mobileotp, setmobileotp] = useState('');
    const [emailotp, setemailotp] = useState('');
    const [isOtpVerified, setisOtpVerified] = useState(false);
    const [registerToken, setRegisterToken] = useState('');
    const [isEmailVerified, setisEmailVerified] = useState(false);
    const [countryCode, setcountryCode] = useState("+91")

    //calendar state
    const [value, onChange] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false);

    const toggleCalendar = () => {
        if (dob) {
            calendarformatDate(dob)
        }
        setShowCalendar(!showCalendar);
    };

    function formatDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    function calendarformatDate(date) {
        const newdate = new Date(date);
        // Check if the date is valid
        if (isNaN(date)) {
            console.error("Invalid date");
            return null;
        }

        const day = newdate.getDate().toString().padStart(2, '0');
        const month = (newdate.getMonth() + 1).toString().padStart(2, '0');
        const year = newdate.getFullYear();
        let datevalue = `${month}/${day}/${year}`
        setformatedDobForCalendar(datevalue);
        return `${month}/${day}/${year}`;
    }

    const [formatedDobForCalendar, setformatedDobForCalendar] = useState(dob)


    let maskedMobileNumber = mobileNumber.replace(/(\d{2})\d*(\d{2})/, '$1******$2');

    const [username, domain] = email.split('@');
    const maskedEmail = `${username.substring(0, 3)}*****@${domain}`;

    const handleFirstNameChange = (value) => {
        setFirstName(value);
    };

    const handleLastNameChange = (value) => {
        setLastName(value);
    };

    const handleMobileNumberChange = (value) => {
        setMobileNumber(value);
    };

    const handleEmailChange = (value) => {
        setEmail(value);
    };

    const handleDobChange = (value) => {
        setDob(value);
    };

    const handleCalendarChange = (date) => {
        setDob(date instanceof Date ? formatDate(date) : date.target.value);
        toggleCalendar();
    };

    const handleOrganizationChange = (value) => {
        setOrganization(value);
    };

    const handlePasswordChange = (value) => {
        setPassword(value);
    };

    const handleConfirmPasswordChange = (value) => {
        setConfirmPassword(value);
    };

    const handleMobileOtp = (value) => {
        setmobileotp(value);
    }

    const handleEmailOtp = (value) => {
        setemailotp(value);
    }


    function isValidDate(dateString) {
        // Check if it's a valid date in the format dd/mm/yyyy
        const regex = /^\d{2}\/\d{2}\/\d{4}$/;
        if (!regex.test(dateString)) return false;

        // Parse the date parts to integers
        const parts = dateString.split('/');
        const day = parseInt(parts[0], 10);
        const month = parseInt(parts[1], 10);
        const year = parseInt(parts[2], 10);

        // Check the ranges of month and year
        if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

        // Check the number of days in the month
        const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
            monthLength[1] = 29; // Leap year
        }
        return day > 0 && day <= monthLength[month - 1];
    }


    const validateInputs = () => {
        let isValid = true;
        let errors = {};

        // Validation for First Name
        // if (firstName.trim() === '') {
        //     errors.firstName = 'First name is required';
        //     isValid = false;
        // }

        // // Validation for Last Name
        // if (lastName.trim() === '') {
        //     errors.lastName = 'Last name is required';
        //     isValid = false;
        // }

        // if (!/^[A-Za-z]+$/.test(firstName.trim()) && !errors.firstName) {
        //     errors.firstName = 'Please enter valid first name';
        //     isValid = false;
        // }

        errors.firstName = validation.isValidFirstName(firstName);

        // Validation for Last Name
        // if (!/^[A-Za-z]+$/.test(lastName.trim()) && !errors.lastName) {
        //     errors.lastName = 'Please enter valid last name';
        //     isValid = false;
        // }

        errors.lastName = validation.isValidLastName(lastName);
        errors.mobileNumber = validation.isValidRegistrationMobileNumber(mobileNumber);

        // // Validation for Mobile Number
        // if (!/^\d{10}$/.test(mobileNumber)) {
        //     errors.mobileNumber = 'Please enter valid mobile number';
        //     isValid = false;
        // }

        errors.email = validation.isValidRegistrationEmail(email);

        // // Validation for Email
        // if (!/^\S+@\S+\.\S+$/.test(email)) {
        //     errors.email = 'Please enter valid email address';
        //     isValid = false;
        // }

        errors.dob = validation.isValidDob(dob);
        errors.organization = validation.isValidOrganization(organization);
        errors.password = validation.isValidPassword(password);
        errors.confirmPassword = validation.isValidConfirmPassword(confirmPassword, password);




        // Set errors state

        setConsentCheckError(errors.isConsentChecked = validation.isConsentChecked(isConsentChecked));



        setErrors(errors);

        for (const prop in errors) {
            if (errors[prop]) {
                isValid = false;
                break;
            }
        }


        return isValid;
    };

    const VerifyOTpClick = async () => {
        let errors = {};
        let isValid = true;
        try {
            errors.mobileotp = validation.isValidOtp(mobileotp);

            setErrors(errors);
            if (isValid == true) {
                setMobileOtpLoader(true);
                const response = await TriggerverifymobileOtp();
                setApiError('');
                setMobileOtpLoader(false);
                setErrors(errors);
                console.log(response);
            }
            // if (mobileotp == 111111) {
            //     setisOtpVerified(true);
            // }
            // else if (mobileotp !== 111111) {
            //     errors.mobileotp = 'Invalid OTP Entered';
            //     isValid = false;
            // }


        } catch (e) {
            console.log(e);
            setMobileOtpLoader(false);
            if (e.errors[0]) {
                errors.mobileotp = e.errors[0];
            } else {
                setApiError(e.errors[0]);
            }
            setErrors(errors);
            isValid = false;

        }
        return isValid;

    }

    const VerifyemailOTpClick = async () => {
        let isValid = true;
        let errors = {};

        try {
            errors.emailotp = validation.isValidEmailOtp(emailotp)
            setErrors(errors);
            if (isValid == true) {
                setEmailOtploader(true);
                const response = await TriggerverifyemailOtp();
                setApiError('');
                setErrors(errors);
                setEmailOtploader(false);
                console.log(response);
            }
            // else if (emailotp == 111111) {
            //     setisEmailVerified(true);
            // }
            // else if (emailotp !== 111111) {
            //     errors.emailotp = 'Invalid OTP Entered';
            //     isValid = false;
            // }
        } catch (e) {
            console.log(e);
            setEmailOtploader(false);
            if (e.errors[0]) {
                errors.emailotp = e.errors[0];
            } else {
                setApiError(e.errors[0]);
            }
            setErrors(errors);
            isValid = false;
        }
        return isValid;

    }



    function formatDate(date) {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }


    const TriggerRegisterButton = () => {
        return new Promise(async (res, rej) => {
            try {
                let body = {
                    "userData": {
                        "firstName": firstName,
                        "lastName": lastName,
                        "dob": dob,
                        "organizationName": organization,
                        "countryCode": countryCode,
                        "mobileNumber": mobileNumber.toString(),
                        "isMobileNumberVerified": isOtpVerified ? "1" : "0",
                        "primaryEmail": email,
                        "isPrimaryEmailVerified": isEmailVerified ? "1" : "0",
                        "password": encrypt(password)
                    },
                    "consentData": {
                        "type": "Register",
                        "userAgent": navigator.userAgent
                    }
                };

                const response = await ApiCall("register/registersuperadmin", body, 'post', registerToken);
                setApiError('');
                sessionStorage.setItem("accessToken", response.responseJson.auth.accessToken);
                sessionStorage.setItem("userId", response.responseJson.userDetails.userId);
                res();
            } catch (error) {
                setApiError(error.errors[0]);
                rej();
            }
        })
    }

    const TriggerSendOtpEmail = () => {
        return new Promise(async (res, rej) => {
            try {
                let body = {
                    "inputDetails": [
                        {
                            "countryCode": countryCode,
                            "flow": "registration",
                            "type": "email",
                            "value": email
                        }
                    ]
                };
                const response = await ApiCall("otp/sendotp", body);
                console.log(response.responseJson.details[0].requestId);
                setEmailReqId(response.responseJson.details[0].requestId);
                res();
            } catch (error) {
                console.log(error);
                setApiError(error.errors[0]);
                rej(error);
            }
        });
    }

    const TriggerSendOtpMobile = () => {
        return new Promise(async (res, rej) => {
            try {
                let body = {
                    "inputDetails": [
                        {
                            "countryCode": countryCode,
                            "flow": "registration",
                            "type": "mobile",
                            "value": mobileNumber.toString()
                        }
                    ]
                };
                const response = await ApiCall("otp/sendotp", body);
                console.log(response.responseJson.details[0].requestId);
                setMobileReqId(response.responseJson.details[0].requestId);
                res();
            } catch (error) {
                console.log(error);
                setApiError(error.errors[0]);
                rej(error);
            }
        });
    }

    const TriggerverifymobileOtp = () => {
        return new Promise(async (res, rej) => {
            try {
                let body = {
                    "otpDetails": [
                        {
                            "flow": "registration",
                            "type": "mobile",
                            "requestId": mobileReqId.toString(),
                            "otp": mobileotp.toString()
                        }

                    ]
                };
                const response = await ApiCall("otp/verifyotp", body);
                console.log(response);
                setisOtpVerified(true);
                setRegisterToken(response.responseJson.details[0].registerToken);
                res();
            } catch (error) {
                console.log(error);
                rej(error);
            }
        })
    }

    const TriggerverifyemailOtp = () => {
        return new Promise(async (res, rej) => {
            try {
                let body = {
                    "otpDetails": [
                        {
                            "flow": "registration",
                            "type": "email",
                            "requestId": emailReqId.toString(),
                            "otp": emailotp.toString()
                        }

                    ]
                };
                const response = await ApiCall("otp/verifyotp", body);
                console.log(response);
                setisEmailVerified(true);
                res();
            } catch (error) {
                console.log(error);
                rej(error);
            }
        })
    }



    return (
        <div className="NewRegistrationForm_main_banner_container">
            <div className="NewRegistrationForm_main-banner-content-container">
                <div className="registration_inner_left_banner_container">
                    <div className="registration_banner_img_container">
                        <img alt='Man looking at statistics' src={RegistrationSideImage} />
                    </div>
                    <div className="paragraph_points_container">
                        {paragraph_points.map((item) => (
                            <div key={item.id} className="paragraph_points_wrapper">
                                <FaCheckCircle className='check-icon' />
                                <p style={{ fontSize: "1.4vw", fontWeight: "500" }}>{item.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="registration_inner_right_banner_container">
                    <h1 className='registration_right_banner_title'>Register To Start Your Free Trial</h1>
                    <div className="progress_bar_container">
                        <div className="progress_bar_wrapper">
                            <div className={activepage ? "first_bar_container  first_stage_completed_container" : "first_bar_container"} onClick={() => {
                                if (!mainButtonLoader) {
                                    setactivepage(false)
                                }
                            }}>
                                <h2 className={activepage ? "active_state_text_style" : "inactive_state_text_style"}>1</h2>
                            </div>
                            <div className={activepage ? "middle_line_container_active" : "middle_line_container"}></div>
                            <div className={activepage ? "second_bar_container_active" : "second_bar_container"}>
                                <h2 className={activepage ? "active_state_text_style_second" : "inactive_state_text_style_second"}>2</h2>
                            </div>
                        </div>
                        <div className="text_below_progressbar_container">
                            <div className="basic_details_container" onClick={() => {
                                if (!mainButtonLoader) {
                                    setactivepage(false)
                                }
                            }}>
                                <p>Basic Details</p>
                            </div>
                            <div className="Verify_details_container">
                                <p className={activepage ? "" : "Verify_details_Inactive"}>Verify Details</p>
                            </div>
                        </div>
                    </div>
                    {activepage ?
                        <div className='back-to-basic-details-container' >
                            <div className="back-to-basic-details-button" onClick={() => {
                                if (!mainButtonLoader) {
                                    setactivepage(false)
                                }
                            }}>
                                <FaChevronLeft />
                                <p>
                                    Back to basic details
                                </p>
                            </div>
                        </div>
                        :
                        null
                    }
                    {
                        apiError != '' ?
                            <div className={`registration-api-error-container`}>
                                <div className={`registration-form-error-container`}>
                                    <FaInfoCircle />
                                    <p>
                                        {apiError}
                                    </p>
                                </div>
                            </div>
                            : null
                    }

                    <>

                        {
                            activepage ?
                                <>
                                    <div className="mobile_otp_container">
                                        <p className='otp_text'>OTP has been sent to your mobile number +91 {maskedMobileNumber}</p>

                                        <CommonTextInput
                                            label={"Mobile OTP"}
                                            required={true}
                                            className={"mobile_otp_style"}
                                            error={errors.mobileotp}
                                            maxLength={6}
                                            disabled={isOtpVerified || mainButtonLoader}
                                            onKeyDown={(e) => {
                                                const numberRegex = /^\d$/
                                                if (numberRegex.test(e.key) || e.key == 'Backspace' || e.key == "Tab") {
                                                    return e.key;
                                                } else {
                                                    // return false;
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }
                                                // console.log(e);
                                            }}
                                            inputValue={mobileotp}
                                            setInputValue={setmobileotp}
                                            maxlength={10}

                                        // onChange={handleMobileOtp}
                                        />
                                        <div className="resend_otp_container">
                                            <div className="time_container">

                                                <p className='time_text'>
                                                    {
                                                        isOtpVerified ?
                                                            <span className='otp-verified-text'>Mobile OTP is verified successfully !</span> :
                                                            `You can resend mobile OTP in ${formatTime(mobileotptime)}`
                                                    }
                                                </p>
                                            </div>
                                            {
                                                isOtpVerified ?
                                                    null :
                                                    <div className="resend_container" onClick={handle_mobile_Resend_Click}>
                                                        <p style={{ color: mobileotptime === 0 || mainButtonLoader ? "#008af1" : "#3257F180" }} className="resend_link">Resend</p>
                                                    </div>
                                            }
                                        </div>
                                        <div className="button_contianer" >
                                            <PrimaryButton
                                                buttonText={isOtpVerified ? "Verified" : "Verify"}
                                                disabled={isOtpVerified || mobileOtpLoader || mainButtonLoader}
                                                displayLoader={mobileOtpLoader}
                                                className={"button_style"}
                                                onClick={VerifyOTpClick}
                                            />
                                        </div>
                                        <div className="horizontal_line_container"></div>
                                    </div>
                                    <div className="email_otp_container">
                                        <p className='otp_text'>OTP has been sent to your email address {maskedEmail}</p>

                                        <CommonTextInput
                                            label={"Email OTP"}
                                            maxLength={6}
                                            disabled={isEmailVerified || mainButtonLoader}
                                            onKeyDown={(e) => {
                                                const numberRegex = /^\d$/
                                                if (numberRegex.test(e.key) || e.key == 'Backspace' || e.key == "Tab") {
                                                    return e.key;
                                                } else {
                                                    // return false;
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }
                                                // console.log(e);
                                            }}
                                            className={"mobile_otp_style"}
                                            error={errors.emailotp}
                                            // onChange={handleEmailOtp}
                                            inputValue={emailotp}
                                            setInputValue={setemailotp}
                                        // error={"Please enter valid username"}

                                        />
                                        <div className="resend_otp_container">
                                            <div className="time_container">

                                                <p className='time_text'>
                                                    {
                                                        isEmailVerified ?
                                                            <span className='otp-verified-text'>Email OTP is verified successfully !</span> :
                                                            `You can resend email OTP in ${formatTime(emailotptime)}`
                                                    }
                                                </p>

                                            </div>
                                            {
                                                isEmailVerified ?
                                                    null :
                                                    <div className="resend_container" onClick={handle_email_Resend_Click}>
                                                        <p style={{ color: emailotptime === 0 || mainButtonLoader ? "#008af1" : "#3257F180" }} className="resend_link">Resend</p>
                                                    </div>
                                            }
                                        </div>
                                        <div className="button_contianer" >
                                            <PrimaryButton
                                                buttonText={isEmailVerified ? "Verified" : "Verify"}
                                                disabled={isEmailVerified || emailOtpLoader || mainButtonLoader}
                                                displayLoader={emailOtpLoader}
                                                onClick={VerifyemailOTpClick}
                                                className={"button_style"}
                                            />
                                        </div>

                                    </div>
                                </>

                                :
                                <div className="text_input_container">
                                    <div className="name_container">
                                        <CommonTextInput
                                            label={"First name"}
                                            required={true}
                                            disabled={mainButtonLoader}
                                            // value={firstName}
                                            inputValue={firstName}
                                            setInputValue={setFirstName}
                                            // ExtraStyles={{ width: "50%", marginRight: "1vw" }}
                                            // onChange={handleFirstNameChange}
                                            error={errors.firstName}
                                            maxLength={20}
                                        />

                                        <CommonTextInput
                                            label={"Last name"}
                                            required={true}

                                            // value={lastName}
                                            disabled={mainButtonLoader}
                                            inputValue={lastName}
                                            setInputValue={setLastName}
                                            // ExtraStyles={{ width: "50%" }}
                                            // onChange={handleLastNameChange}
                                            error={errors.lastName}
                                            maxLength={20}

                                        />
                                    </div>
                                    <div className="phone_no_container">
                                        <CommonTextInput
                                            label={"Mobile number"}
                                            required={true}

                                            // value={mobileNumber}
                                            inputValue={mobileNumber}
                                            disabled={mainButtonLoader}
                                            countryCode={true}
                                            onKeyDown={(e) => {
                                                const numberRegex = /^\d$/
                                                if (numberRegex.test(e.key) || e.key == 'Backspace' || e.key == "Tab") {
                                                    return e.key;
                                                } else {
                                                    // return false;
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }
                                                // console.log(e);
                                            }}
                                            // inputType={'number'}
                                            // onChange={handleMobileNumberChange}
                                            setInputValue={setMobileNumber}
                                            error={errors.mobileNumber}
                                            maxLength={10}
                                        />
                                    </div>
                                    <div className="Email_Id_container">
                                        <CommonTextInput
                                            label={"Email address"}
                                            required={true}

                                            disabled={mainButtonLoader}
                                            // value={email}
                                            inputValue={email}
                                            setInputValue={setEmail}
                                            // onChange={handleEmailChange}
                                            error={errors.email}
                                            maxlength={254}

                                        />
                                    </div>
                                    <div className="Dob_container">
                                        <CommonTextInput
                                            label={"Date of birth dd/mm/yyyy"}
                                            required={true}

                                            disabled={mainButtonLoader}
                                            rightIcon={<FaCalendar onClick={toggleCalendar} />}
                                            rightIconStyle={{ marginRight: "0.5vw" }}
                                            // value={dob}
                                            inputValue={dob}
                                            setInputValue={setDob}
                                            // onChange={handleDobChange}
                                            error={errors.dob}
                                            maxLength={10}
                                        />
                                        {
                                            (showCalendar && !mainButtonLoader) ?
                                                <div className='Calendar_Container'>
                                                    <Calendar
                                                        onChange={(date) => {
                                                            handleCalendarChange(date);
                                                            toggleCalendar();
                                                        }}
                                                        value={formatedDobForCalendar}
                                                    />
                                                </div>
                                                : null
                                        }

                                    </div>
                                    <div className="Organization_container">
                                        <CommonTextInput
                                            label={"Organization name"}
                                            required={true}

                                            // value={organization}
                                            inputValue={organization}
                                            disabled={mainButtonLoader}
                                            setInputValue={setOrganization}
                                            // onChange={handleOrganizationChange}
                                            error={errors.organization}
                                            maxLength={35}
                                        />
                                    </div>
                                    <div className="Password_container">
                                        <CommonTextInput
                                            label={"Password"}
                                            required={true}

                                            disabled={mainButtonLoader}
                                            // value={password}
                                            inputValue={password}
                                            inputType={isPasswordVisible ? 'text' : 'password'}
                                            setInputValue={setPassword}
                                            // onChange={handlePasswordChange}
                                            rightIcon={isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                                            rightIconToggle={setIsPasswordVisible}
                                            rightIconStyle={{ marginRight: "0.5vw" }}
                                            error={errors.password}
                                            maxlength={32}

                                        />
                                    </div>
                                    <div className="Confirm_Password_container">
                                        <CommonTextInput
                                            label={"Confirm Password"}
                                            required={true}

                                            disabled={mainButtonLoader}
                                            // value={confirmPassword}
                                            inputValue={confirmPassword}
                                            inputType={isConfirmPasswordVisible ? 'text' : 'password'}
                                            setInputValue={setConfirmPassword}
                                            // onChange={handleConfirmPasswordChange}
                                            rightIconToggle={setIsConfirmPasswordVisible}
                                            rightIcon={isConfirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                                            rightIconStyle={{ marginRight: "0.5vw" }}
                                            error={errors.confirmPassword}
                                            maxlength={32}

                                        />
                                    </div>

                                </div>
                        }
                    </>

                    <ConsentCheckbox
                        // disabled={disableLogin}
                        isRegistrationPage={true}
                        disabled={mainButtonLoader}
                        isConsentChecked={isConsentChecked}
                        setIsConsentChecked={setIsConsentChecked}
                        setConsentCheckError={setConsentCheckError}
                        consentCheckError={consentCheckError}
                    />
                    <div className="button_contianer" >
                        <PrimaryButton
                            buttonText={activepage ? "Register" : "Continue"}
                            className={"button_style"}
                            onClick={handleContinueClick}
                            disabled={(activepage && !isOtpVerified) || mainButtonLoader}
                            displayLoader={mainButtonLoader}
                        />
                    </div>

                    <div className="already_account_container">
                        <p>Already have an account? <a onClick={() => props.setDisplayLoginPopup(true)}>Login</a> instead!</p>
                    </div>
                    <div className="need_help_container">
                        <p>Need Help? <a href="#">Contact Support</a></p>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default NewRegistrationForm;