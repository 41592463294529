import React, { useState, useEffect } from "react";
import './FeesReceipt.scss';
import {
    Document,
    Page,
    Text,
    View,
    Image,
    StyleSheet,
    PDFViewer,
    Font,
} from "@react-pdf/renderer";
import Logo from '../../Assets/Images/Sankalp.png';
import { ApiCall } from "../../Utils/ApiComponets/ApiCall";


/*Font.register({
    family: 'Poppins', fonts: [
        { src: Font300, fontWeight: 'light' },
        { src: Font400, fontWeight: 'normal' },
        { src: Font500, fontWeight: 'medium' },
        { src: Font600, fontWeight: 'semibold' }
    ]
});*/

const FeesReceipt = () => {

    const [username, setUsername] = useState('vipul.bhoir@12061999');
    const [instituteName, setInstituteName] = useState('Sankalp Tutorials');
    const [instituteAddress, setInstituteAddress] = useState('Opp. Aai Chandika Sweets, Near CK Advertising, At Wakipada, Post Juchandra, Naigaon (East)');
    const [instituteNumber, setInstituteNumber] = useState("+91 9238484848");
    const [instituteEmail, setInstituteEmail] = useState("contact@sankalp.com");

    const [params, setParams] = useState(new URLSearchParams(window.location.search));

    const [feesDetails, setFeesDetails] = useState({
        receiptNo: '00001',
        section: 'School',
        studentname: 'Rinku Rajput',
        adimssionNo: '001',
        parentname: 'Baburao Rajput',
        date: '05/05/2024',
        class: '9',
        divison: 'A',
        modeofpayment: 'Cash',
    });

    const [tableDetails, setTableDetails] = useState({
        srno: '1',
        particulars: 'First Installment',
        amount: '10000',
        inWord: 'Ten Thousand only',
        total: '10000',
    });

    useEffect(() => {
        document.title = 'Fees Receipt PDF';
        triggerGetTransactionDetails();
    }, []);

    const triggerGetTransactionDetails = () => {
        return new Promise(async (res, rej) => {
            try {
                const transactionId = params.get('transactionId');
                const response = await ApiCall("fee/gettransactionforreciept?transactionId=" + transactionId, null, 'get', params.get('token'));
                // const transaction = response.respo
                console.log(response);
                const transaction = response.responseJson.recieptDetails;
                setFeesDetails({
                    receiptNo: transaction.recieptNo,
                    section: transaction.section,
                    studentname: transaction.studentName + " " + transaction.lastName,
                    adimssionNo: transaction.admissionNo,
                    parentname: transaction.parentName + " " + transaction.lastName,
                    date: transaction.date,
                    class: transaction.standard,
                    divison: transaction.division,
                    modeofpayment: transaction.transactionDetails.modoOfPayment,
                });
                setTableDetails({
                    srno: '1',
                    particulars: 'Installment Payment',
                    amount: transaction.transactionDetails.amountPaid,
                    inWord: numToWord(transaction.transactionDetails.amountPaid) + "Rupees Only",
                    total: transaction.transactionDetails.amountPaid,
                });
                res();
            } catch (e) {
                console.log(e);
                rej(e);
            }
        })
    }

    const numToWord = (value) => {
        let singleDigits = ";One ;Two ;Three ;Four ;Five ;Six ;Seven ;Eight ;Nine ;Ten ;Eleven ;Twelve ;Thirteen ;Fourteen ;Fifteen ;Sixteen ;Seventeen ;Eighteen ;Nineteen ".split(";"), doubleDigits = "  Twenty Thirty Forty Fifty Sixty Seventy Eighty Ninety".split(" "), str, numberValue, currentId, textOutput;
        // let c = Math.round(value.replace(/,/g, "")).toString(10).replace(/,/g, "");
        let c = Math.round(value).toString(10);
        let unikie = 0 < c.length && 10 > c.length ? (numberValue = ("000000000" + c).substr(-9).match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/)) ? (str = "",
        str += 0 != numberValue[1] ? (singleDigits[Number(numberValue[1])] || doubleDigits[numberValue[1][0]] + " " + singleDigits[numberValue[1][1]]) + "Crore " : "",
        str += 0 != numberValue[2] ? (singleDigits[Number(numberValue[2])] || doubleDigits[numberValue[2][0]] + " " + singleDigits[numberValue[2][1]]) + "Lakh " : "",
        str += 0 != numberValue[3] ? (singleDigits[Number(numberValue[3])] || doubleDigits[numberValue[3][0]] + " " + singleDigits[numberValue[3][1]]) + "Thousand " : "",
        str += 0 != numberValue[4] ? (singleDigits[Number(numberValue[4])] || doubleDigits[numberValue[4][0]] + " " + singleDigits[numberValue[4][1]]) + "Hundred " : "",
        c = str += 0 != numberValue[5] ? ("" != str ? "and " : "") + (singleDigits[Number(numberValue[5])] || doubleDigits[numberValue[5][0]] + " " + singleDigits[numberValue[5][1]]) : "") : c = void 0 : c = "False";
        return c;
    }

    const generateRow = (first, second, third, fourth, needBottom) => {
        return (
            <View style={[styles.rowContainer, needBottom ? styles.borderBottom : null]}>
                <Text style={[styles.firstColoumn, styles.addSideBorder]}>
                    {first}
                </Text>
                <Text style={[styles.secondColoumn, styles.addSideBorder]}>
                    {second}
                </Text>
                <Text style={[styles.firstColoumn, styles.addSideBorder]}>
                    {third}
                </Text>
                <Text style={[styles.secondColoumn]}>
                    {fourth}
                </Text>
            </View>
        )
    }



    const generateSecondaryRow = (first, second, third, needBottom) => {
        return (
            <View style={[styles.rowContainer2, needBottom ? styles.borderBottom2 : null]}>
                <Text style={[styles.secondaryFirstColoumn, styles.addSideBorder2]}>
                    {first}
                </Text>
                <Text style={[styles.secondarySecondColoumn, styles.addSideBorder2]}>
                    {second}
                </Text>
                <Text style={[styles.secondaryThirdColomn, styles.addSideBorder]}>
                    {third}
                </Text>
            </View>
        )
    }

    const generateThirdRow = (first, second, third, needBottom) => {
        return (
            <View style={[styles.rowContainer3, needBottom ? styles.borderBottom3 : null]}>
                <Text style={[styles.nextFirstColoumn, styles.addSideBorder2]}>
                    {first}
                </Text>
                <Text style={[styles.nextSecondColoumn, styles.addSideBorder2]}>
                    {second}
                </Text>
                <Text style={[styles.nextThirdColomn, styles.addSideBorder]}>
                    {third}
                </Text>
            </View>
        )
    }

    const generateFourthRow = (first, second, third, fourth, needBottom) => {
        return (
            <View style={[styles.rowContainer4, needBottom ? styles.borderBottom4 : null]}>
                <Text style={[styles.FirstColoumn4, styles.addSideBorder]}>
                    {first}
                </Text>
                <Text style={[styles.SecondColoumn4, styles.addSideBorder]}>
                    {second}
                </Text>
                <Text style={[styles.ThirdColomn4, styles.addSideBorder2]}>
                    {third}
                </Text>
                <View style={[styles.FourthColomn4, styles.addSideBorder2]}>
                    <Text >
                        {fourth}
                    </Text>
                </View>
            </View>
        )
    }

    return (
        <PDFViewer style={styles.viewer}>
            <Document author='Alphacubes' pdfVersion='1.0' producer={instituteName} title={username}>
                <Page size="A4" style={styles.page}>
                    <View style={styles.page}>
                        <View style={styles.mainContainer}>
                            <View style={{ width: '100%' }}>
                                <View style={styles.headerContainer}>
                                    <View style={styles.logoContainer}>
                                        <Image
                                            src={Logo}
                                            style={styles.instituteLogo}
                                        />
                                    </View>
                                    <View style={styles.instituteDetailsContainer}>
                                        <Text style={styles.instituteName}>
                                            {instituteName}
                                        </Text>
                                        <Text style={styles.instituteAddress}>
                                            {instituteAddress}
                                        </Text>
                                        <Text style={styles.instituteContactDetails}>
                                            Mobile:{instituteNumber} | Email: {instituteEmail}
                                        </Text>
                                    </View>
                                    <View style={styles.instituteCopyText}>
                                        <Text>
                                            Institute Copy
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.textContainer}>
                                    <Text style={styles.text}>
                                        TRANSACTION FEE RECEIPT
                                    </Text>
                                </View>
                                <View style={styles.detailsContainer}>
                                    {generateRow("Receipt No. :", feesDetails.receiptNo, "Section :", feesDetails.section)},
                                    {generateRow("Student's name :", feesDetails.studentname, "Admission No. :", feesDetails.adimssionNo)},
                                    {generateRow("Parent's name :", feesDetails.parentname, "Date :", feesDetails.date)},
                                    {generateRow("Class :", feesDetails.class, "Divison :", feesDetails.divison)},
                                    {generateRow("Mode of Payment :", feesDetails.modeofpayment)},
                                </View>
                                <View style={styles.tableContainer}>
                                    {generateSecondaryRow("Sr.No", "Particulars", "Amount")}
                                </View>
                                <View style={styles.tableDetails}>
                                    {generateThirdRow(tableDetails.srno, tableDetails.particulars, tableDetails.amount)}
                                    {generateFourthRow("Rs. in Word's:", tableDetails.inWord, "Total", tableDetails.total)}
                                </View>
                                <View style={{ alignSelf: 'left' }}>
                                    <Text style={styles.notes}>
                                        Note:
                                    </Text>
                                    <Text style={styles.disclaimerText}>
                                        Fees Once Deposit Will Not Be Refundable & Non Transferable In Any Case
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={{ borderBottom: '1px dashed black', marginTop: 10, marginBottom: 10 }} />

                    <View style={styles.page}>
                        <View style={styles.mainContainer}>
                            <View style={{ width: '100%' }}>
                                <View style={styles.headerContainer}>
                                    <View style={styles.logoContainer}>
                                        <Image
                                            src={Logo}
                                            style={styles.instituteLogo}
                                        />
                                    </View>
                                    <View style={styles.instituteDetailsContainer}>
                                        <Text style={styles.instituteName}>
                                            {instituteName}
                                        </Text>
                                        <Text style={styles.instituteAddress}>
                                            {instituteAddress}
                                        </Text>
                                        <Text style={styles.instituteContactDetails}>
                                            Mobile:{instituteNumber} | Email: {instituteEmail}
                                        </Text>
                                    </View>
                                    <View style={styles.studentCopyText}>
                                        <Text>
                                            Student Copy
                                        </Text>
                                    </View>
                                </View>
                                <View style={styles.textContainer}>
                                    <Text style={styles.text}>
                                        TRANSACTION FEE RECEIPT
                                    </Text>
                                </View>
                                <View style={styles.detailsContainer}>
                                    {generateRow("Receipt No. :", feesDetails.receiptNo, "Section :", feesDetails.section)},
                                    {generateRow("Student's name :", feesDetails.studentname, "Admission No. :", feesDetails.adimssionNo)},
                                    {generateRow("Parent's name :", feesDetails.parentname, "Date :", feesDetails.date)},
                                    {generateRow("Class :", feesDetails.class, "Divison :", feesDetails.divison)},
                                    {generateRow("Mode of Payment :", feesDetails.modeofpayment)},
                                </View>
                                <View style={styles.tableContainer}>
                                    {generateSecondaryRow("Sr.No.", "Particulars", "Amount")}
                                </View>
                                <View style={styles.tableDetails}>
                                    {generateThirdRow(tableDetails.srno, tableDetails.particulars, tableDetails.amount)}
                                    {generateFourthRow("Rs. in Word's:", tableDetails.inWord, "Total", tableDetails.total)}
                                </View>
                                <View style={{ alignSelf: 'left' }}>
                                    <Text style={styles.notes}>
                                        Note:
                                    </Text>
                                    <Text style={styles.disclaimerText}>
                                        Fees Once Deposit Will Not Be Refundable & Non Transferable In Any Case
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </Page>
            </Document>
        </PDFViewer>
    )
}

export default FeesReceipt;

const styles = StyleSheet.create({
    page: {
        flex: 1,
        backgroundColor: '#fff',
        padding: '5px',
        paddingBottom: '10px',
    },
    viewer: {
        width: window.innerWidth,
        height: window.innerHeight,
    },
    mainContainer: {
        border: '2px solid #2d3142',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: '2.5%',
        paddingBottom: '2px',
        // borderBottomWidth: '2px',
        // borderColor: '#2d3142',
        // borderStyle: 'solid',
        width: '100%',
    },
    logoContainer: {
        width: '90px',
        height: 'auto',
    },
    instituteDetailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flex: '1',
        //marginRight:'10px',
    },
    instituteName: {
        fontSize: '18px',
        fontWeight: 'semibold',
        color: '#2d3142',
        fontFamily: 'Poppins',
    },
    instituteAddress: {
        fontSize: '8px',
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontWeight: 'light',
        width: '60%',
        marginTop: '2px',
        //marginLeft:'20px'
    },
    instituteContactDetails: {
        fontSize: '8px',
        fontFamily: 'Poppins',
        fontWeight: 'light',
        marginTop: '2px',
        marginBottom: '5px',
    },
    textContainer: {
        alignItems: 'center',
        //border:'1px solid #2d3142',
        backgroundColor: '#c9c9c9',
        borderBottom: '1px solid #2d3142',
        borderTop: '1px solid #2d3142',
    },
    instituteCopyText: {
        display: 'flex',
        width: '20%',
        fontSize: '10px',
        fontWeight: 'medium',
        fontFamily: 'Poppins',
        textAlign: 'right',
        marginBottom: '50px',
    },
    text: {
        // marginLeft:'45px',
        textAlign: 'center',
        fontSize: '16px',
        fontFamily: 'Poppins',
        fontWeight: 'medium',
    },
    addSideBorder: {
        // borderRightWidth: '1px',
        // borderColor: '#2d3142',
        // borderStyle: 'solid',
    },
    detailsContainer: {
        paddingTop: '5px',
    },
    rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        //alignItems: 'center',
        width: '100%',
        fontSize: '10px',
        // border: '1px solid #2d3142',
        fontWeight: 'medium',
        fontFamily: 'Poppins',
    },
    firstColoumn: {
        width: '20%',
        marginLeft: '20px',
        height: '100%'
    },
    addSideBorder2: {
        borderRightWidth: '1px',
        borderColor: '#2d3142',
        borderStyle: 'solid',
    },
    secondColoumn: {
        width: '35%',
        marginLeft: '2px',
        height: '100%',
    },

    tableContainer: {
        marginTop: '15px',
    },
    secondaryFirstColoumn: {
        width: '8%',
        //marginLeft: '4px',
        height: '100%',
        textAlign: 'center',
    },
    secondarySecondColoumn: {
        width: '72%',
        // marginLeft: '200px',
        height: '100%',
        textAlign: 'center',
    },
    secondaryThirdColomn: {
        width: '20%',
        // marginLeft:'4px',
        height: '100%',
        textAlign: 'center',
    },
    rowContainer2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        fontSize: '10px',
        borderTop: '1px solid #2d3142',
        //  borderBottom: '1px solid #2d3142',
        fontWeight: 'medium',
        fontFamily: 'Poppins',
    },
    /*borderBottom2:{
        borderBottomWidth:'none',
    },*/
    rowContainer3: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        fontSize: '10px',
        borderTop: '1px solid #2d3142',
        //borderBottom: '1px solid #2d3142',
        fontWeight: 'medium',
        fontFamily: 'Poppins',
        // paddingBottom:'100px',
    },
    nextFirstColoumn: {
        width: '8%',
        // marginLeft: '4px',
        height: '100%',
        textAlign: 'center',
        paddingBottom: '100px',
    },
    nextSecondColoumn: {
        width: '72%',
        height: '100%',
        textAlign: 'center',
        paddingBottom: '100px',
    },
    nextThirdColomn: {
        width: '20%',
        height: '100%',
        textAlign: 'center',
        paddingBottom: '100px',
    },
    rowContainer4: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: '100%',
        fontSize: '10px',
        borderTop: '1px solid #2d3142',
        borderBottom: '1px solid #2d3142',
        fontWeight: 'medium',
        fontFamily: 'Poppins',
    },
    FirstColoumn4: {
        width: '20%',
        height: '100%',
        textAlign: 'left',
        paddingLeft: '5px',
    },
    SecondColoumn4: {
        width: '54%',
        height: '100%',
        textAlign: 'center',
        //marginLeft:'5px'
    },
    ThirdColomn4: {
        width: '6%',
        height: '100%',
        textAlign: 'center'
    },
    FourthColomn4: {
        width: '20%',
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor:  '#f0f',
        // paddingLeft:'20px',
        //backgroundColor:'#2d3142'
    },
    notes: {
        fontSize: '10px',
        fontFamily: 'Poppins',
        marginLeft: '5px',

    },
    disclaimerText: {
        fontSize: '7px',
        fontFamily: 'Poppins',
        // alignSelf: 'center',
        // marginTop: '4px',
        // textAlign:'left',
        marginLeft: '5px',
        fontWeight: 'medium'
    },
    studentCopyText: {
        display: 'flex',
        width: '20%',
        fontSize: '10px',
        fontWeight: 'medium',
        fontFamily: 'Poppins',
        textAlign: 'right',
        marginBottom: '50px',
    },
})