import React, { useState } from "react";
import '../NotificationDetails/NotificationDetails.scss'
import { FaEnvelope, FaInbox, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const NotificationDetails = (props) => {

const [message,setMessage]=useState([
    {id:"first",title:"Admission",body:"New Admission Done",time:"03/08/2024 16:00",isRead:false},
    {id:"second",title:"Payment",body:"Your Account is credited",time:"03/08/2024 16:38",isRead:false},
    {id:"third",title:"Admission",body:"New Student is added",time:"04/08/2024 12:46",isRead:true}
]);
    return (
        <div className="notification_main_container">
            <div className="notification_close_container">
                <div onClick={()=>{
                    props.onClose();
                }} className="notification_close_button">
                    <FaTimes className="close_button" />
                </div>
            </div>
            <div className="notification_header_main_container">
                <div className="notification_header_container">
                    <p>Notification</p>
                </div>
                <div className="envelop_icon">
                    <FaEnvelope />
                </div>
            </div>
            <div className="notification_message_main_container">
                <div className="message_container">
                     {message.map((item)=>{
                        return <div className="detail_container">
                            {item.id}
                        </div>
                        
                     })
                     }
                     {message.map((item)=>{
                        return <div className="detail_container">
                            {item.body}
                        </div>

                     })}
                </div>
            </div>

        </div>
    )
}
export default NotificationDetails