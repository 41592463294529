import React, { useState } from 'react'
import '../PastAcademicReports/PastAcademicReports.scss'
import CommonTextInput from '../Common/CommonTextInput/CommonTextInput'
import CommonDropDown from '../Common/CommonDropDown';


const PastAcademicReports = ({
    instituteName,
    setinstituteName,
    medium,
    setmedium,
    obtainedMarks,
    setObtainedMarks,
    obtainedPercentage,
    setObtainedPercentage,
    board,
    setboard,
    standard,
    setstandard,
    outOfMarks,
    setOutOfMarks,
    grade,
    setGrade,
    errors,
    mainButtonLoader,
    mediumOptions,
    boardOptions,
    standardOptions,
    handleMediumChange,
    handleBoardChange,
    handleStandardChange,
    hideHeader,
    viewOnly,
    boardLoader,
    mediumLoader,
    standardLoader,
}) => {



    return (
        <div className="PastAcademicReports_main_container">
            {
                hideHeader ? null :

                    <div className="PastAcademicReports_title_container">
                        <h1>Past Academic Records</h1>
                    </div>
            }
            <div className="PastAcademicReports_content_container">
                <div className="row_class">
                    <div className="PastAcademicReports_Institute_name">
                        <CommonTextInput
                            label={"Institute name"}
                            required={false}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={instituteName}
                            setInputValue={setinstituteName}
                            error={errors.instituteName}
                            maxlength={50}

                        />
                    </div>
                    <div className="parent_right_dropDown_container">
                        <CommonDropDown
                            error={errors.board}
                            selectedValue={board}
                            displayLoader={boardLoader}
                            disabled={viewOnly || mainButtonLoader}
                            handleSelectChange={handleBoardChange}
                            options={boardOptions}
                           // extraStyles={{ width: "100%", margin: "0 0 0.8vw 0", padding: "0.63vw" }}

                        />
                    </div>
                </div>
                <div className="row_class">
                    <div className="parent_left_dropDown_container">
                        <CommonDropDown
                            error={errors.medium}
                            selectedValue={medium}
                            displayLoader={mediumLoader}
                            disabled={viewOnly || mainButtonLoader}
                            handleSelectChange={handleMediumChange}
                            options={mediumOptions}
                           // extraStyles={{ width: "100%", padding: "0.63vw" }}

                        />
                    </div>
                    <div className="parent_right_dropDown_container">
                        <CommonDropDown
                            error={errors.standard}
                            selectedValue={standard}
                            required={true}
                            displayLoader={standardLoader}
                            disabled={viewOnly || mainButtonLoader}
                            handleSelectChange={handleStandardChange}
                            options={standardOptions}
                            //extraStyles={{ width: "100%", padding: "0.63vw" }}
                        />
                    </div>
                </div>
                <div className="row_class">
                    <div className="parents_last_name">
                        <CommonTextInput
                            label={"Obtained marks"}
                            required={false}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={obtainedMarks}
                            setInputValue={setObtainedMarks}
                            error={errors.obtainedMarks}
                            maxlength={6}

                        />
                    </div>
                    <div className="parents_last_name">
                        <CommonTextInput
                            label={"Out of marks"}
                            required={false}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={outOfMarks}
                            setInputValue={setOutOfMarks}
                            error={errors.outOfMarks}
                            maxlength={6}

                        />
                    </div>
                </div>
                <div className="row_class">
                    <div className="parents_last_name">
                        <CommonTextInput
                            label={"Obtained percentage"}
                            required={false}
                            disabled={true}
                            inputValue={obtainedPercentage}
                            setInputValue={setObtainedPercentage}
                            error={errors.obtainedPercentage}
                            maxlength={6}

                        />
                    </div>
                    <div className="parents_last_name">
                        <CommonTextInput
                            label={"Overall Grade"}
                            required={false}
                            disabled={viewOnly || mainButtonLoader}
                            inputValue={grade}
                            setInputValue={setGrade}
                            error={errors.grade}
                            maxlength={6}

                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PastAcademicReports