import Config from "../../Config/Config";

const Socket = (base64, fileType, fileSize, userAction, userObjectId, count, checkSum, setProgress, setWsStatus) => {
    try {
        let token = sessionStorage.getItem("accessToken");
        if (!token) {
            token = localStorage.getItem("accessToken");
        }

        const contentLength = base64.length;

        const parts = 100;
        const filePart = Math.ceil(contentLength / parts);

        function* generateChunk() {
            for (let i = 0; i < parts; i++) {
                yield base64.slice(filePart * i, filePart * i + filePart);
                if (filePart * i + filePart >= contentLength) {
                    break;
                }
            }
        }

        const chunkGenerator = generateChunk();

        let isSuccess = false;

        let sendMessageObj = {
            "userObjectId": userObjectId,
            "fileName": userObjectId,
            "extension": fileType,
            "chunk": "",
            "count": count,
            "userAction": userAction,
            "fileSize": fileSize,
            "checksum": checkSum,
            "contentLength": contentLength
        }

        // Custom WebSocket connection with headers
        let socket = new WebSocket(Config.socketUrl + '?token=' + token);

        function sendMessage(sendMessageObj) {
            if (socket && socket.readyState === WebSocket.OPEN) {
                socket.send(JSON.stringify(sendMessageObj));
            } else {
                setWsStatus("failed");
            }
        }


        // Connection opened
        socket.onopen = () => {
            const firstChunk = chunkGenerator.next().value;
            if (firstChunk) {
                sendMessageObj.chunk = firstChunk;
                setWsStatus('uploading');
                setTimeout(() => {
                    sendMessage(sendMessageObj);
                }, 1000);
            }
        };

        // Listen for messages
        socket.onmessage = (event) => {
            const responseMessage = JSON.parse(event.data);
            if (responseMessage.message == 'Chunk added successfully') {
                setProgress(responseMessage.progress);
                const chunk = chunkGenerator.next().value;
                if (chunk && responseMessage.progress < 100) {
                    sendMessageObj.chunk = chunk;
                    sendMessage(sendMessageObj);
                }
            } else if (responseMessage.message == 'Upload successful') {
                setProgress(100);
                isSuccess = true;
                setWsStatus('success');
                socket.close();
            } else if (responseMessage.message == 'Upload failed') {
                setProgress(0);
                isSuccess = false;
                setWsStatus('failed');
            } else {
                setProgress(0);
                isSuccess = false;
                setWsStatus('failed');
            }
        };

        // Connection closed
        socket.onclose = () => {
            if (!isSuccess) {
                setProgress(0);
                setWsStatus('failed');
            }
        };

    } catch (e) {
        console.log("Error in socket: " + e);
        setWsStatus('failed');
    }
}

export { Socket };