import React, { useState, useEffect } from "react";
import './ProfilePopup.scss';
import { FaTimes, FaUserCircle, FaHandPointRight, FaUnlock } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ProfilePopup = (props) => {
    console.log(props, "...")
    const navigate = useNavigate();

    const [profileUserName, setProfileUserName] = useState("Alphacubes");
    const [profileUserEmail, setProfileUserEmail] = useState("alphacubes@123gmail.com")

    useEffect(() => {
        let firstName = sessionStorage.getItem("firstName");
        if (!firstName) {
            firstName = localStorage.getItem("firstName");
        }
        let lastName = sessionStorage.getItem("lastName");
        if (!lastName) {
            lastName = localStorage.getItem("lastName");
        }
        setProfileUserName(firstName + " " + lastName);
    }, []);


    return (
        <div className="profile_main_container">
            <div className="profile-container">
                <div className="profile-upper-container">
                    <div className="close-button-main-container">
                        <div onClick={() => {
                            props.onClose();
                        }}
                            className={`profile-close-button-icon`}>
                            <FaTimes />
                        </div>
                    </div>

                    <div className="profile-icon-main-container">
                        <FaUserCircle />
                    </div>
                    <div className="profile-user-detail-container">
                        <div className="profile-name-container">
                            {profileUserName}
                        </div>
                        <div className="profile-email-container">
                            {profileUserEmail}
                        </div>
                    </div>


                    <div className='profile-edit-icon'>
                        <FaUserCircle />
                        <p>
                            Edit Profile
                        </p>
                    </div>
                    <div className='profile-subscription-icon'>
                        <FaHandPointRight />
                        <p>
                            Subscription
                        </p>
                    </div>
                    <div className='profile-change-password-icon'>
                        <FaUnlock />
                        <p className={`change-password-button-profile-popup`}>
                            Change Password
                        </p>
                    </div>
                </div>
                <div className="profile-bottom-container">
                    <div className={`profile-logout-container`}>
                        <div className='link-text profile-popup-bottom-button-help'>Help Center</div>
                        <p>|</p>
                        <div
                            onClick={() => {
                                window.scrollTo({
                                    top: 0,
                                    behavior: 'smooth' // Smooth scrolling animation
                                });
                                sessionStorage.clear();
                                localStorage.clear();
                                navigate('/');

                            }} className='link-text profile-popup-bottom-button-logout'>Logout</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProfilePopup